import { createSlice } from '@reduxjs/toolkit'
import { readGroupOrder } from '../action'
import { entityAdapter } from '../adapter'
import ReadSliceState from '../model/slice/ReadSliceState'
import ReadSliceReducer from '../model/slice/ReadSliceReducer'
import readInitialState from '../initialState/readState'

const readSlice = createSlice<ReadSliceState, ReadSliceReducer>({
  name: 'groupOrder_read',
  initialState: readInitialState,
  reducers: {},
  extraReducers: {
    [readGroupOrder.pending.type]: (state) => {
      state.isLoading = true
      state.error = readInitialState.error
      state.success = readInitialState.success
    },
    [readGroupOrder.fulfilled.type]: (state, action) => {
      entityAdapter.upsertOne(state, action.payload)
      state.isLoading = false
      state.error = readInitialState.error
    },
    [readGroupOrder.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default readSlice
