import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, CardMedia } from '../MaterialUI/core'
import { makeStyles } from '../MaterialUI/styles'
/*
 * Direct import of AppLogo component ('../AppLogo/AppLogo') instead of '../' is required to fix ESLint error:
 * Dependency cycle detected  import/no-cycle
 */
import AppLogo from '../AppLogo/AppLogo'
import { Size } from '../AppLogo/Options'
import LocaleSwitcher from '../Locale/LocaleSwitcher'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    flex: 1,
    maxWidth: 400,
  },
  media: {
    margin: theme.spacing(2, 0),
  },
  switcher: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}))

const LogoCard = ({ children }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <div className={classes.switcher}>
        <LocaleSwitcher />
      </div>
      <CardMedia className={classes.media}>
        <AppLogo size={Size.Large} />
      </CardMedia>
      <CardContent>{children}</CardContent>
    </Card>
  )
}

LogoCard.propTypes = {
  children: PropTypes.any.isRequired,
}

export default LogoCard
