import { AbstractFieldModel, ObjectFieldModel } from '@models/form/fields/FieldModels'
import { FieldType } from '@app/enums/form/FieldType'

export class CollectionFieldModel<T extends Record<string, any>> extends AbstractFieldModel<
  FieldType.Collection,
  Record<string, ObjectFieldModel<T>>
> {
  constructor() {
    super(FieldType.Collection)
  }
}
