import React, { FC, ReactNode } from 'react'
import clsx from 'clsx'
import GridItem from './items/GridItem'
import { makeStyles } from '../MaterialUI/styles'
import { GridCellType } from './cell'
import { Padding } from '../../theme/styles/padding'

type Props = {
  cells: GridCellType[]
  object: Record<string, any>
  toggleItem: (item: GridCellType) => void
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: Padding.Small,
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}))

const Grid: FC<Props> = ({ object, cells, toggleItem }) => {
  const classes = useStyles()

  const filterCells = (openedOnly: boolean): GridCellType[] => {
    return cells.filter((cell) => cell.isOpen === openedOnly)
  }

  const createGridItem = (cell: GridCellType): ReactNode => (
    <GridItem
      cell={cell}
      object={object}
      toggleButtonShow={() => toggleItem(cell)}
      key={cell.key}
      isVisible={cell.isOpen}
    />
  )

  const getGridCells = (): ReactNode[] => {
    return filterCells(true).map((cell) => createGridItem(cell))
  }

  const getGridButtons = (): ReactNode[] => {
    return filterCells(false).map((cell) => createGridItem(cell))
  }

  const gridCells = getGridCells()
  const buttons = getGridButtons()

  return (
    <>
      {buttons.length > 0 && <div className={classes.buttonRow}>{buttons}</div>}
      {gridCells.length > 0 && <div className={clsx(classes.wrapper)}>{gridCells}</div>}
    </>
  )
}

export default Grid
