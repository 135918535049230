import { DEFAULT_VAT_PERCENTAGE } from '@config/vat'
import { Percentage } from '@models/numbers/Percentage'

type isValidFunction = (value?: number) => boolean

const formatWithDecimals = (value: number, precision: number): string => {
  if (precision < 0 || isNaN(value)) {
    return ''
  }

  return Number(value).toFixed(precision)
}

const formatWithTwoDecimals = (value: number): string => {
  return formatWithDecimals(value, 2)
}

const formatWithoutDecimals = (value: number): string => {
  return formatWithDecimals(value, 0)
}

const getVATPercentage = (value: number): number => {
  const vat = Number(value)

  if (vat < 0 || isNaN(vat)) {
    return 0
  }

  return getPercentageOf(vat, DEFAULT_VAT_PERCENTAGE)
}

const isValueBetween = (min: number, max: number, value?: number): boolean => {
  if (value === undefined) {
    return false
  }

  return value >= min && value <= max
}

const isInteger = (value?: number): boolean => {
  if (value === undefined) {
    return false
  }

  return value % 1 === 0
}

const getPercentageOf = (value: number, percentage: number): number => {
  if (isValueBetween(Percentage.Min, Percentage.Max, percentage)) {
    return (percentage / 100) * value
  }

  throw new RangeError('Percentage should be in range (0, 100). You have provided ' + percentage + '.')
}

const getValueBetween = (min: number, max: number, value?: number): number | undefined => {
  if (value === undefined || min > max) {
    return
  }

  if (isValueBetween(min, max, value)) {
    return value
  }

  if (value > max) {
    return max
  }

  return min
}

export type { isValidFunction }

export {
  formatWithDecimals,
  formatWithTwoDecimals,
  formatWithoutDecimals,
  getVATPercentage,
  isValueBetween,
  isInteger,
  getPercentageOf,
  getValueBetween,
}
