import http from '@utils/http'
import { generateUrl } from '@utils/route'
import { UpdateParameters, Url } from '@features/clientOrderItems/api/model'

interface FetchParameters {
  id: number
}

interface CreateParameters {
  document: string
  article: string
  amount: number
}

interface DeleteParameters {
  id: number
}

interface ConditionItemParameter {
  position: number
  percentage: number
}

const fetchClientOrderItems = async (parameters: FetchParameters) => {
  const url = generateUrl(Url.Items, { id: parameters.id })

  return http.get(url)
}

const createClientOrderItem = async (parameters: CreateParameters) => {
  const url = generateUrl(Url.CreateItem)

  return http.post(url, parameters)
}

const updateClientOrderItem = async (parameters: UpdateParameters) => {
  const url = generateUrl(Url.UpdateItem, { id: parameters.id })

  return http.patch(url, parameters.changed)
}

const deleteClientOrderItem = async (parameters: DeleteParameters) => {
  const url = generateUrl(Url.RemoveItem, { id: parameters.id })

  return http.delete(url)
}

export type { FetchParameters, CreateParameters, ConditionItemParameter, DeleteParameters }

export { fetchClientOrderItems, createClientOrderItem, updateClientOrderItem, deleteClientOrderItem }
