import http from '@utils/http'
import { generateUrl } from '@utils/route'
import { ClientModel } from '@models/entities/clientOrders'
import { GetItemsPaginatedParameters, Pagination } from '@models/api/request'

const CLIENTS_API_URL = '/api/clients'
const CLIENT_DETAILS_API_URL = '/api/client/:id(\\d+)'
const CLIENT_UPDATE_API_URL = '/api/client/:id(\\d+)'

interface PostParameters {
  client: ClientModel
}

interface FetchParameters extends Omit<GetItemsPaginatedParameters, 'pagination'> {
  pagination?: Pagination
  companyName?: string
}

interface UpdateParameters {
  id: number
  changed: any
}

const fetchClients = async (parameters: FetchParameters) => {
  return http.get(CLIENTS_API_URL, {
    params: {
      ...parameters,
      include: ['deliveryAddresses', 'phoneNumber'],
    },
  })
}

const readClient = async (id: number) => {
  const url = generateUrl(CLIENT_DETAILS_API_URL, { id })

  return http.get(url, {
    params: {
      include: ['deliveryAddresses', 'phoneNumber'],
    },
  })
}

const createClient = async (parameters: PostParameters) => {
  const url = generateUrl(CLIENTS_API_URL)
  return http.post(url, parameters)
}

const updateClient = async (parameters: UpdateParameters) => {
  const url = generateUrl(CLIENT_UPDATE_API_URL, { id: parameters.id })

  return http.patch(url, parameters.changed)
}

export type { PostParameters, FetchParameters, UpdateParameters }
export { fetchClients, readClient, createClient, updateClient }
