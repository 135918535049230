import { FieldType } from '@app/enums/form/FieldType'
import { AbstractFieldModel } from '@models/form/fields/FieldModels'
import { DropdownOption } from '@models/form/dropdown/DropdownOption'

export class DropdownFieldModel extends AbstractFieldModel<FieldType.Dropdown, DropdownOption> {
  private _options: DropdownOption[] = []
  private _includeEmptyValue?: boolean = false

  constructor() {
    super(FieldType.Dropdown)
  }

  get options(): DropdownOption[] {
    return this._options
  }

  set options(options: DropdownOption[]) {
    this._options = options
  }

  get includeEmptyValue(): boolean | undefined {
    return this._includeEmptyValue
  }

  set includeEmptyValue(value: boolean | undefined) {
    this._includeEmptyValue = value
  }
}
