import React, { FC } from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { Theme } from '@components/MaterialUI/theme'
import { makeStyles } from '@components/MaterialUI/styles'
import { Divider, Drawer } from '@components/MaterialUI/core'
import NavList from './Navigation/NavList'
import { AppLogo } from '@components/index'
import { Color, Size, Variant } from '@components/AppLogo/Options'
import { DASHBOARD_PATH } from '@config/routes/paths'
import { executeClickEvent } from '@utils/events/clickEvents'
import { ClickEvent } from '@models/events/ClickEvent'

interface StylesProps {
  width: number
}

const useStyles = (settings: StylesProps) =>
  makeStyles((theme: Theme) => ({
    paper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: settings.width,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    paperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    toolbarSpace: {
      ...theme.mixins.toolbar,
      minHeight: theme.spacing(6),
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }))

interface LogoOptions {
  variant?: Variant
  size?: Size
}

interface Props {
  open: boolean
  drawerWidth: number
}

const Navigation: FC<Props> = ({ open, drawerWidth }) => {
  const classes = useStyles({ width: drawerWidth })()
  const history = useHistory()

  const getLogoOptions = (): LogoOptions => {
    let variant = Variant.Signet
    let size = Size.Small

    if (open) {
      variant = Variant.Full
      size = Size.Medium
    }

    return {
      variant,
      size,
    }
  }

  const navigateToDashboard = () => {
    history.push(DASHBOARD_PATH)
  }

  const handleLogoClick = (event: ClickEvent) => {
    executeClickEvent(event, navigateToDashboard)
  }

  const logoOptions = getLogoOptions()

  return (
    <Drawer
      variant='permanent'
      classes={{
        paper: clsx(classes.paper, !open && classes.paperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarSpace}>
        <AppLogo
          size={logoOptions.size}
          variant={logoOptions.variant}
          color={Color.White}
          handleClick={handleLogoClick}
        />
      </div>
      <Divider />
      <NavList open={open} />
    </Drawer>
  )
}

export default Navigation
