import { DropdownOption } from '@models/form/dropdown/DropdownOption'
import { ChangeDropdownType } from '@config/dropdown'

const changeSelectedValue = (option: DropdownOption, defaultIsUndefined?: boolean, onChange?: ChangeDropdownType) => {
  if (onChange) {
    onChange(option, defaultIsUndefined)
  }
}

export const handleDropdownSelectionChange = (
  OptionId: number,
  allOptions: DropdownOption[],
  selected?: DropdownOption,
  onChange?: ChangeDropdownType,
) => {
  const defaultIsUndefined = OptionId === 0

  let selectedOption = allOptions.find((item) => {
    return item.id === OptionId
  })

  if (!selectedOption && defaultIsUndefined && selected) {
    selectedOption = selected
  }

  if (selectedOption) {
    changeSelectedValue(selectedOption, defaultIsUndefined, onChange)
  }
}
