import createApiAction from '@features/@common/actions/api-actions'
import { parsePaginatedCollection } from '@utils/api/data'
import * as api from '../api'
import { CreateParameters, FetchBrandConditionForClientParams } from '../api'
import { GetItemsPaginatedParameters } from '@models/api/request'

const fetchClientHasBrandConditionsForBrand = createApiAction<any, GetItemsPaginatedParameters, any>(
  'clientHasBrandConditions.forBrand.fetch',
  async (parameters: GetItemsPaginatedParameters) => {
    const response = await api.fetchClientHasBrandConditionsForBrand(parameters)
    return parsePaginatedCollection(response)
  },
)

const fetchClientHasBrandConditionsForClient = createApiAction<any, FetchBrandConditionForClientParams, any>(
  'clientHasBrandConditions.forClient.fetch',
  async (parameters: FetchBrandConditionForClientParams) => {
    const response = await api.fetchClientHasBrandConditionsForClient(parameters)
    return parsePaginatedCollection(response)
  },
)

const createClientHasBrandCondition = createApiAction<any, CreateParameters, any>(
  'clientHasBrandConditions.create',
  async (parameters: CreateParameters) => {
    return await api.createClientHasBrandCondition(parameters)
  },
)

const deleteClientHasBrandCondition = createApiAction<any, number, any>(
  'clientHasBrandConditions.delete',
  async (id: number) => {
    await api.deleteClientHasBrandCondition(id)
  },
)

export {
  fetchClientHasBrandConditionsForBrand,
  fetchClientHasBrandConditionsForClient,
  createClientHasBrandCondition,
  deleteClientHasBrandCondition,
}
