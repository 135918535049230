import http from '../../utils/http'
import { UpdateParameters } from '@models/api/request'
import { generateUrl } from '@utils/route'

const GROUP_ORDERS_API_URL = '/api/group_orders'
const GROUP_ORDER_API_URL = '/api/group_orders/:id(\\d+)'
const GROUP_ORDER_UPDATE_API_URL = '/api/group_orders/:id(\\d+)'

interface PostParameters {
  name: string
  orderDate: string
}

interface CreateParameters {
  name: string
  orderDate: string
}

interface FetchParameters {
  page?: string | number
  perPage?: number
  name?: string
}

interface UpdateGroupOrderNameParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    name?: string
  }
}

interface UpdateGroupOrderItemsParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    items?: string[]
  }
}

interface UpdateGroupOrderDeliveryParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    deliveryAddress?: string
  }
}

interface UpdateGroupOrderHasClientDeliveryParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    hasClientDelivery?: boolean
  }
}

const fetchGroupOrders = async (parameters: FetchParameters) => {
  return http.get(GROUP_ORDERS_API_URL, { params: parameters })
}

const readGroupOrder = async (id: number) => {
  const url = generateUrl(GROUP_ORDER_API_URL, { id })

  return http.get(url, {
    params: {
      include: ['items', 'address'],
    },
  })
}

const createGroupOrder = async (parameters: CreateParameters) => {
  const url = generateUrl(GROUP_ORDERS_API_URL)

  return http.post(url, parameters)
}

const updateGroupOrderName = async (parameters: UpdateGroupOrderNameParameters) => {
  const url = generateUpdateGroupUrl(parameters)

  return http.patch(url, parameters.changed)
}

const updateGroupOrderItems = async (parameters: UpdateGroupOrderItemsParameters) => {
  const url = generateUpdateGroupUrl(parameters)

  return http.patch(url, parameters.changed)
}

const updateGroupOrderDelivery = async (parameters: UpdateGroupOrderDeliveryParameters) => {
  const url = generateUpdateGroupUrl(parameters)

  return http.patch(url, parameters.changed)
}

const updateGroupOrderHasClientDelivery = async (parameters: UpdateGroupOrderHasClientDeliveryParameters) => {
  const url = generateUpdateGroupUrl(parameters)

  return http.patch(url, parameters.changed)
}

const generateUpdateGroupUrl = (parameters: UpdateParameters): string => {
  return generateUrl(GROUP_ORDER_UPDATE_API_URL, { id: parameters.id })
}

export type {
  PostParameters,
  FetchParameters,
  CreateParameters,
  UpdateGroupOrderItemsParameters,
  UpdateGroupOrderDeliveryParameters,
  UpdateGroupOrderHasClientDeliveryParameters,
}
export {
  fetchGroupOrders,
  readGroupOrder,
  createGroupOrder,
  updateGroupOrderItems,
  updateGroupOrderName,
  updateGroupOrderDelivery,
  updateGroupOrderHasClientDelivery,
}
