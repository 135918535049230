import { createEntityAdapter } from '@reduxjs/toolkit'
import { ArticleModel } from '@models/entities/articles'

const selectId = (entity: ArticleModel) => entity.id

const entityAdapter = createEntityAdapter({
  selectId,
})

export { selectId, entityAdapter }
