import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit'
import { UpdateSliceState } from '@features/@common/slices/update/UpdateSliceState'

type UpdateSliceReducer = SliceCaseReducers<UpdateSliceState>

const updateInitialState: UpdateSliceState = {
  updatedSuccessfully: false,
  isUpdating: false,
  validationErrors: undefined,
}

const createUpdateSlice = (name: string, thunkAction: any, initialState = updateInitialState) =>
  createSlice<UpdateSliceState, UpdateSliceReducer>({
    name,
    initialState,
    reducers: {},
    extraReducers: {
      [thunkAction.pending.type]: (state) => {
        state.updatedSuccessfully = false
        state.isUpdating = true
        state.validationErrors = undefined
      },
      [thunkAction.fulfilled.type]: (state) => {
        state.updatedSuccessfully = true
        state.isUpdating = false
        state.validationErrors = undefined
      },
      [thunkAction.rejected.type]: (state, action) => {
        state.updatedSuccessfully = false
        state.isUpdating = false
        state.validationErrors = action.error
      },
    },
  })

export default createUpdateSlice
