import CreateSliceState from '../model/slice/CreateSliceState'
import { entityAdapter } from '../adapter'

const createInitialState: CreateSliceState = {
  ...entityAdapter.getInitialState(),
  isLoading: true,
  success: undefined,
  error: undefined,
}

export default createInitialState
