import { createSlice } from '@reduxjs/toolkit'
import { entityAdapter } from '../adapter'
import ReadSliceState from '../model/slice/ReadSliceState'
import ReadSliceReducer from '../model/slice/ReadSliceReducer'
import { readArticlePrices } from '../action'
import readInitialState from '../initialState/readState'

const readSlice = createSlice<ReadSliceState, ReadSliceReducer>({
  name: 'prices.read',
  initialState: readInitialState,
  reducers: {},
  extraReducers: {
    [readArticlePrices.pending.type]: (state) => {
      state.isLoading = true
    },
    [readArticlePrices.fulfilled.type]: (state, action) => {
      state.isLoading = false
      entityAdapter.upsertMany(state, action.payload.data)
    },
    [readArticlePrices.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload.errors
    },
  },
})

export default readSlice
