import { createSlice } from '@reduxjs/toolkit'
import { updateSupplier } from '../action'
import { entityAdapter } from '../adapter'

const updateSlice = createSlice({
  name: 'supplier_update',
  initialState: {
    ...entityAdapter.getInitialState(),
    updatedSuccessfully: false,
    isUpdating: false,
    validationErrors: null,
  },
  extraReducers: {
    [updateSupplier.pending.type]: (state) => {
      state.updatedSuccessfully = false
      state.isUpdating = true
      state.validationErrors = null
    },
    [updateSupplier.fulfilled.type]: (state) => {
      state.updatedSuccessfully = true
      state.isUpdating = false
      state.validationErrors = null
    },
    [updateSupplier.rejected.type]: (state, action) => {
      state.updatedSuccessfully = false
      state.isUpdating = false
      state.validationErrors = action.error
    },
  },
})

export default updateSlice
