import React, { FC } from 'react'
import { useController } from 'react-hook-form'
import { LabeledCheckbox } from '@components/Form/Labeled'
import { useIdGenerator } from '@features/focusedInput/hooks/useIdGenerator'
import { FormFieldProps } from '@components/Form/FormFields'
import { mergeRules } from '@utils/form/rules/mergeRules'
import { getInputName } from '@utils/form/fields/getInputName'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'

const FormCheckboxField: FC<FormFieldProps> = ({ formField, control, disabled, sectionLabel }) => {
  const name = getInputName(formField, sectionLabel)
  const { generateId } = useIdGenerator('form')
  const rules = mergeRules(formField.rules) as RegisterOptions

  const { field } = useController({
    name,
    rules,
    control,
    defaultValue: formField.value,
  })

  return (
    <LabeledCheckbox
      {...field}
      id={generateId(name)}
      isChecked={field.value}
      labelText={formField.label}
      disabled={disabled}
    />
  )
}

export default FormCheckboxField
