import BasicColumn from './BasicColumn'
import ActionsColumn from './ActionsColumn'
import EditableColumn from './EditableColumn'
import SelectRowColumn from './SelectRowColumn'
import PrefixedColumn from './PrefixedColumn'
import CheckboxColumn from './CheckboxColumn'
import { FetchedValue } from './FetchedValue'
import DropDownSelectColumn from './DropDownSelectColumn'
import IconButtonColumn from '@components/RoyalList/Model/IconButtonColumn'

type GetValueFunction = (object: any) => FetchedValue

type Column =
  | BasicColumn
  | ActionsColumn
  | EditableColumn
  | PrefixedColumn
  | SelectRowColumn
  | CheckboxColumn
  | DropDownSelectColumn
  | IconButtonColumn

const isActionsColumn = (column: Column): column is ActionsColumn => {
  return !!(column as ActionsColumn).actions
}

const isEditableColumn = (column: Column): column is EditableColumn => {
  return !!(column as EditableColumn).onChange
}

const isPrefixedColumn = (column: Column): column is SelectRowColumn => {
  return !!(column as PrefixedColumn).prefix
}

const isDropDownSelectColumn = (column: Column): column is DropDownSelectColumn => {
  return !!(column as DropDownSelectColumn).groupOptions || !!(column as DropDownSelectColumn).dropdownAction
}

//this functionality will be used in lists to come (we will have approximatly 20 more listviews to come and there will be
// the necessity for rowselection so, this will be used in future)

const isSelectRowColumn = (column: Column): column is SelectRowColumn => {
  return !!(column as SelectRowColumn).selectedObjectsIds
}

const isCheckboxColumn = (column: Column): column is CheckboxColumn => {
  return !!(column as CheckboxColumn).checkedIfTruthy
}

const isIconButtonColumn = (column: Column): column is IconButtonColumn => {
  return !!(column as IconButtonColumn).iconButton
}

export type { GetValueFunction, Column }

export {
  isActionsColumn,
  isEditableColumn,
  isPrefixedColumn,
  isSelectRowColumn,
  isCheckboxColumn,
  isDropDownSelectColumn,
  isIconButtonColumn,
}
