import React, { ChangeEvent, FC } from 'react'
import { FormFieldProps } from '@components/Form/FormFields'
import { ListFieldModel } from '@models/form/fields/FieldModels'
import { FieldType } from '@app/enums/form/FieldType'
import Loader from '@components/Loader'
import { Dropdown } from '@components/Dropdown'
import { getInputName } from '@utils/form/fields/getInputName'
import { mergeRules } from '@utils/form/rules/mergeRules'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'
import { useController } from 'react-hook-form'
import { DropdownOption } from '@models/form/dropdown/DropdownOption'
import { v4 } from 'uuid'
import { LabeledCheckbox } from '@components/Form/Labeled'
import { useIdGenerator } from '@features/focusedInput/hooks/useIdGenerator'
import { getDropdownsWithValues } from '@components/Form/FormFields/utils/FormListField/getDropdownsWithValues'
import { getCheckboxesWithValues } from '@components/Form/FormFields/utils/FormListField/getCheckboxesWithValues'
import { ListDropdownModel } from '@models/form/list'
import { UniqueSellingPointListCheckboxModel } from '@app/factory/article/models/UniqueSellingPointListCheckboxModel'

interface Props extends Omit<FormFieldProps, 'formField'> {
  formField: ListFieldModel
}

const FormListField: FC<Props> = ({ formField, control, disabled, sectionLabel }) => {
  const name = getInputName(formField, sectionLabel)
  const rules = mergeRules(formField.rules) as RegisterOptions
  const { generateId } = useIdGenerator('form')

  const { field } = useController({
    name,
    rules,
    control,
  })

  const formFieldHasValues = formField.value && !!formField.value?.length

  const handleDropdownChange = (option: DropdownOption, defaultIsUndefined?: boolean) => {
    if (formField.listItemType === FieldType.Dropdown && formFieldHasValues) {
      const values = getDropdownsWithValues(formField.value as ListDropdownModel[], option, defaultIsUndefined)

      field.onChange(values)
    }
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (formField.listItemType === FieldType.Checkbox && formFieldHasValues) {
      const values = getCheckboxesWithValues(
        formField.value as UniqueSellingPointListCheckboxModel[],
        event.target.id,
        checked,
      )

      field.onChange(values)
    }
  }

  if (!formField || !formField.value) {
    return <Loader />
  }

  return (
    <>
      {formField.listItemType === FieldType.Dropdown && (
        <>
          {formField.value?.map((dropdownField) => (
            <Dropdown
              key={v4()}
              options={dropdownField.dropdown.options}
              optionsGroup=''
              selected={dropdownField.dropdown.value}
              label={dropdownField.dropdown.label}
              disabled={disabled}
              onChange={handleDropdownChange}
              includeEmptyValue={dropdownField.dropdown.includeEmptyValue}
            />
          ))}
        </>
      )}

      {formField.listItemType === FieldType.Checkbox && (
        <>
          {formField.value?.map((checkboxField) => (
            <LabeledCheckbox
              {...field}
              key={v4()}
              id={generateId(checkboxField.label)}
              isChecked={checkboxField.checked}
              labelText={checkboxField.label}
              disabled={disabled}
              onChange={handleCheckboxChange}
            />
          ))}
        </>
      )}
    </>
  )
}

export default FormListField
