import React, { FC } from 'react'
import { FormFieldProps } from '@components/Form/FormFields'
import { createFormFields } from '@utils/form/fields/createFormFields'
import { CollectionFieldModel } from '@models/form/fields/FieldModels'
import useFieldsComponent from '@hooks/form/useFieldsComponent'

interface Props extends Omit<FormFieldProps, 'formField'> {
  formFields: CollectionFieldModel<Record<string, any>>
}

export const FormCollectionField: FC<Props> = ({ formFields, control, disabled, sectionLabel }) => {
  const fields = createFormFields(formFields.value, control, disabled, sectionLabel)
  const component = useFieldsComponent(fields)

  return <>{component}</>
}
