import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormHelperText, FormLabel } from '../../MaterialUI/core'
import { useTranslation } from '../../../utils/translation'
import FieldControl from './fragment/FieldControl'

const Field = ({ input, label, type, meta: { error, warning, touched }, ...rest }) => {
  const { t } = useTranslation()

  const labelTranslated = t(label)
  const nameTranslated = t(`form:${input.name}`)

  return (
    <FormControl>
      {label && <FormLabel>{labelTranslated}</FormLabel>}
      <FieldControl type={type} {...input} {...rest} />

      {touched && error && (
        <FormHelperText error>
          {t(error, {
            label: labelTranslated,
            field: nameTranslated,
            value: input.value,
          })}
        </FormHelperText>
      )}

      {touched && warning && (
        <FormHelperText>
          {t(warning, {
            label: labelTranslated,
            field: nameTranslated,
            value: input.value,
          })}
        </FormHelperText>
      )}
    </FormControl>
  )
}

Field.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    warning: PropTypes.string,
  }).isRequired,
}

export default Field
