import { generateUrl } from '@utils/route'
import { Url } from '@features/uniqueSellingPoints/api/model/Url'
import http from '@utils/http'

const fetchSuggestedUSPsForArticle = async (articleId: number) => {
  const url = generateUrl(Url.ArticleUniqueSellingPoints, { id: articleId })

  return http.get(url)
}

export { fetchSuggestedUSPsForArticle }
