import React, { FC, useCallback } from 'react'
import clsx from 'clsx'
import { v4 } from 'uuid'
import { Button, Typography } from '@components/MaterialUI/core'
import { makeStyles } from '@components/MaterialUI/styles'
import { LabeledStyles } from '@components/Form/Labeled'
import { FONT_WEIGHT_BOLD } from '@theme/constants'
import { generateUrl, useRedirectTo } from '@utils/route'
import { Link } from '@models/navigation/Link'
import { Theme } from '@components/MaterialUI/theme'
import { RoyalTooltip } from '@components/index'
import { TooltipDirection } from '@components/RoyalTooltip/config'
import { HTML_CONTENT_MAX_WIDTH, HTML_CONTENT_WIDTH } from '../config/constants'
import { Padding } from '@theme/styles/padding'

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    ...LabeledStyles.row,
  },
  line: {
    height: '30px',
    display: 'inline-flex',
    lineHeight: '30px',
    marginRight: '3px',

    '& input': {
      fontWeight: FONT_WEIGHT_BOLD,
    },
  },
  indent: {
    ...LabeledStyles.indent,
  },
  bottomSpace: {
    marginBottom: '20px',
  },
  linkButton: {
    padding: `${Padding.Small} ${Padding.Medium}`,
    '&:hover': {
      backgroundColor: theme.palette.royal.lightGray,
    },
  },
  buttonTooltip: {
    maxWidth: HTML_CONTENT_MAX_WIDTH,
    width: HTML_CONTENT_WIDTH,
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  tooltipContent: {
    width: 'inherit',
    flexWrap: 'wrap',
    whiteSpace: 'normal',
    justifyContent: 'center',
  },
}))

interface Props {
  link: Link
  labelText?: string
  className?: string
  withBottomMargin?: boolean
  withSeparator?: boolean
}

const LinkButton: FC<Props> = ({ link, className, withBottomMargin = false, withSeparator = false }) => {
  const classes = useStyles()
  const redirectTo = useRedirectTo()

  const getUrl = useCallback(() => {
    return generateUrl(link.route, link.routeParameters)
  }, [link])

  const handleRedirect = useCallback(() => {
    redirectTo(getUrl())
  }, [getUrl, redirectTo])

  return (
    <div className={clsx(className, { [classes.bottomSpace]: withBottomMargin })} key={v4()}>
      <div>
        <RoyalTooltip
          value={
            <div className={classes.buttonTooltip}>
              <Typography>{link.text}</Typography>
              <div className={classes.tooltipContent}>{link.hoverText}</div>
            </div>
          }
          direction={TooltipDirection.BOTTOM}
        >
          <Button className={classes.linkButton} onClick={handleRedirect}>
            {link.text}
          </Button>
        </RoyalTooltip>
        {withSeparator && <span>, </span>}
      </div>
    </div>
  )
}

export default LinkButton
