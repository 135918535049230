import { FieldType } from '@app/enums/form/FieldType'
import { RuleTypeMessageModel } from '@models/form/rules/RuleTypeMessageModel'
import { ArticleFormFieldName } from '@app/enums/form/article/ArticleFormFieldName'

export abstract class AbstractFieldModel<F extends FieldType, V> {
  private _type: F
  private _value?: V
  private _label: string
  private _fieldName: string
  private _rules?: RuleTypeMessageModel[]

  protected constructor(type: F) {
    this._type = type
    this._label = ''
    this._fieldName = ArticleFormFieldName.Empty
  }

  get fieldName(): string {
    return this._fieldName
  }

  set fieldName(value: string) {
    this._fieldName = value
  }
  get type(): F {
    return this._type
  }

  set type(type: F) {
    this._type = type
  }

  get value(): V | undefined {
    return this._value
  }

  set value(value: V | undefined) {
    this._value = value
  }

  get label(): string {
    return this._label
  }

  set label(value: string) {
    this._label = value
  }

  get rules(): RuleTypeMessageModel[] | undefined {
    return this._rules
  }

  set rules(value: RuleTypeMessageModel[] | undefined) {
    this._rules = value
  }
}
