import { createSlice } from '@reduxjs/toolkit'
import { readArticleDownSellers } from '../action'
import { entityAdapter } from '../adapter'
import DownSellerSliceState from '../model/slice/DownSellerSliceState'
import DownSellerReducer from '../model/slice/DownSellerReducer'
import downSellersInitialState from '../initialState/downSellerState'

const downSellerSlice = createSlice<DownSellerSliceState, DownSellerReducer>({
  name: 'articles.readMany.downSellers',
  initialState: downSellersInitialState,
  reducers: {},
  extraReducers: {
    [readArticleDownSellers.pending.type]: (state) => {
      entityAdapter.removeAll(state)
      state.isLoading = downSellersInitialState.isLoading
      state.error = downSellersInitialState.error
    },
    [readArticleDownSellers.fulfilled.type]: (state, action) => {
      entityAdapter.upsertMany(state, action.payload.data)
      state.isLoading = false
      state.error = downSellersInitialState.error
    },
    [readArticleDownSellers.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default downSellerSlice
