import http from '@utils/http'
import { createSortingParameters } from '@utils/api/sorting'
import { Url } from '@features/attributes/api/model/Url'
import { generateUrl } from '@utils/route'

export const fetchAttributes = async (categoryId: number) => {
  const url = generateUrl(Url.CategoryAttributes, { id: categoryId })

  const sorting = createSortingParameters([
    {
      field: 'translations.name',
      desc: false,
    },
  ])

  return await http.get(url, {
    params: {
      ...sorting,
    },
  })
}
