import { createEntityAdapter } from '@reduxjs/toolkit'
import { ClientModel } from '@models/entities/clientOrders'

const selectId = (entity: ClientModel) => entity.id

const entityAdapter = createEntityAdapter({
  selectId,
})

export { selectId, entityAdapter }
