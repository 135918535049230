import { FieldType } from '@app/enums/form/FieldType'
import { AbstractFieldModel } from '@models/form/fields/FieldModels'

export class ObjectFieldModel<T extends Record<string, any>> extends AbstractFieldModel<
  FieldType.Object,
  T | undefined
> {
  constructor() {
    super(FieldType.Object)
  }
}
