import React, { FC } from 'react'
import { FormFieldProps } from '@components/Form/FormFields/FormFieldProps'
import FormTextField from '@components/Form/FormFields/FormTextField'
import { FormConfig } from '@config/form'

export const FormTextareaField: FC<FormFieldProps> = ({ formField, control, disabled, sectionLabel }) => {
  return (
    <FormTextField
      control={control}
      disabled={disabled}
      formField={formField}
      sectionLabel={sectionLabel}
      minRows={FormConfig.TextAreaMinRows}
    />
  )
}
