export enum ArticleInclude {
  AttributeValues = 'attributeValues',
  Brand = 'brand',
  Category = 'category',
  ClientChannels = 'clientChannels',
  Color = 'color',
  Dimensions = 'dimensions',
  Images = 'images',
  MetaData = 'metaData',
  Predecessor = 'predecessor',
  Prices = 'prices',
  Stock = 'stock',
  Successor = 'successor',
  Supplier = 'supplier',
  Taxes = 'taxes',
  Translations = 'translations',
  UniqueSellingPoint = 'uniqueSellingPoint',
}
