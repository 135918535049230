import { createSlice } from '@reduxjs/toolkit'
import { readSupplier } from '../action'
import { entityAdapter } from '../adapter'

const readSlice = createSlice({
  name: 'supplier_read',
  initialState: {
    ...entityAdapter.getInitialState(),
    loadingItems: [],
  },
  extraReducers: {
    [readSupplier.pending.type]: (state, action) => {
      const id = action.meta.arg
      state.loadingItems.push(id)
    },
    [readSupplier.fulfilled.type]: (state, action) => {
      const id = action.meta.arg
      state.loadingItems = state.loadingItems.filter((i) => i !== id)
      entityAdapter.upsertOne(state, action.payload)
    },
  },
})

export default readSlice
