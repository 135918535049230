import { createSlice, PayloadAction, Update } from '@reduxjs/toolkit'
import { updateGroupOrderDelivery, updateGroupOrderHasClientDelivery, updateGroupOrderItems } from '../action'
import { entityAdapter } from '../adapter'
import { GroupOrderModel } from '../../../../app/models/entities/groupOrder/GroupOrderModel'
import updateInitialState from '../initialState/updateState'
import UpdateSliceState from '../model/slice/UpdateSliceState'
import UpdateSliceReducer from '../model/slice/UpdateSliceReducer'

const updateSlice = createSlice<UpdateSliceState, UpdateSliceReducer>({
  name: 'groupOrders_update',
  initialState: updateInitialState,
  reducers: {},
  extraReducers: {
    [updateGroupOrderItems.pending.type]: (state) => {
      state.isLoading = true
      state.error = updateInitialState.error
      state.success = updateInitialState.success
    },
    [updateGroupOrderItems.fulfilled.type]: (state, action: PayloadAction<GroupOrderModel>) => {
      const groupOrder = action.payload

      state.isLoading = false
      state.error = updateInitialState.error
      state.success = groupOrder

      const data: Update<GroupOrderModel> = {
        id: groupOrder.id,
        changes: groupOrder,
      }

      entityAdapter.updateOne(state, data)
    },
    [updateGroupOrderItems.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      state.success = updateInitialState.success
    },
    [updateGroupOrderDelivery.pending.type]: (state) => {
      state.isLoading = true
      state.error = updateInitialState.error
      state.success = updateInitialState.success
    },
    [updateGroupOrderDelivery.fulfilled.type]: (state, action: PayloadAction<GroupOrderModel>) => {
      const groupOrder = action.payload

      state.isLoading = false
      state.error = updateInitialState.error
      state.success = groupOrder

      const data: Update<GroupOrderModel> = {
        id: groupOrder.id,
        changes: groupOrder,
      }

      entityAdapter.updateOne(state, data)
    },
    [updateGroupOrderDelivery.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      state.success = updateInitialState.success
    },
    [updateGroupOrderHasClientDelivery.pending.type]: (state) => {
      state.isLoading = true
      state.error = updateInitialState.error
      state.success = updateInitialState.success
    },
    [updateGroupOrderHasClientDelivery.fulfilled.type]: (state, action: PayloadAction<GroupOrderModel>) => {
      const groupOrder = action.payload

      state.isLoading = false
      state.error = updateInitialState.error
      state.success = groupOrder

      const data: Update<GroupOrderModel> = {
        id: groupOrder.id,
        changes: groupOrder,
      }

      entityAdapter.updateOne(state, data)
    },
    [updateGroupOrderHasClientDelivery.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      state.success = updateInitialState.success
    },
  },
})

export default updateSlice
