import { AnyAction, Dispatch } from 'redux'
import storage from '@features/auth/storage'
import { login, logout, tokenReceived } from '@features/auth/action'
import { parseJwt } from '@utils/api/data'
import { AuthenticationData, AuthenticationToken } from '@models/api/authentication'

interface AuthenticationResponse {
  token: string
  mercureToken: string
}

const tokenMiddleware = () => (next: Dispatch) => (action: AnyAction) => {
  switch (action.type) {
    case tokenReceived.type:
    case login.fulfilled.type: {
      const { token, mercureToken } = action.payload as AuthenticationResponse
      const parsedToken = parseJwt(token) as AuthenticationToken

      if (token && parsedToken.userId) {
        const data: AuthenticationData = {
          token,
          mercureToken,
          userId: parsedToken.userId,
        }

        storage.storeData(data)
      }

      break
    }

    case logout.fulfilled.type: {
      storage.clear()
      break
    }

    default:
      break
  }

  next(action)
}

export default tokenMiddleware
