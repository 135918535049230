import { FieldError } from 'react-hook-form'
import { useTranslation } from '@utils/translation'
import { isEmpty } from 'lodash'

const translationDomain = 'form'
const translationNode = 'errors'

export const useErrorMessage = (error?: FieldError): string | undefined => {
  const { t } = useTranslation()

  const buildLeftPart = (error: FieldError): string => {
    const translationKey = translationDomain + ':' + translationNode + '.' + error.type

    return t(translationKey)
  }

  const buildRightPart = (error: FieldError): string | undefined => {
    if (!error.message) {
      return
    }

    return t(error.message as string)
  }

  if (!error || (isEmpty(error.type) && isEmpty(error.message))) {
    return
  }

  const leftPart = buildLeftPart(error)
  const rightPart = buildRightPart(error)

  if (!rightPart) {
    return leftPart
  }

  return `${leftPart}: ${rightPart}`
}
