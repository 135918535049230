import { AnyAction } from 'redux'
import { AsyncThunk } from '@reduxjs/toolkit'
import { FulfilledAction, RejectedAction } from '@utils/redux/action'
import { HasIriEntity } from '@models/entities/HasIriEntity'

export const actionVerifier = (supportedActions: AsyncThunk<any, any, any>[]) => {
  const isPendingAction = (action: AnyAction): action is AnyAction => {
    if (supportedActions.length === 0) {
      return false
    }

    const filtered = supportedActions.filter((supported) => {
      return supported.pending.type === action.type
    })

    return filtered.length > 0
  }

  const isFulfilledAction = <Model extends HasIriEntity>(action: AnyAction): action is FulfilledAction<Model> => {
    if (supportedActions.length === 0) {
      return false
    }

    const filtered = supportedActions.filter((supported) => {
      return supported.fulfilled.type === action.type
    })

    return filtered.length > 0
  }

  const isRejectedAction = (action: AnyAction): action is RejectedAction => {
    if (supportedActions.length === 0) {
      return false
    }

    const filtered = supportedActions.filter((supported) => {
      return supported.rejected.type === action.type
    })

    return filtered.length > 0
  }

  return {
    isPendingAction,
    isFulfilledAction,
    isRejectedAction,
  }
}
