export default {
  pickerView: {
    display: 'flex',
    maxWidth: '365px',
    minWidth: '365px',
    minHeight: '305px',
    overflowX: 'hidden',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}
