import { createSlice } from '@reduxjs/toolkit'
import { readRoyalCrownDeliveryAddresses } from '../action'
import { entityAdapter } from '../adapter'
import { initialState, SliceStateModel } from './models/SliceStateModel'
import { SliceReducersModel } from './models/SliceReducerModel'

const royalCrownSlice = createSlice<SliceStateModel, SliceReducersModel>({
  name: 'royalCrownDeliveryAddresses',
  initialState,
  reducers: {},
  extraReducers: {
    [readRoyalCrownDeliveryAddresses.pending.type]: (state) => {
      state.isLoading = true
    },
    [readRoyalCrownDeliveryAddresses.fulfilled.type]: (state, action) => {
      entityAdapter.upsertMany(state, action.payload.data)
      state.isLoading = false
    },
    [readRoyalCrownDeliveryAddresses.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default royalCrownSlice
