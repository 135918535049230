import { ClientOrderInclude } from '@features/clientOrders/api/model/ClientOrderInclude'

export default [
  ClientOrderInclude.Client,
  ClientOrderInclude.Addresses,
  ClientOrderInclude.DeliveryAddress,
  ClientOrderInclude.Totals,
  ClientOrderInclude.AdditionalFees,
  ClientOrderInclude.TransportFees,
]
