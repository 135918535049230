import http from '@utils/http'
import { GetItemsPaginatedParameters, UpdateParameters } from '@models/api/request'
import { ClientOrderOption } from '@models/entities/clientOrders'
import { generateUrl } from '@utils/route'
import { Url } from '@features/clientOrders/api/model/Url'
import { ClientOrderInclude } from '@features/clientOrders/api/model/ClientOrderInclude'
import readClientOrderIncludes from '@features/clientOrders/api/config/readClientOrderIncludes'
import { readResource } from '@features/@common/api/api'

interface PostParameters {
  client: string
  reference: string
  desiredDeliveryDate: string
  deliveryAddress: string
}

interface FetchParameters extends Omit<GetItemsPaginatedParameters, 'extra'> {
  extra: {
    'documentData.clientData.companyName': string
    'documentData.documentNumber': string
    'documentData.clientData.customerNumber': string
    'documentData.reference': string
    'documentData.desiredDeliveryDate': string
  }
}

interface UpdateAddressParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    deliveryAddress?: string
  }
}

interface UpdatePaymentDiscountParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    paymentDiscount?: number
  }
}

interface UpdatePaymentPeriodParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    paymentPeriod?: number
  }
}

interface UpdateDateParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    desiredDeliveryDate?: string
  }
}

interface UpdateOptionsParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    [option in ClientOrderOption]?: boolean
  }
}

interface UpdateNoteParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    note?: string
  }
}

interface UpdateInternalNoteParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    internalNote?: string
  }
}

interface UpdateUsedByParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    isUsedBy: string | null
  }
}

const fetchClientOrders = async (parameters: FetchParameters) => {
  return http.get(Url.Orders, {
    params: {
      ...parameters.pagination,
      ...parameters.sorting,
      ...parameters.extra,
      include: [ClientOrderInclude.Client, ClientOrderInclude.Addresses, ClientOrderInclude.Items],
    },
  })
}

const readClientOrder = async (id: number) => {
  const url = generateUrl(Url.Details, { id })

  return readResource(url, readClientOrderIncludes)
}

const createClientOrder = async (parameters: PostParameters) => {
  const url = generateUrl(Url.Orders)

  return http.post(url, parameters)
}

const updateClientOrderAddress = async (parameters: UpdateAddressParameters) => {
  const url = generateUpdateOrderUrl(parameters)

  return http.patch(url, parameters.changed)
}

const updateClientOrderDeliveryDate = async (parameters: UpdateDateParameters) => {
  const url = generateUpdateOrderUrl(parameters)

  return http.patch(url, parameters.changed)
}

const updateClientOrderNote = async (parameters: UpdateNoteParameters) => {
  const url = generateUpdateOrderUrl(parameters)

  return http.patch(url, parameters.changed)
}

const updateClientOrderInternalNote = async (parameters: UpdateInternalNoteParameters) => {
  const url = generateUpdateOrderUrl(parameters)

  return http.patch(url, parameters.changed)
}

const updateClientOrderOptions = async (parameters: UpdateOptionsParameters) => {
  const url = generateUpdateOrderUrl(parameters)

  return http.patch(url, parameters.changed, {
    params: {
      include: readClientOrderIncludes,
    },
  })
}

const updateClientOrderUsedBy = async (parameters: UpdateUsedByParameters) => {
  const url = generateUpdateOrderUrl(parameters)

  return http.patch(url, parameters.changed)
}

const updateClientOrderPaymentDiscount = async (parameters: UpdatePaymentDiscountParameters) => {
  const url = generateUpdateOrderUrl(parameters)

  return http.patch(url, parameters.changed, {
    params: {
      include: readClientOrderIncludes,
    },
  })
}

const updateClientOrderPaymentPeriod = async (parameters: UpdatePaymentPeriodParameters) => {
  const url = generateUpdateOrderUrl(parameters)

  return http.patch(url, parameters.changed)
}

const generateUpdateOrderUrl = (parameters: UpdateParameters): string => {
  return generateUrl(Url.Update, { id: parameters.id })
}

export type {
  FetchParameters,
  PostParameters,
  UpdateAddressParameters,
  UpdateOptionsParameters,
  UpdateDateParameters,
  UpdateNoteParameters,
  UpdateInternalNoteParameters,
  UpdateUsedByParameters,
  UpdatePaymentDiscountParameters,
  UpdatePaymentPeriodParameters,
}

export {
  fetchClientOrders,
  readClientOrder,
  createClientOrder,
  updateClientOrderAddress,
  updateClientOrderOptions,
  updateClientOrderDeliveryDate,
  updateClientOrderInternalNote,
  updateClientOrderNote,
  updateClientOrderUsedBy,
  updateClientOrderPaymentDiscount,
  updateClientOrderPaymentPeriod,
}
