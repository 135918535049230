import { createSlice } from '@reduxjs/toolkit'
import { fetchClientOrders } from '../action'
import { entityAdapter } from '../adapter'
import { CollectionSliceState } from '@features/@common/slices/collection/CollectionSliceState'
import { ClientOrderModel } from '@models/entities/clientOrders/ClientOrderModel'
import { CollectionSliceReducers } from '@features/@common/reducers/CollectionSliceReducers'
import { createCollectionInitialState } from '@features/@common/slices/collection/createCollectionInitialState'

const initialState = createCollectionInitialState<ClientOrderModel>(entityAdapter)

const mainSlice = createSlice<CollectionSliceState<ClientOrderModel>, CollectionSliceReducers<ClientOrderModel>>({
  name: 'clientOrders',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchClientOrders.pending.type]: (state) => {
      state.isLoading = true
      state.error = undefined
    },
    [fetchClientOrders.fulfilled.type]: (state, action) => {
      state.isLoading = false
      entityAdapter.upsertMany(state, action.payload.data)
    },
    [fetchClientOrders.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default mainSlice
