const hasCssClass = (element: HTMLElement, className: string): boolean => {
  if (!element || className === '') {
    return false
  }

  const regex = new RegExp(className)

  return regex.test(element.className)
}

export { hasCssClass }
