import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import { RouteConfig, RouteConfigComponentProps } from 'react-router-config'
import { AuthGuard } from '@features/auth/components'
import { MainLayout } from '../../layouts'
import { ARTICLES_PATH, CONDITIONS_PATH } from './paths'
import { createPath } from '@utils/route'

const supplierRoutes: RouteConfig = {
  route: '*',
  component: (props: RouteConfigComponentProps) => (
    <AuthGuard>
      <MainLayout {...props} />
    </AuthGuard>
  ),
  routes: [
    {
      path: createPath(ARTICLES_PATH),
      exact: true,
      component: lazy(() => import('../../views/Products/SupplierArticles')),
    },
    {
      path: createPath(CONDITIONS_PATH),
      exact: true,
      component: lazy(() => import('../../views/Products/SupplierConditions')),
    },
    {
      path: '*',
      component: () => <Redirect to={ARTICLES_PATH} />,
    },
  ],
}

export default supplierRoutes
