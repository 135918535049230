import React, { ChangeEvent, FC, Ref, useState } from 'react'
import clsx from 'clsx'
import { v4 } from 'uuid'
import { makeStyles } from '@components/MaterialUI/styles'
import { Theme } from '@components/MaterialUI/theme'
import { useTranslation } from '@utils/translation'
import { Checkbox, InputLabel } from '@components/MaterialUI/core'
import { FONT_WEIGHT_BOLD } from '@theme/constants'

const useStyles = makeStyles((theme: Theme) => {
  const size = '15px'
  const height = '30px'

  return {
    row: {
      display: 'flex',
      justifyContent: 'flex-start',
      height: height,
      lineHeight: height,
    },
    checkbox: {
      height: size,
      width: size,
      margin: 'auto 0',
      marginRight: '20px',
      fontWeight: FONT_WEIGHT_BOLD,
      '&:hover': {
        borderColor: theme.palette.common.black,
      },
    },
    label: {
      lineHeight: height,
    },
  }
})

interface Props {
  isChecked: boolean
  labelText: string
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  className?: string
  disabled?: boolean
  ref?: Ref<any>
  id: string
}

const LabeledCheckbox: FC<Props> = ({ isChecked, labelText = '', className, disabled = false, onChange, ref, id }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [checked, setChecked] = useState<boolean>(isChecked)

  const toggleChecked = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setChecked(checked)
    onChange(event, checked)
  }

  return (
    <div className={clsx(classes.row, className)} key={v4()}>
      <Checkbox
        className={classes.checkbox}
        checked={checked}
        id={id}
        onChange={toggleChecked}
        disabled={disabled}
        inputRef={ref}
      />
      <InputLabel className={classes.label}> {t(labelText)}</InputLabel>
    </div>
  )
}

export default LabeledCheckbox
