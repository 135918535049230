import React, { lazy } from 'react'
import { RouteConfigComponentProps } from 'react-router-config'
import { AuthGuard } from '../../features/auth/components'
import { MainLayout } from '../../layouts'
import { createPath } from '@utils/route'
import {
  ARTICLE_DETAILS_PATH,
  ARTICLES_PATH,
  BRAND_DETAILS_PATH,
  BRANDS_PATH,
  CLIENT_ORDER_PATH,
  CLIENT_ORDERS_PATH,
  DASHBOARD_PATH,
  GROUP_ORDER_PATH,
  GROUP_ORDERS_PATH,
  SETTINGS_PATH,
  SETTINGS_PATH_STEP2,
  SUPPLIER_DETAILS_PATH,
  SUPPLIERS_PATH,
} from './paths'
import Dashboard from '../../views/Dashboard/Dashboard'

const clientRoutes = {
  route: '*',
  component: (props: RouteConfigComponentProps) => (
    <AuthGuard>
      <MainLayout {...props} />
    </AuthGuard>
  ),
  routes: [
    {
      path: createPath(DASHBOARD_PATH),
      exact: true,
      component: Dashboard,
    },
    {
      path: createPath(SUPPLIERS_PATH),
      exact: true,
      component: lazy(() => import('../../views/Suppliers/List')),
    },
    {
      path: createPath(SUPPLIER_DETAILS_PATH),
      exact: true,
      component: lazy(() => import('../../views/Suppliers/Details')),
    },
    {
      path: createPath(ARTICLES_PATH),
      exact: true,
      component: lazy(() => import('../../views/Articles/List')),
    },
    {
      path: createPath(ARTICLE_DETAILS_PATH),
      exact: true,
      component: lazy(() => import('../../views/Articles/Details')),
    },
    {
      path: createPath(CLIENT_ORDERS_PATH),
      exact: true,
      component: lazy(() => import('../../views/ClientOrders/List')),
    },
    {
      path: createPath(CLIENT_ORDER_PATH),
      exact: true,
      component: lazy(() => import('../../views/ClientOrders/Details')),
    },
    {
      path: createPath(GROUP_ORDERS_PATH),
      exact: true,
      component: lazy(() => import('../../views/GroupOrders/List')),
    },
    {
      path: createPath(GROUP_ORDER_PATH),
      exact: true,
      component: lazy(() => import('../../views/GroupOrders/Details')),
    },
    {
      path: createPath(BRANDS_PATH),
      exact: true,
      component: lazy(() => import('../../views/Brands/List')),
    },
    {
      path: createPath(BRAND_DETAILS_PATH),
      exact: true,
      component: lazy(() => import('../../views/Brands/Details')),
    },
    {
      path: createPath(SETTINGS_PATH),
      exact: true,
      component: lazy(() => import('@views/Settings/SettingsHome')),
    },
    {
      path: createPath(SETTINGS_PATH_STEP2),
      exact: true,
      component: lazy(() => import('@views/Settings/SettingsConfigurator')),
    },
  ],
}

export default clientRoutes
