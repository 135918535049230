import { entityAdapter } from '../adapter'
import UpdateSliceState from '../model/slice/UpdateSliceState'

const updateInitialState: UpdateSliceState = {
  ...entityAdapter.getInitialState(),
  updatedSuccessfully: false,
  isUpdating: true,
  error: undefined,
  success: undefined,
}

export default updateInitialState
