import React, { ElementType, FC, MouseEvent } from 'react'
import { Button } from '@components/MaterialUI/core'
import { Loader, RoyalTooltip } from '@components/index'
import { ButtonType } from '@app/enums/form/ButtonType'
import { Color } from '@models/common/Color'
import { RoyalTooltipDelay, TooltipDirection } from '@components/RoyalTooltip/config'

interface Props {
  onClick?: (event: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLAnchorElement>) => void
  title?: string
  tooltipDirection?: TooltipDirection
  type?: ButtonType
  color?: Color
  isProcessing?: boolean
  icon?: ElementType
  className?: string
}

const ProcessingButton: FC<Props> = ({
  children,
  onClick,
  type = ButtonType.Button,
  color = Color.Inherit,
  icon: Icon,
  isProcessing = false,
  title,
  tooltipDirection = TooltipDirection.BOTTOM,
  className,
}) => {
  const handleClick = (event: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick(event)
    }
  }

  return (
    <RoyalTooltip value={title} delay={RoyalTooltipDelay.SHORT} direction={tooltipDirection}>
      <Button
        type={type}
        variant='contained'
        color={color}
        onClick={handleClick}
        startIcon={Icon && <Icon />}
        className={className}
      >
        {isProcessing ? <Loader variant='inline' color='inherit' /> : children}
      </Button>
    </RoyalTooltip>
  )
}

export default ProcessingButton
