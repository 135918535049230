import { NavLink } from 'react-router-dom'
import React, { FC } from 'react'
import { Link, Typography } from '../MaterialUI/core'
import Item from '@features/breadcrumbs/model/Item'

interface Props {
  item: Item
}

const Breadcrumb: FC<Props> = ({ item }) => {
  const { label } = item
  const { url } = item

  if (url === undefined) {
    return <Typography color='textPrimary'>{label}</Typography>
  }

  return (
    <Link component={NavLink} color='inherit' to={url}>
      {label}
    </Link>
  )
}

export default Breadcrumb
