import React, { FC, ReactNode } from 'react'
import clsx from 'clsx'
import { v4 } from 'uuid'
import { makeStyles } from '@components/MaterialUI/styles'
import { LabeledStyles } from '@components/Form/Labeled'
import { FONT_WEIGHT_BOLD } from '@theme/constants'
import { useTranslation } from '@utils/translation'
import { InputLabel } from '@components/MaterialUI/core'
import LinkButton from '../LinkButton'
import { Link } from '@models/navigation/Link'

const useStyles = makeStyles(() => ({
  row: {
    ...LabeledStyles.row,
    minHeight: '30px',
    height: 'auto',
  },
  line: {
    ...LabeledStyles.line,
  },
  indent: {
    ...LabeledStyles.indent,
  },
  bottomSpace: {
    marginBottom: '20px',
  },
  links: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(50% - 3px)',
    justifyContent: 'flex-start',
    paddingLeft: '6px',
    '& button': {
      fontWeight: FONT_WEIGHT_BOLD,
    },
  },
}))

interface Props {
  links: Link[]
  labelText?: string
  indent?: boolean
  className?: string
  showIfEmptyValue?: boolean
  withBottomMargin?: boolean
}

const LabeledLinkButton: FC<Props> = ({
  links,
  labelText = '',
  showIfEmptyValue = false,
  withBottomMargin = false,
  indent = false,
  className,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const getLinks = (): ReactNode[] => {
    return links.map((link, index) => <LinkButton link={link} withSeparator={index < links.length - 1} key={v4()} />)
  }

  if (getLinks().length === 0 && !showIfEmptyValue) {
    return <></>
  }

  return (
    <div className={clsx(classes.row, className, { [classes.bottomSpace]: withBottomMargin })} key={v4()}>
      <InputLabel className={clsx(classes.line, { [classes.indent]: indent })}>{labelText && t(labelText)}</InputLabel>
      <div className={classes.links}>{getLinks()}</div>
    </div>
  )
}

export default LabeledLinkButton
