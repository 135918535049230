import React, { FC } from 'react'
import { ArrowDropDownIcon, ArrowDropUpIcon } from '../../MaterialUI/icons'
import { SortingParameter } from '@utils/api/sorting'

type Props = {
  sortBy?: string
  sortingParameter?: SortingParameter
}

const HeaderSortingArrow: FC<Props> = ({ sortBy, sortingParameter }) => {
  if (!!sortBy && !!sortingParameter && sortingParameter.field === sortBy) {
    if (sortingParameter.desc) {
      return <ArrowDropUpIcon />
    }

    return <ArrowDropDownIcon />
  }

  return null
}

export default HeaderSortingArrow
