import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit'
import SliceState from './model/SliceState'

type SliceReducers = SliceCaseReducers<SliceState>

const initialState: SliceState = {
  focusedId: null,
}

const focusedInputSlice = createSlice<SliceState, SliceReducers>({
  name: 'focusedInput',
  initialState,
  reducers: {
    setFocusedId: (state, action) => {
      state.focusedId = action.payload
    },
  },
})

export { focusedInputSlice }
