import createApiAction from '@features/@common/actions/api-actions'
import { parsePaginatedCollection } from '@utils/api/data'
import * as api from '../api'
import { useClientId } from '@features/@common/hooks'

const fetchDeliveryAddresses = createApiAction('deliveryAddresses.fetch', async () => {
  const response = await api.fetchDeliveryAddresses()

  return parsePaginatedCollection(response)
})

const createDeliveryAddress = createApiAction('deliveryAddresses.create', api.createDeliveryAddress)

const readDeliveryAddresses = createApiAction('deliveryAddresses.read', async (id: number) => {
  const response = await api.readDeliveryAddresses(id)

  return parsePaginatedCollection(response)
})

const readRoyalCrownDeliveryAddresses = createApiAction('deliveryAddresses.royalCrown', async () => {
  const clientId = useClientId()
  const response = await api.readDeliveryAddresses(clientId as number)

  return parsePaginatedCollection(response)
})

// todo Is it necessary (not used anywhere)?
const updateDeliveryAddress = createApiAction('deliveryAddresses.update', api.updateDeliveryAddress)

export {
  fetchDeliveryAddresses,
  createDeliveryAddress,
  readDeliveryAddresses,
  updateDeliveryAddress,
  readRoyalCrownDeliveryAddresses,
}
