import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '../../../../components/MaterialUI/styles'
import { SvgIconTypeMap, Theme } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'

const useStyles = makeStyles((theme: Theme) => {
  const tileSize = '4rem'

  return {
    root: {
      width: '23%',
      minWidth: '60px',
      minHeight: '60px',
      height: '0',
      paddingBottom: '23%',
      marginBottom: '3%',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.common.black,
    },
    tileContainer: {
      width: '70%',
      height: 0,
      paddingBottom: '70%',
      margin: 'auto',
    },
    tile: {
      backgroundColor: theme.palette.royal.nearlyWhite,
      boxShadow: `0px 0px 4px 4px ${theme.palette.royal.darkerGray}`,
      border: 0,
      borderRadius: theme.shape.borderRadius,
      height: tileSize,
      width: tileSize,
      cursor: 'pointer',
    },
    tileLabel: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
    },
    tileIcon: {
      fontSize: '1.75rem',
    },
  }
})

interface Props {
  route: string
  icon: OverridableComponent<SvgIconTypeMap>
  title: string
}

const Tile: FC<Props> = ({ route, title, icon: Icon }) => {
  const history = useHistory()
  const classes = useStyles()

  const handleClick = () => {
    history.push(route)
  }

  return (
    <li className={classes.root}>
      <div className={classes.tileContainer}>
        <button className={classes.tile} type='button' onClick={handleClick}>
          {Icon && <Icon className={classes.tileIcon} />}
        </button>
      </div>
      <div
        tabIndex={0}
        role='button'
        onKeyDown={handleClick}
        className={classes.tileLabel}
        onClick={handleClick}
        title={title}
      >
        {title}
      </div>
    </li>
  )
}

export default Tile
