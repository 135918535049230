import React, { FC } from 'react'
import { Pagination } from '../MaterialUI/lab'
import { FormControl, Grid, InputLabel, Select } from '../MaterialUI/core'
import { makeStyles } from '../MaterialUI/styles'
import { useTranslation } from '@utils/translation'
import { PaginationOptions } from '@models/pagination/PaginationOptions'
import { DEFAULT_ROWS_PER_PAGE } from '@config/pagination'
import { Theme } from '@components/MaterialUI/theme'

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 70,
  },
}))

interface Props {
  isLoading: boolean
  options: PaginationOptions
  asTableRow?: boolean
  columnsCount?: number
}

const RoyalPagination: FC<Props> = ({ isLoading, options, asTableRow = true, columnsCount = undefined }) => {
  if (asTableRow && !columnsCount) {
    throw Error('Count of columns is required (if pagination should be a part of table)')
  }

  const { t } = useTranslation()
  const classes = useStyles()
  const getPageIndex = () => Number(options.page)
  const getMaxPageIndex = () => Math.ceil(options.totalItems / options.perPage)

  const isReady = () => {
    const pageIndex = getPageIndex()
    const maxPageIndex = getMaxPageIndex()

    return !isLoading && options.totalItems > 0 && pageIndex <= maxPageIndex
  }

  if (!isReady()) {
    return null
  }

  const handleChangePage = (event: any, currentPage: number) => {
    options.onChangePage(currentPage)
  }

  const handleChangeRowsPerPage = (event: any) => {
    let perPage = event?.target?.value

    if (perPage === undefined) {
      return
    }

    perPage = Number(perPage)
    options.onChangeRowsPerPage(perPage)
  }

  return (
    <Grid container direction='row' justify='flex-end' alignItems='center'>
      <Pagination count={getMaxPageIndex()} page={isLoading ? 0 : getPageIndex()} onChange={handleChangePage} />
      <FormControl variant='outlined' className={classes.formControl}>
        <InputLabel htmlFor='outlined-amount-rows'>{t('list.rows_per_page')}</InputLabel>
        <Select
          native
          value={options.perPage}
          label={t('list.rows_per_page')}
          onChange={handleChangeRowsPerPage}
          inputProps={{
            name: 'amount-rows',
            id: 'outlined-amount-rows',
          }}
        >
          {DEFAULT_ROWS_PER_PAGE.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )
}

export default RoyalPagination
