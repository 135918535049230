import { createPaginationSlice } from '@features/@common'
import { fetchBrandConditionsForBrand } from '../action'
import { BrandConditionModel } from '@models/entities/brands/BrandConditionModel'

const mainSlice = createPaginationSlice<BrandConditionModel>(
  'brandConditions.forBrand.fetch',
  fetchBrandConditionsForBrand,
)

export { mainSlice }
