import React, { ChangeEvent, FC } from 'react'
import { makeStyles } from '@components/MaterialUI/styles'
import { RoyalTooltipDelay, TooltipDirection } from '@components/RoyalTooltip/config'
import { RoyalTooltip } from '@components/index'
import { Button } from '@components/MaterialUI/core'
import { Color } from '@models/common/Color'
import { useTranslation } from '@utils/translation'
import { UploadType } from '@components/MediaUpload/config/UploadType'
import { getAcceptTypes } from '@utils/upload'

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
}))

interface FileUploadProps {
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void
  tooltipDirection?: TooltipDirection
  tooltipTitle?: string
  uploadType: UploadType
}

const FileUploadButton: FC<FileUploadProps> = ({
  onFileChange,
  tooltipTitle,
  tooltipDirection = TooltipDirection.BOTTOM,
  uploadType,
}) => {
  const classes = useStyles()
  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation()

  const handleClick = () => {
    if (!!hiddenFileInput && !!hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onFileChange(event)
  }

  return (
    <>
      <RoyalTooltip
        value={tooltipTitle ?? t('upload:actions.browse')}
        delay={RoyalTooltipDelay.SHORT}
        direction={tooltipDirection}
      >
        <Button color={Color.Secondary} onClick={handleClick}>
          {t('upload:actions.browse')}
        </Button>
      </RoyalTooltip>
      <input
        className={classes.input}
        type='file'
        onChange={handleChange}
        ref={hiddenFileInput}
        accept={getAcceptTypes(uploadType)}
      />
    </>
  )
}

export default FileUploadButton
