import { combineReducers } from 'redux'
import { mainSlice, readSlice, royalCrownSlice, updateSlice } from './slice'

const reducer = combineReducers({
  main: mainSlice.reducer,
  royalCrown: royalCrownSlice.reducer,
  read: readSlice.reducer,
  update: updateSlice.reducer,
})

export default reducer
