import { slugify } from '@utils/string'

interface Result {
  generateId: (position: number | string) => string
}

export const useIdGenerator = (prefix: string): Result => {
  const generateId = (position: number | string): string => {
    return slugify(prefix, position)
  }

  return {
    generateId,
  }
}
