import React, { FC } from 'react'
import { useTranslation } from '@utils/translation'
import SearchForm from '../SearchForm/SearchForm'
import { ProcessingButton } from '../index'
import { makeStyles } from '../MaterialUI/styles'
import { Color } from '@models/common/Color'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '50%',
  },
  button: {
    margin: 'auto 0',
  },
}))

interface Props {
  isAdding: boolean
  onButtonClick: (value: boolean) => void
  filters: string
  onFilterChange: (value: string) => void
  buttonTooltip: string
  buttonText?: string
}

const RoyalSearchBar: FC<Props> = ({
  isAdding,
  onButtonClick,
  filters,
  onFilterChange,
  buttonTooltip,
  buttonText = 'form:action.create',
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleButtonClick = () => {
    onButtonClick(!isAdding)
  }

  return (
    <div className={classes.root}>
      <SearchForm className={classes.searchField} defaultValue={filters} onChange={onFilterChange} />
      <div className={classes.button}>
        <ProcessingButton onClick={handleButtonClick} color={Color.Primary} title={t(buttonTooltip)}>
          {t(buttonText as string)}
        </ProcessingButton>
      </div>
    </div>
  )
}

export default RoyalSearchBar
