import moment, { Moment } from 'moment'
import { isBefore, isToday } from 'date-fns'
import { Day } from '@config/date'
import localeConfiguration, { DEFAULT_COUNTRY_ISO_CODE } from '@config/locale'
import { DateFormat } from '@config/dateFormat'

const DateOptions = {
  weekStartsOn: localeConfiguration.firstDayOfWeek,
}

const getLocalisedDateFormat = () => moment.localeData(DEFAULT_COUNTRY_ISO_CODE).longDateFormat(DateFormat.Long)

const parseDate = (date: Moment | string, format: moment.MomentFormatSpecification = DateFormat.WithTime): Moment => {
  return moment(date, format, true)
}

const formatDate = (date: Moment | string, format: string): string | undefined => {
  const dateParsed = parseDate(date)

  if (dateParsed.isValid()) {
    return dateParsed.format(format)
  }

  return
}

const isFuture = (date: Moment | string): boolean | undefined => {
  const dateParsed = parseDate(date)

  if (dateParsed.isValid()) {
    return dateParsed.isAfter(moment())
  }

  return
}

const isPast = (date: Moment | string): boolean | undefined => {
  const dateParsed = parseDate(date)

  if (dateParsed.isValid()) {
    return dateParsed.isBefore(moment())
  }

  return
}

const isNowBetween = (startDate: Moment | string, endDate: Moment | string): boolean | undefined => {
  const startDateParsed = parseDate(startDate)
  const endDateParsed = parseDate(endDate)

  if (startDateParsed.isValid() && endDateParsed.isValid()) {
    return moment().isBetween(startDateParsed, endDateParsed)
  }

  return
}

const isTodayOrFuture = (date: Moment | string): boolean | undefined => {
  const dateParsed = parseDate(date)

  if (dateParsed.isValid()) {
    return isFuture(dateParsed) || isToday(dateParsed.toDate())
  }

  return
}

const isWeekend = (date: Moment): boolean => {
  const day = date.day()

  return day === Day.Saturday || day === Day.Sunday
}

const isBeforeToday = (date: Moment): boolean => {
  return isBefore(date.toDate(), moment().toDate())
}

const getTomorrow = (): Moment => {
  return moment().add(1, 'days')
}

const getNextWorkdayDate = (): Moment => {
  const date = getTomorrow()
  const day = date.day()

  if (day === Day.Saturday || day === Day.Sunday) {
    if (day === Day.Saturday) {
      date.add(1, 'w')
    }

    date.day(Day.Monday)
  }

  return date
}

export {
  DateOptions,
  getLocalisedDateFormat,
  parseDate,
  formatDate,
  isFuture,
  isNowBetween,
  isPast,
  isTodayOrFuture,
  isWeekend,
  isBeforeToday,
  getTomorrow,
  getNextWorkdayDate,
}
