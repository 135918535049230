import { slugify } from '@utils/string'
import { UniqueSellingPointListCheckboxModel } from '@app/factory/article/models/UniqueSellingPointListCheckboxModel'

const getCheckboxIndexFromListBySluggedId = (
  sluggedId: string,
  checkboxList: UniqueSellingPointListCheckboxModel[],
): number => {
  const formKey = 'form-'
  return checkboxList.findIndex((checkboxField) => {
    return slugify(checkboxField.label) === sluggedId.split(formKey)[1]
  })
}

export const getCheckboxesWithValues = (
  checkboxList: UniqueSellingPointListCheckboxModel[],
  sluggedId: string,
  checked: boolean,
): UniqueSellingPointListCheckboxModel[] => {
  const foundIndex: number = getCheckboxIndexFromListBySluggedId(sluggedId, checkboxList)

  if (foundIndex > -1) {
    checkboxList[foundIndex].checked = checked
  }

  return checkboxList
}
