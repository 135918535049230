import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

const PostalCode = ({ postalCode }) => {
  if (isEmpty(postalCode)) {
    return null
  }

  return `${postalCode.postalCode}, ${postalCode.city}`
}

PostalCode.propTypes = {
  postalCode: PropTypes.shape({
    postalCode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
  }),
}

export default PostalCode
