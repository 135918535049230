import { CollectionSliceState } from '@features/@common/slices/collection/CollectionSliceState'
import { HasIdEntity } from '@models/entities/HasIdEntity'
import { EntityAdapter } from '@reduxjs/toolkit'

export const createCollectionInitialState = <EntityModel extends HasIdEntity>(
  entityAdapter: EntityAdapter<EntityModel>,
): CollectionSliceState<EntityModel> => {
  return {
    ...entityAdapter.getInitialState(),
    isLoading: true,
    error: undefined,
  }
}
