import PropTypes from 'prop-types'

const AuthUserType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  email: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
})

// eslint-disable-next-line import/prefer-default-export
export { AuthUserType }
