import { entityAdapter } from '../adapter'
import MainSliceState from '../model/slice/MainSliceState'

const mainInitialState: MainSliceState = {
  ...entityAdapter.getInitialState(),
  isLoading: true,
  error: undefined,
}

export default mainInitialState
