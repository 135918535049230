import http from '@utils/http'
import { Url } from '@features/colors/url'
import { createSortingParameters } from '@utils/api/sorting'

export const fetchColors = async () => {
  const sorting = createSortingParameters([
    {
      field: 'translations.name',
      desc: false,
    },
  ])

  return await http.get(Url.Colors, {
    params: {
      ...sorting,
    },
  })
}
