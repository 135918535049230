import http from '@utils/http'
import { generateUrl } from '@utils/route'
import { GetItemsPaginatedParameters } from '@models/api/request/GetItemsPaginatedParameters'
import fetchCategoryInclude from '@features/categories/api/config/fetchCategoryInclude'

const CATEGORIES_API_URL = '/api/categories'

interface FetchCategoriesParameters extends GetItemsPaginatedParameters {
  extra: {
    name: string
  }
}

const fetchCategories = async (parameters: FetchCategoriesParameters) => {
  const url = generateUrl(CATEGORIES_API_URL)

  return http.get(url, {
    headers: parameters.headers,
    params: {
      include: fetchCategoryInclude,
      ...parameters.pagination,
      ...parameters.sorting,
      ...parameters.extra,
    },
  })
}

export type { FetchCategoriesParameters }
export { fetchCategories }
