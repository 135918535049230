import http from '@utils/http'
import { generateUrl } from '@utils/route'
import { Url } from '@features/mediaObjects/api/model/Url'

interface PostParameters {
  mediaObject: FormData
}

const createImageMediaObject = async (parameters: FormData) => {
  const url = generateUrl(Url.MediaObjects)
  return http.post(url, parameters)
}

export type { PostParameters }
export { createImageMediaObject }
