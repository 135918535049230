import moment, { Moment } from 'moment'
import { getISOWeek, isThisWeek } from 'date-fns'
import { Day, Month, UnitOfTime } from '@config/date'
import {
  DAY_ONE_MONTH_ONE,
  MAX_WEEK_PICKER_ENTRY,
  MAX_YEAR,
  MIN_WEEK_PICKER_ENTRY,
  NOW,
  STANDARD_WEEK_AMOUNT,
} from '../DatePicker/utilities/constants'
import localeConfiguration from '@config/locale'
import { DateErrors } from '../DatePicker/utilities/enums'
import { parseDate } from '@utils/date'
import { DateErrorType, getDateError } from '../DatePicker/utilities/functions'
import { DateFormat } from '@config/dateFormat'

interface WeekPickerValue {
  year: number
  week: number
}

const formatWeek = (week: number): string => {
  if (week < 10) {
    return `0${week}`
  }

  return week.toString()
}

export const getNumbersFromWeekPickerString = (weekPickerString: string): WeekPickerValue | null => {
  const value = weekPickerString.match(/(\d{4})-(\d{2})/)

  if (value) {
    return {
      year: Number(value[1]),
      week: Number(value[2]),
    }
  }

  return null
}

export const getWeekFromDate = (date: Moment): string => {
  const week = getISOWeek(date.toDate())
  const weekFormatted = formatWeek(week)

  const month = date.month()
  let year = date.year()

  // Avoid strange 2021-53 value when there is a break/change of year (December 31 -> January 1) and week number is 53th
  // Example: 2021-01-01 -> 2021 (year), 53 (week)
  if (month === Month.January && Number(week) >= STANDARD_WEEK_AMOUNT) {
    year -= 1
  }

  return `${year}-${weekFormatted}`
}

export const getDateFromWeek = (value: string): Moment | undefined => {
  const dateNumbers = getNumbersFromWeekPickerString(value)

  if (dateNumbers) {
    const inputValue = Number(`${dateNumbers.year}${dateNumbers.week}`)

    if (Number.isNaN(dateNumbers)) {
      return
    }

    if (inputValue >= MIN_WEEK_PICKER_ENTRY && inputValue < MAX_WEEK_PICKER_ENTRY) {
      const { year } = dateNumbers
      const { week } = dateNumbers

      const result = moment().year(year).isoWeek(week).day(localeConfiguration.firstDayOfWeek)
      const thisWeek = isThisWeek(result.toDate())

      if (thisWeek) {
        return result.clone().add(1, UnitOfTime.Day)
      }

      return result
    }
  }
}

export const validate = (weekPickerString: string): DateErrorType | undefined => {
  const dateNumbers = getNumbersFromWeekPickerString(weekPickerString)

  if (dateNumbers) {
    const dateValue = Number(`${dateNumbers.year}${dateNumbers.week}`)

    if (Number.isNaN(dateValue)) {
      return getDateError(DateErrors.INVALID_WEEK_PICKER_FORMAT)
    }

    const { year } = dateNumbers
    const { week } = dateNumbers

    const nextYear = year + 1
    const startDate = parseDate(`${DAY_ONE_MONTH_ONE}${year}`, DateFormat.WithoutTime)
    const endDate = parseDate(`${DAY_ONE_MONTH_ONE}${nextYear}`, DateFormat.WithoutTime)

    const date = moment().year(year).day(localeConfiguration.firstDayOfWeek).isoWeek(week)
    const weeksCount = getWeeksCountBetween(startDate, endDate, Day.Monday)
    const thisWeek = isThisWeek(date.toDate())
    const chosenDateBeforeNow = date < NOW

    if (week > weeksCount) {
      return getDateError(DateErrors.WEEK_DOES_NOT_EXIST)
    }

    if (year > MAX_YEAR) {
      return getDateError(DateErrors.DATE_AFTER_MAXIMAL_DATE)
    }

    if ((year < NOW.year() || chosenDateBeforeNow) && !thisWeek) {
      return getDateError(DateErrors.DATE_BEFORE_MINIMAL_DATE)
    }

    if (thisWeek && NOW.day() >= Day.Friday) {
      return getDateError(DateErrors.NO_DELIVERIES_DURING_WEEKEND)
    }
  }
}

const getWeeksCountBetween = (startDate: Moment, endDate: Moment, isoWeekday: number): number => {
  const start = startDate.startOf(UnitOfTime.Day)
  const end = endDate.startOf(UnitOfTime.Day)
  let result = 0

  const weekdayToCheck: boolean[] = [false, false, false, false, false, false, false]
  weekdayToCheck[isoWeekday] = true

  weekdayToCheck.forEach((info: boolean, index: number) => {
    if (info) {
      let current = start.clone()

      if (current.isoWeekday() <= index) {
        current = current.isoWeekday(index)
      } else {
        current.add(1, UnitOfTime.Week).isoWeekday(index)
      }

      while (current.isSameOrBefore(end)) {
        current.day(7 + index)

        if (result < current.week()) {
          result = current.week()
        }
      }
    }
  })

  return result
}
