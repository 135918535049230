import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { MixinsOptions } from '@material-ui/core/styles/createMixins'
import palette from './palette'

declare module '@material-ui/core/styles/createMixins' {
  interface Mixins {
    centered: CSSProperties
    overlay: CSSProperties
  }

  interface MixinsOptions {
    centered?: CSSProperties
    overlay?: CSSProperties
  }
}

const mixins: MixinsOptions = {
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: palette.common.white,
    opacity: 0.4,
  },
}

export default mixins
