// eslint-disable-next-line import/prefer-default-export
import http from '@utils/http'
import { generateUrl } from '@utils/route'

const AUTHENTICATION_LOGIN_URL = '/api/login_check'
const AUTHENTICATION_CONFIRMATION_URL = '/user/confirm-token/:token(\\w+)'
const AUTHENTICATION_SETUP_PASSWORD_URL = '/user/password-setup/:token(\\w+)'
const AUTHENTICATION_FETCH_USER_URL = '/api/users/:id(\\d+)'

export const login = async ({ email, password }) => {
  return http.post(
    AUTHENTICATION_LOGIN_URL,
    {
      email,
      password,
    },
    { secure: false },
  )
}

export const checkConfirmation = async ({ token }) => {
  const url = generateUrl(AUTHENTICATION_CONFIRMATION_URL, { token })

  return http.get(url, {
    secure: false,
  })
}

export const passwordSetup = async ({ token, password, passwordConfirmation }) => {
  const url = generateUrl(AUTHENTICATION_SETUP_PASSWORD_URL, { token })

  return http.put(
    url,
    {
      password,
      passwordConfirmation,
    },
    {
      secure: false,
    },
  )
}

export const fetchProfile = async (userId) => {
  const user = await fetchUser(userId)

  if (typeof user.person === 'string') {
    user.person = await fetchPerson(user.person)
  }

  return user
}

const fetchUser = async (userId) => {
  const url = generateUrl(AUTHENTICATION_FETCH_USER_URL, { id: userId })

  return await http.get(url)
}

const fetchPerson = async (personIri) => {
  return await http.get(personIri, {
    params: {
      include: ['deliveryAddress'],
    },
  })
}
