import { getRowId } from './RowSelection'

const getRowClickHandler = (row, isEnabled, onRowClick) => {
  if (isEnabled) {
    const rowId = getRowId(row)

    return () => onRowClick(row, rowId)
  }

  return () => false
}

export default getRowClickHandler
