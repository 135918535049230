import { CommonNodeFieldModel } from '@components/Form/FormFields/model/TreeView'

interface TreeNode {
  node: CommonNodeFieldModel
  level: number
}

export const findNodeById = (nodeFields: CommonNodeFieldModel[], level: number, id: number): TreeNode | undefined => {
  if (nodeFields.length === 0) {
    return undefined
  }

  let result: TreeNode | undefined = undefined

  nodeFields.forEach((field) => {
    if (field.id === id) {
      result = {
        node: field,
        level: level,
      }
    }

    if (!!result || field.children.length === 0) {
      return
    }

    result = findNodeById(field.children, level + 1, id)
  })

  return result
}
