import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { useAppTitle } from '@hooks/app/useAppTitle'

interface Props {
  title: string
}

const Page: FC<Props> = ({ title, children }) => {
  const appTitle = useAppTitle(title)

  return (
    <>
      <Helmet>
        <title>{appTitle}</title>
      </Helmet>
      {children}
    </>
  )
}

export default Page
