import http from '@utils/http'
import { generateUrl } from '@utils/route'

const EMAIL_ADDRESS_TYPES_API_URL = '/api/contact_email_address_types'
const EMAIL_ADDRESS_TYPES_DETAILS_API_URL = '/api/contact_email_address_types/:id(\\d+)'

const fetchEmailTypes = async (parameters) => {
  return http.get(EMAIL_ADDRESS_TYPES_API_URL, {
    params: {
      ...parameters,
    },
  })
}

const readEmailType = async ({ id }) => {
  return http.get(generateUrl(EMAIL_ADDRESS_TYPES_DETAILS_API_URL, { id }))
}

const createEmailType = async (emailType = {}) => {
  return http.post(EMAIL_ADDRESS_TYPES_API_URL, {
    emailType,
  })
}

export { fetchEmailTypes, readEmailType, createEmailType }
