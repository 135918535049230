import { createSlice, PayloadAction, Update } from '@reduxjs/toolkit'
import { entityAdapter } from '../adapter'
import { ClientOrderItemModel } from '@models/entities/clientOrders'
import { deleteClientOrderItem, fetchClientOrderItems, updateClientOrderItem } from '../action'
import mainInitialState from '../initialState/mainState'
import { ResponseSuccessful } from '@models/api/response'
import MainSliceState from '../model/slice/MainSliceState'
import MainSliceReducer from '../model/slice/MainSliceReducer'
import { OneEntityMeta } from '@features/@common/model/redux/Action'

interface Response extends Omit<ResponseSuccessful, 'data'> {
  data: ClientOrderItemModel[]
}

const mainSlice = createSlice<MainSliceState, MainSliceReducer>({
  name: 'clientOrderItems',
  initialState: mainInitialState,
  reducers: {
    replaceEntity: (state: MainSliceState, action: PayloadAction<ClientOrderItemModel>) => {
      entityAdapter.upsertOne(state, action.payload)
    },
    removeEntity: (state: MainSliceState, action: PayloadAction<number>) => {
      entityAdapter.removeOne(state, action.payload)
    },
  },
  extraReducers: {
    [fetchClientOrderItems.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchClientOrderItems.fulfilled.type]: (state, action: PayloadAction<Response>) => {
      state.isLoading = false
      entityAdapter.setAll(state, action.payload.data)
    },
    [fetchClientOrderItems.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload.errors
    },
    [updateClientOrderItem.fulfilled.type]: (state, action: PayloadAction<ClientOrderItemModel>) => {
      const orderItem = action.payload

      const data: Update<ClientOrderItemModel> = {
        id: orderItem.id,
        changes: orderItem,
      }

      entityAdapter.updateOne(state, data)
    },
    [deleteClientOrderItem.fulfilled.type]: (state, action: PayloadAction<Response, string, OneEntityMeta>) => {
      entityAdapter.removeOne(state, action.meta.arg.id)
    },
  },
})

const mainActions = mainSlice.actions

export { mainSlice, mainActions }
