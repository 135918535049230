import createApiAction from '@features/@common/actions/api-actions'
import { parsePaginatedCollection } from '@utils/api/data'
import * as api from '../api'
import { FetchCategoriesParameters } from '../api'

const fetchCategories = createApiAction<any, FetchCategoriesParameters, any>(
  'categories.fetch',
  async (parameters: FetchCategoriesParameters) => {
    const response = await api.fetchCategories(parameters)

    return parsePaginatedCollection(response)
  },
)

export { fetchCategories }
