import { colors } from '../components/MaterialUI/colors'

const white = '#ffffff'
const nearlyWhite = '#f7f8f7'
const greyedOut = '#fafafa'
const black = '#4a4a4a'
const gray = '#878787'
const lightGray = '#d2d2d2'
const yellow = '#f0b847'
const green = '#50c386'
const blue = '#4a90e2'
const lightBlue = '#deecfb'
const red = '#d0011b'
const darkRed = '#8f0f08'
const highlight = '#e8d8c2'
const brown = '#8e6a4a'
const backgroundGray = '#ececec'
const darkerGray = '#e2e2e2'

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    royal: {
      gray: string
      nearlyWhite: string
      darkerGray: string
      lightBlue: string
      lightGray: string
      highlight: string
      paper: string
    }
    link: string
    icon: string
    section: {
      background: string
      border: string
      textAreaBackground: string
    }
    sidePanel: {
      background: string
    }
  }

  // allow configuration using `createPalette`
  interface PaletteOptions {
    royal?: {
      gray?: string
      nearlyWhite?: string
      darkerGray?: string
      lightBlue?: string
      lightGray?: string
      highlight?: string
    }
    link?: string
    icon?: string
    section?: {
      background?: string
      border?: string
      textAreaBackground?: string
    }
    sidePanel?: {
      background?: string
    }
  }
}

const palette = {
  //
  // Material UI
  //
  common: {
    black,
    white,
  },
  primary: {
    contrastText: white,
    dark: darkRed,
    main: red,
    light: red,
  },
  secondary: {
    contrastText: white,
    dark: darkRed,
    main: brown,
    light: brown,
  },
  error: {
    main: red,
  },
  warning: {
    main: yellow,
  },
  info: {
    main: blue,
  },
  success: {
    main: green,
  },
  text: {
    primary: black,
    secondary: gray,
    disabled: nearlyWhite,
    hint: gray,
  },
  divider: colors.grey[300],
  background: {
    default: nearlyWhite,
    paper: white,
    greyedOut: greyedOut,
  },
  //
  // Custom
  //
  royal: {
    gray,
    nearlyWhite,
    darkerGray,
    lightBlue,
    lightGray,
    highlight,
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  section: {
    background: nearlyWhite,
    border: lightGray,
    textAreaBackground: nearlyWhite,
  },
  sidePanel: {
    background: backgroundGray,
  },
}

export default palette
