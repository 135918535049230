import { ChangeEvent, useRef } from 'react'
import { debounce, DebouncedFunc } from 'lodash'

const DELAY = 500

const useDebouncedChangeHandler = (): DebouncedFunc<
  (
    event: ChangeEvent<HTMLInputElement>,
    onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void,
    forRegister?: string,
  ) => void
> => {
  return useRef(
    debounce(
      (event: ChangeEvent<HTMLInputElement>, onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void) =>
        onChangeHandler(event),
      DELAY,
    ),
  ).current
}

export default useDebouncedChangeHandler
