import React from 'react'
import PropTypes from 'prop-types'

const EmptyComponent = ({ title }) => {
  const styles = {
    backgroundColor: 'lightgoldenrodyellow',
    border: '1px dashed lightsteelblue',
    color: 'green',
    padding: '25px 50px',
    textAlign: 'center',
  }

  return <div style={styles}>{title}</div>
}

EmptyComponent.propTypes = {
  title: PropTypes.string.isRequired,
}

export default EmptyComponent
