import React, { ChangeEvent, FC, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Tab as MuiTab, Tabs as MuiTabs } from '../../MaterialUI/core'
import { makeStyles } from '../../MaterialUI/styles'
import a11yProps from '../Helper/a11yProps'
import Tab from '../Type/Tab'
import TabPanel from './TabPanel'
import { Theme } from '@components/MaterialUI/theme'
import { noHeight } from '@theme/styles/noHeight'
import { editIcon } from '@components/Tabs/styles/tabStyles'
import { VoidFunction } from '@models/function/VoidFunction'

const DEFAULT_TAB_SELECTED = 0

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  editIcon: {
    ...editIcon,
  },
}))

interface Props {
  idPrefix: string
  tabsData: Tab[]
  onTabChange?: (value: number) => void
  value?: number
  onEditIconClick?: VoidFunction
}

const Tabs: FC<Props> = ({ idPrefix, tabsData, onTabChange, value, onEditIconClick }) => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(value ?? DEFAULT_TAB_SELECTED)

  const handleChange = (event: ChangeEvent<Record<string, any>>, newValue: number): void => {
    setActiveTab(newValue)

    if (onTabChange) {
      onTabChange(newValue)
    }
  }

  return (
    <div className={classes.root}>
      <MuiTabs
        value={activeTab}
        onChange={handleChange}
        textColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        TabIndicatorProps={noHeight}
      >
        {tabsData &&
          tabsData.map((tab) => (
            <MuiTab tabIndex={0} key={uuidv4()} label={tab.name} {...a11yProps(idPrefix, tab.index)} />
          ))}
      </MuiTabs>

      {tabsData &&
        tabsData.map((tab) => (
          <TabPanel
            key={uuidv4()}
            idPrefix={idPrefix}
            index={tab.index}
            value={activeTab}
            withEditIcon={tab.editable}
            onEditIconClick={onEditIconClick}
          >
            {tab.content}
          </TabPanel>
        ))}
    </div>
  )
}

export default Tabs
