import React, { FC } from 'react'
import { FormFieldProps } from '@components/Form/FormFields/FormFieldProps'
import { Dropdown } from '@components/index'
import { DropdownOption } from '@models/form/dropdown/DropdownOption'
import { useController } from 'react-hook-form'
import { getInputName } from '@utils/form/fields/getInputName'
import { mergeRules } from '@utils/form/rules/mergeRules'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'
import { DropdownFieldModel } from '@models/form/fields/FieldModels'
import { createUnknownDropdownOption } from '@app/creator/form/model/dropdown/createUnknownDropdownOption'

interface Props extends Omit<FormFieldProps, 'formField'> {
  formField: DropdownFieldModel
}

export const FormDropdownField: FC<Props> = ({ formField, control, disabled, sectionLabel }) => {
  const name = getInputName(formField, sectionLabel)
  const rules = mergeRules(formField.rules) as RegisterOptions

  const { field } = useController({
    name,
    rules,
    control,
  })

  const createSelectedOption = (): DropdownOption => {
    if (!formField.value) {
      return createUnknownDropdownOption()
    }

    return formField.value
  }

  return (
    <Dropdown
      options={formField.options}
      optionsGroup=''
      selected={createSelectedOption()}
      label={formField.label}
      disabled={disabled}
      {...field}
    />
  )
}
