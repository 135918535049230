import palette from '@theme/palette'

const border = `2px solid ${palette.primary.main}`

export default {
  root: {
    backgroundColor: palette.background.greyedOut,
    '& button': {
      top: '2px',
      borderBottom: border,
      backgroundColor: palette.background.greyedOut,
    },
    '& .Mui-selected': {
      borderLeft: border,
      borderRight: border,
      borderTop: border,
      backgroundColor: palette.background.paper,
      borderBottom: `2px solid ${palette.common.white}`,
    },
  },
  flexContainer: {
    margin: '0 5px',
    borderBottom: border,
  },
}
