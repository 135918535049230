import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import moment from 'moment'
import { initReactI18next, Trans, useTranslation } from 'react-i18next'
import translations from '../translations'
import localeConfiguration from '../config/locale'

const detector = new LanguageDetector(null, {
  order: ['url-prefix', 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
})

detector.addDetector({
  name: 'url-prefix',
  lookup() {
    if (!window) {
      return
    }

    const lang = window.location.pathname.split('/')

    if (typeof lang[1] !== 'undefined') {
      const code = lang[1]
      if (localeConfiguration.locales.includes(code) && code) {
        return code
      }
    }
  },
})

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(detector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      resources: translations,
      defaultNS: 'global',
      fallbackNS: 'global',
      fallbackLng: localeConfiguration.fallbackLocale,
      debug: false,
    },
    () => {
      moment.locale(i18n.language)
    },
  )

export { useTranslation, Trans, i18n }
