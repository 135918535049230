import React, { FC } from 'react'
import { FormConfig } from '@config/form'
import { LabeledTextFieldProps } from '@components/Form/Labeled/LabeledTextField'
import { LabeledTextField } from '@components/Form/Labeled'

interface Props extends LabeledTextFieldProps {
  minRows?: number
}

export const LabeledTextArea: FC<Props> = ({ minRows = FormConfig.TextAreaMinRows, ...otherProps }) => {
  return <LabeledTextField minRows={minRows} {...otherProps} />
}
