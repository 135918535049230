import { createSlice } from '@reduxjs/toolkit'
import { createClientHasBrandCondition } from '../action'
import CreateSliceState from '../model/slice/CreateSliceState'
import createInitialState from '../initialState/createState'
import CreateSliceReducer from '../model/slice/CreateSliceReducer'
import { ClientHasBrandConditionModel } from '@models/entities/clientOrders'

const createClientHasBrandConditionSlice = createSlice<CreateSliceState, CreateSliceReducer>({
  name: 'clientHasBrandConditions.create',
  initialState: createInitialState,
  reducers: {},
  extraReducers: {
    [createClientHasBrandCondition.pending.type]: (state) => {
      state.isLoading = true
      state.success = createInitialState.success
      state.newId = createInitialState.newId
      state.error = createInitialState.error
    },
    [createClientHasBrandCondition.fulfilled.type]: (state, action) => {
      const clientOrderBrandCondition = action.payload as ClientHasBrandConditionModel
      state.isLoading = false
      state.success = clientOrderBrandCondition
      state.newId = clientOrderBrandCondition.id
      state.error = createInitialState.error
    },
    [createClientHasBrandCondition.rejected.type]: (state, action) => {
      state.isLoading = false
      state.success = createInitialState.success
      state.newId = createInitialState.newId
      state.error = action.payload.message
    },
  },
})

export default createClientHasBrandConditionSlice
