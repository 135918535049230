import { combineReducers } from 'redux'
import { mainSlice } from './slice/main'
import createBrandConditionSlice from '@features/brandConditions/redux/slice/create'

const reducer = combineReducers({
  main: mainSlice.reducer,
  create: createBrandConditionSlice.reducer,
})

export default reducer
