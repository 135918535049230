import React, { useState } from 'react'
import { useTranslation } from '@utils/translation'
import moment from 'moment'
import { Button, Menu, MenuItem } from '../MaterialUI/core'
import localeConfiguration from '../../config/locale'

const LocaleSwitcher = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { i18n } = useTranslation()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLocaleChange = async (locale) => {
    await moment.locale(locale)
    await i18n.changeLanguage(locale)
    handleClose()
  }

  return (
    <div>
      <Button
        disableRipple
        variant='text'
        color='inherit'
        aria-controls='app-locale'
        aria-haspopup='true'
        onClick={handleClick}
      >
        {i18n.language.split('-')[0]}
      </Button>
      <Menu id='app-locale' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {localeConfiguration.locales.map((locale) => (
          <MenuItem onClick={() => handleLocaleChange(locale)} key={locale}>
            {locale.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default LocaleSwitcher
