import { combineReducers } from 'redux'
import { mainSlice } from './slice/main'
import { mainForClientSlice } from '@features/clientHasBrandConditions/redux/slice/mainForClient'
import createClientHasBrandConditionSlice from '@features/clientHasBrandConditions/redux/slice/create'
import deleteClientHasBrandConditionSlice from '@features/clientHasBrandConditions/redux/slice/delete'

const reducer = combineReducers({
  main: mainSlice.reducer,
  mainForClient: mainForClientSlice.reducer,
  create: createClientHasBrandConditionSlice.reducer,
  delete: deleteClientHasBrandConditionSlice.reducer,
})

export default reducer
