import { CSSProperties } from '@material-ui/core/styles/withStyles'

const editIcon: CSSProperties = {
  marginLeft: 'calc(100% - 50px)',
}

const tabPanelStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 50%',
  maxWidth: '700px',
  '&:focus': {
    outline: 'none',
  },
}

export { editIcon, tabPanelStyle }
