import Alignment from '@models/Alignment'
import { makeStyles } from '@components/MaterialUI/styles'

type AlignmentClasses = {
  [key: string]: boolean
}

const createAlignmentStyles = makeStyles(() => ({
  alignLeft: {
    justifyContent: 'flex-start',
  },
  alignCenter: {
    justifyContent: 'center',
  },
  alignRight: {
    justifyContent: 'flex-end',
  },
}))

const useAlignmentClasses = (alignment?: Alignment): AlignmentClasses => {
  const alignmentStyles = createAlignmentStyles()

  return {
    [alignmentStyles.alignLeft]: alignment === Alignment.Left,
    [alignmentStyles.alignCenter]: alignment === Alignment.Center,
    [alignmentStyles.alignRight]: alignment === Alignment.Right,
  }
}

export default useAlignmentClasses
