import { entityAdapter } from '../adapter'
import DownSellerSliceState from '../model/slice/DownSellerSliceState'

const downSellersInitialState: DownSellerSliceState = {
  ...entityAdapter.getInitialState(),
  isLoading: true,
  error: undefined,
}

export default downSellersInitialState
