import { lazy } from 'react'
import CellComponentDefinition from '../Model/Cell/CellComponentDefinition'
import CellType from '../Model/Cell/CellType'

const components: CellComponentDefinition[] = [
  {
    type: CellType.DisplayText,
    component: lazy(() => import('../Cell/Value/Display/TextValue')),
  },
  {
    type: CellType.DisplayBoolean,
    component: lazy(() => import('../Cell/Value/Display/BooleanValue')),
  },
  {
    type: CellType.DisplayStatus,
    component: lazy(() => import('../Cell/Value/Display/StatusValue')),
  },
  {
    type: CellType.DisplayIsClosed,
    component: lazy(() => import('../Cell/Value/Display/IsClosedValue')),
  },
  {
    type: CellType.DisplayDate,
    component: lazy(() => import('../Cell/Value/Display/DateValue')),
  },
  {
    type: CellType.DisplayEmail,
    component: lazy(() => import('../Cell/Value/Display/EmailValue')),
  },
  {
    type: CellType.DisplayPhone,
    component: lazy(() => import('../Cell/Value/Display/PhoneValue')),
  },
  {
    type: CellType.DisplayNumber,
    component: lazy(() => import('../Cell/Value/Display/NumberValue')),
  },
  {
    type: CellType.DisplayIcon,
    component: lazy(() => import('../Cell/Value/Display/IconValue')),
  },
  {
    type: CellType.EditCheckbox,
    component: lazy(() => import('../Cell/Value/Edit/CheckboxValue')),
  },
  {
    type: CellType.EditText,
    component: lazy(() => import('../Cell/Value/Edit/TextValue')),
  },
  {
    type: CellType.Actions,
    component: lazy(() => import('../Action')),
  },
  {
    type: CellType.EditNumber,
    component: lazy(() => import('../Cell/Value/Edit/NumberValue')),
  },
  {
    type: CellType.EditSelect,
    component: lazy(() => import('../Cell/Value/Edit/DropdownValue')),
  },
]

export default components
