import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { Moment } from 'moment'
import InputMask from 'react-input-mask'
import { makeStyles } from '../MaterialUI/styles'
import { TextField } from '../MaterialUI/core'
import { noArrowsInputStyle } from '@theme/custom'
import { getWeekFromDate } from './functions'
import { SMALL_FONT_SIZE } from '@theme/constants'

interface StylesProps {
  noArrowsInputStyle: CSSProperties
}

const useStyles = (settings: StylesProps) =>
  makeStyles(() => ({
    root: {
      display: 'flex',
    },
    numberField: {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      fontSize: SMALL_FONT_SIZE,
      '& input': {
        textAlign: 'left',
        ...settings.noArrowsInputStyle,
      },
    },
  }))

interface Props {
  date: null | Moment
  warningText: ReactNode | null
  onValueChange: (args: string) => any
  disabled: boolean
}

const WeekPicker: FC<Props> = ({ date, onValueChange, warningText, disabled = false }) => {
  const classes = useStyles({ noArrowsInputStyle })()
  const [selectedDate, setSelectedDate] = useState<string>('')

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newValue = event.target.value

    setSelectedDate(newValue)
    onValueChange(newValue)
  }

  useEffect(() => {
    if (date) {
      setSelectedDate(getWeekFromDate(date))
    }
  }, [date])

  return (
    <div className={classes.root}>
      <div className={classes.numberField}>
        <InputMask
          mask='9999-99'
          value={selectedDate}
          disabled={disabled}
          alwaysShowMask
          maskPlaceholder='YYYY-WW'
          onChange={handleChange}
        >
          {() => <TextField error={warningText !== null} helperText={warningText} disabled={disabled} />}
        </InputMask>
      </div>
    </div>
  )
}

export default WeekPicker
