import { ObjectFieldModel } from '@models/form/fields/FieldModels'
import React, { FC } from 'react'
import { LabelWithoutValue } from '@components/Form/Labeled'
import { Control } from 'react-hook-form'
import { createFormFields } from '@utils/form/fields/createFormFields'
import { capitalize } from '@components/MaterialUI/functions'

interface Props {
  section: ObjectFieldModel<Record<string, any>>
  control: Control<any>
  disabled: boolean
}

const FormSection: FC<Props> = ({ section, control, disabled }) => {
  const sections = createFormFields(section.value, control, disabled, section.fieldName)

  if (!section.value || !sections.length) {
    return <LabelWithoutValue labelText={capitalize(section.label)} />
  }

  return (
    <section key={section.label}>
      {section.label && <LabelWithoutValue labelText={capitalize(section.label)} />}
      {sections.map((section) => section)}
    </section>
  )
}

export default FormSection
