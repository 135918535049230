import { combineReducers } from 'redux'
import { createOrderSlice, mainSlice, paginationSlice, readSlice, updateAddress, updateOptions } from './slice'

const reducer = combineReducers({
  main: mainSlice.reducer,
  pagination: paginationSlice.reducer,
  read: readSlice.reducer,
  create: createOrderSlice.reducer,
  updateAddress: updateAddress.reducer,
  updateOptions: updateOptions.reducer,
})

export default reducer
