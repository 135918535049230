import moment from 'moment'

const NOW = moment()
const DAY_ONE_MONTH_ONE = '01-01-'
const WEEK_ONE = '01'
const STANDARD_WEEK_AMOUNT = 52

const MIN_WEEK = NOW.isoWeek()
const MIN_YEAR = NOW.year()
const MAX_YEAR = MIN_YEAR + 80

const MAX_WEEK_PICKER_ENTRY = Number(`${MAX_YEAR}${WEEK_ONE}`)
const MIN_WEEK_PICKER_ENTRY = Number(`${MIN_YEAR}${MIN_WEEK}`)

export {
  DAY_ONE_MONTH_ONE,
  MAX_WEEK_PICKER_ENTRY,
  MAX_YEAR,
  MIN_WEEK_PICKER_ENTRY,
  MIN_WEEK,
  MIN_YEAR,
  NOW,
  STANDARD_WEEK_AMOUNT,
  WEEK_ONE,
}
