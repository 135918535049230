import http from '@utils/http'
import { generateUrl } from '@utils/route'
import { SupplierModel } from '@models/entities/suppliers/SupplierModel'
import { GetItemsPaginatedParameters } from '@models/api/request'

const SUPPLIERS_API_URL = '/api/suppliers'
const SUPPLIER_DETAILS_API_URL = '/api/suppliers/:id(\\d+)'
const SUPPLIER_UPDATE_API_URL = '/api/suppliers/:id(\\d+)'

const fetchAlso = ['address', 'phone', 'emails']

interface FetchParametersWithFilter extends Omit<GetItemsPaginatedParameters, 'extra'> {
  extra: {
    name: string
    royalCrownCustomerNumber: string
  }
}

interface UpdateParameters {
  id: number
  supplier: SupplierModel
}

const fetchSuppliers = async (parameters: FetchParametersWithFilter) => {
  return http.get(SUPPLIERS_API_URL, {
    params: {
      ...parameters.pagination,
      ...parameters.sorting,
      ...parameters.extra,
      include: fetchAlso,
    },
  })
}

const readSupplier = async (id: number) => {
  const url = generateUrl(SUPPLIER_DETAILS_API_URL, { id })

  return http.get(url, {
    params: {
      include: fetchAlso,
    },
  })
}

const createSupplier = async (supplier = {}) => {
  return http.post(SUPPLIERS_API_URL, supplier)
}

const updateSupplier = async (parameters: UpdateParameters) => {
  const url = generateUrl(SUPPLIER_UPDATE_API_URL, { id: parameters.id })

  return http.put(url, parameters.supplier)
}

export type { UpdateParameters, FetchParametersWithFilter }
export { fetchSuppliers, readSupplier, createSupplier, updateSupplier }
