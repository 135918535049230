import { Parent } from './Parent'

export class NodeFieldModel<T> {
  private _iriId: string
  private _name: string
  private _id: number
  private _parent?: Parent<T>
  private _children: NodeFieldModel<T>[] = []

  constructor(name: string, id: number, iriId: string, parent?: Parent<T>, children?: NodeFieldModel<T>[]) {
    this._children = children ?? []
    this._parent = parent
    this._iriId = iriId
    this._id = id
    this._name = name
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get id(): number {
    return this._id
  }

  set id(value: number) {
    this._id = value
  }

  get parent(): Parent<T> | undefined {
    return this._parent
  }

  set parent(value: Parent<T> | undefined) {
    this._parent = value
  }

  get children(): NodeFieldModel<T>[] {
    return this._children
  }

  set children(value: NodeFieldModel<T>[]) {
    this._children = value
  }

  get iriId(): string {
    return this._iriId
  }

  set iriId(value: string) {
    this._iriId = value
  }
}
