import React, { ChangeEvent, FC, Ref } from 'react'
import clsx from 'clsx'
import { v4 } from 'uuid'
import { makeStyles } from '@components/MaterialUI/styles'
import { Theme } from '@components/MaterialUI/theme'
import LabeledStyles from './LabeledStyles'
import { FONT_WEIGHT_BOLD } from '@theme/constants'
import { useTranslation } from '@utils/translation'
import { InputLabel } from '@components/MaterialUI/core'
import { useFormDisplayNumberStyles, useFormEditNumberStyles } from '../../RoyalList/Styles/use-number-styles'
import { PrefixSign } from '@config/number'
import NumberInput from '../NumberInput'
import { useInputChangeHandler } from '@utils/form/events/useInputChangeHandler'
import { FieldError } from 'react-hook-form'

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    ...LabeledStyles.row,
  },
  line: {
    ...LabeledStyles.line,

    '& input': {
      fontWeight: FONT_WEIGHT_BOLD,
    },
  },
  indent: {
    ...LabeledStyles.indent,
  },
  bottomSpace: {
    ...LabeledStyles.bottomMargin,
  },
  number: {
    '& input': {
      textAlign: 'left',
    },
    '& .MuiInputBase-root': {
      margin: 0,
    },
    '&:focus-within': {
      backgroundColor: `${theme.palette.royal.highlight}`,
    },

    borderBottom: `1px dotted ${theme.palette.royal.gray}`,
  },
}))

interface Props {
  value?: string | number
  labelText?: string
  indent?: boolean
  className?: string
  showIfEmptyValue?: boolean
  withBottomMargin?: boolean
  disabled?: boolean
  ref?: Ref<HTMLInputElement>
  prefix?: PrefixSign
  id: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  error?: FieldError
}

const LabeledNumberField: FC<Props> = ({
  value,
  labelText = '',
  showIfEmptyValue = false,
  withBottomMargin = false,
  indent = false,
  className,
  disabled = true,
  prefix = PrefixSign.NONE,
  onChange,
  id,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const editNumberClasses = useFormEditNumberStyles()
  const displayNumberClasses = useFormDisplayNumberStyles()
  const numberValue = Number(value)
  const changeHandler = useInputChangeHandler()

  const disabledClasses = disabled ? displayNumberClasses.numberField : editNumberClasses.numberField
  const numberClasses = clsx(classes.line, disabledClasses, classes.number)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    value = event.target.value
    changeHandler(event, onChange)
  }

  if ((!numberValue || isNaN(numberValue)) && !showIfEmptyValue) {
    return <></>
  }

  return (
    <div className={clsx(classes.row, className, { [classes.bottomSpace]: withBottomMargin })} key={v4()}>
      <InputLabel className={clsx(classes.line, { [classes.indent]: indent })}>{labelText && t(labelText)}</InputLabel>
      <NumberInput
        className={numberClasses}
        value={numberValue}
        onBlur={handleChange}
        prefix={prefix}
        disabled={disabled}
        id={id}
      />
    </div>
  )
}

export default LabeledNumberField
