import { CSSProperties } from '@material-ui/core/styles/withStyles'
import palette from '../../../../../theme/palette'
import shape from '../../../../../theme/shape'
import { Padding } from '../../../../../theme/styles/padding'

const container: CSSProperties = {
  width: '100%',
}

const section: CSSProperties = {
  display: 'block',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  maxHeight: '445px',
  overflow: 'hidden',
}

const row: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  height: '30px',
}

const root: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: `${palette.royal.highlight}15`,
}

const header: CSSProperties = {
  fontWeight: 'bold',
  height: '45px',
  display: 'flex',
  lineHeight: '45px',
  backgroundColor: palette.royal.lightGray,
  color: palette.primary.main,
  padding: `0 ${Padding.Medium}`,
  width: '100%',
  borderBottom: `1px solid ${palette.primary.main}`,
  borderRadius: `${shape.borderRadius} ${shape.borderRadius} 0 0`,
}

const contentContainer: CSSProperties = {
  padding: `${Padding.Small} ${Padding.Small}`,
  maxWidth: '100%',
  maxHeight: '400px',
  borderRadius: `0 0 ${shape.borderRadius} ${shape.borderRadius}`,
  overflow: 'hidden',
  height: '100%',
}

const content: CSSProperties = {
  fontWeight: 'normal',
  overflow: 'auto',
  overflowX: 'hidden',
  maxHeight: '390px',
  height: '100%',
}

const line: CSSProperties = {
  height: '30px',
  lineHeight: '30px',
  marginRight: '3px',
  width: 'calc(50% - 15px)',
}

const indent: CSSProperties = {
  marginLeft: '30px',
}

const inVisible: CSSProperties = {
  display: 'none',
}

const visible: CSSProperties = {
  display: 'block',
}

const listItem: CSSProperties = {
  ...visible,
  border: `1px solid ${palette.primary.main}`,
  borderRadius: shape.borderRadius,
  display: 'flex',
  flexGrow: 1,
  flexShrink: 1,
  minWidth: '400px',
  margin: '5px',
  maxWidth: 'calc(50% - 15px)',
}

const button: CSSProperties = {
  ...visible,
  border: `1px solid ${palette.primary.main}`,
  borderRadius: shape.borderRadius,
}

type GridStylesType = {
  container: CSSProperties
  section: CSSProperties
  inVisible: CSSProperties
  visible: CSSProperties
  button: CSSProperties
  listItem: CSSProperties
  row: CSSProperties
  root: CSSProperties
  header: CSSProperties
  content: CSSProperties
  contentContainer: CSSProperties
  line: CSSProperties
  indent: CSSProperties
}

const GridStyles: GridStylesType = {
  container,
  section,
  inVisible,
  visible,
  button,
  listItem,
  row,
  root,
  header,
  content,
  contentContainer,
  line,
  indent,
}

export default GridStyles
