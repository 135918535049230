import { createEntityAdapter } from '@reduxjs/toolkit'
import { ArticlePriceModel } from '../../../app/models/entities/articlePrice/ArticlePriceModel'

const selectId = (entity: ArticlePriceModel) => entity.id

const entityAdapter = createEntityAdapter<ArticlePriceModel>({
  selectId,
})

export { selectId, entityAdapter }
