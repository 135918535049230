import createApiAction from '@features/@common/actions/api-actions'
import * as api from '../api'
import { FetchParameters } from '../api'
import { parsePaginatedCollection } from '@utils/api/data'

const fetchClients = createApiAction('clients.fetch', async (parameters: FetchParameters) => {
  const response = await api.fetchClients(parameters)

  return parsePaginatedCollection(response)
})

const readClient = createApiAction('clients.read', api.readClient)

const createClient = createApiAction('clients.create', api.createClient)

const updateClient = createApiAction('clients.update', api.updateClient)

export { fetchClients, readClient, updateClient, createClient }
