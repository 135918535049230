import { createEntityAdapter } from '@reduxjs/toolkit'
import { GroupOrderModel } from '../../../app/models/entities/groupOrder/GroupOrderModel'

const selectId = (entity: GroupOrderModel) => entity.id

const entityAdapter = createEntityAdapter({
  selectId,
})

export { selectId, entityAdapter }
