import React, { ChangeEvent, FC } from 'react'
import { FormControl, FormGroup, TextField } from '../MaterialUI/core'
import { SearchIcon } from '../MaterialUI/icons'
import { makeStyles } from '../MaterialUI/styles'
import { useTranslation } from '@utils/translation'
import { MARGIN } from '@theme/constants'
import clsx from 'clsx'
import { Theme } from '@components/MaterialUI/theme'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: MARGIN,
    position: 'relative',
  },
  icon: {
    color: theme.palette.primary.main,
    width: '1.1em',
    height: '1.1em',
    position: 'absolute',
    top: '50%',
    right: theme.spacing(1),
    transform: 'translateY(-50%)',
  },
}))

type Props = {
  onChange?: (value: string) => void
  className?: string
  defaultValue?: string
}

const SearchForm: FC<Props> = ({ className, onChange, defaultValue }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.currentTarget.value)
    }
  }

  return (
    <FormGroup className={clsx(classes.root, className)}>
      <FormControl>
        <TextField
          variant='outlined'
          placeholder={t('actions.search')}
          aria-label={t('actions.search')}
          onChange={handleChange}
          defaultValue={defaultValue}
          type='text'
        />
        <SearchIcon className={classes.icon} />
      </FormControl>
    </FormGroup>
  )
}

export default SearchForm
