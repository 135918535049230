import React from 'react'
import defaultPropTypes from '../common/propTypes'
import { TextField } from '../../../MaterialUI/core'

const Input = ({ type, ...rest }) => {
  return <TextField {...rest} type={type} />
}

Input.propTypes = defaultPropTypes

export default Input
