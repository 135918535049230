import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react'
import { v4 } from 'uuid'
import { Theme } from '@components/MaterialUI/theme'
import { useTranslation } from '@utils/translation'
import { FormControl, InputLabel, ListSubheader, MenuItem, Select } from '@components/MaterialUI/core'
import { makeStyles } from '@components/MaterialUI/styles'
import { DropdownOption } from '@models/form/dropdown/DropdownOption'
import clsx from 'clsx'
import { LabeledTextField } from '@components/Form/Labeled'
import { handleDropdownSelectionChange } from '@utils/dropdown'
import { ChangeDropdownType } from '@config/dropdown'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    margin: '2px 0',
  },
  group: {
    color: theme.palette.primary.main,
  },
}))

interface CssClasses {
  wrapper?: string
  dropdown?: string
}

interface Props {
  options: DropdownOption[]
  optionsGroup: string
  selected?: DropdownOption
  additionalOptions?: DropdownOption[]
  additionalOptionsGroup?: string
  onChange?: ChangeDropdownType
  label?: string
  cssClasses?: CssClasses
  disabled?: boolean
  includeEmptyValue?: boolean
}

const Dropdown: FC<Props> = ({
  options,
  optionsGroup = '',
  selected,
  additionalOptions = [],
  additionalOptionsGroup = '',
  onChange,
  label = '',
  cssClasses,
  disabled = false,
  includeEmptyValue = false,
}) => {
  const { t } = useTranslation()
  const [selectedItem, setSelectedItem] = useState<undefined | DropdownOption>(selected)
  const [currentOptions, setCurrentOptions] = useState<DropdownOption[]>(options)
  const elementId = v4()
  const classes = useStyles()

  const getAllOptions = (): DropdownOption[] => {
    if (additionalOptions) {
      return options.concat(additionalOptions)
    }

    return options
  }

  const handleChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const selectValue = Number(event.target.value)
    const allOptions = getAllOptions()

    handleDropdownSelectionChange(selectValue, allOptions, selected, onChange)
  }

  useEffect(() => {
    setCurrentOptions(options)
  }, [options])

  useEffect(() => {
    setSelectedItem(selected)
  }, [selected])

  const createEmptyValue = (): ReactNode => {
    return (
      <MenuItem key={v4()} value={0}>
        <em>{t('global:information.no.selection')}</em>
      </MenuItem>
    )
  }

  const createOption = (option: DropdownOption): ReactNode => {
    return (
      <MenuItem value={option.id} key={v4()}>
        {t(option.name)}
      </MenuItem>
    )
  }

  const createSubheader = (title?: string): ReactNode | undefined => {
    if (!title) {
      return
    }

    return (
      <ListSubheader className={classes.group} key={v4()}>
        {title}
      </ListSubheader>
    )
  }

  const createOptions = (options?: DropdownOption[], title?: string, includeEmptyOption?: boolean): ReactNode[] => {
    if (!options) {
      return []
    }

    const result: ReactNode[] = []
    const header = createSubheader(title)
    const additionalOptions = options?.map((option) => createOption(option))

    if (header) {
      result.push(header)
    }

    if (includeEmptyOption) {
      result.push(createEmptyValue())
    }

    if (additionalOptions) {
      return result.concat(additionalOptions)
    }

    return result
  }

  const optionsToRender = createOptions(currentOptions, optionsGroup, includeEmptyValue)
  const additionalOptionsToRender = createOptions(additionalOptions, additionalOptionsGroup)
  const wrapperClasses = clsx(classes.wrapper, cssClasses?.wrapper)

  if (disabled) {
    return <>{label && <LabeledTextField disabled labelText={label} value={selectedItem?.name} />}</>
  }

  return (
    <FormControl variant='outlined' className={wrapperClasses} disabled={disabled}>
      {label && <InputLabel htmlFor={elementId}>{label}</InputLabel>}
      <Select
        id={elementId}
        variant='outlined'
        value={selectedItem?.id}
        defaultValue={''}
        onChange={handleChange}
        className={cssClasses?.dropdown}
      >
        {optionsToRender}
        {additionalOptionsToRender}
      </Select>
    </FormControl>
  )
}

export default Dropdown
