import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { makeStyles } from '../MaterialUI/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > a ': {
      color: theme.palette.primary.main,
    },
  },
}))

const Email = ({ email }) => {
  const classes = useStyles()

  if (isEmpty(email)) {
    return null
  }

  return (
    <span className={classes.root}>
      <a href={`mailto:${email}`}>{email}</a>
    </span>
  )
}

Email.propTypes = {
  email: PropTypes.string,
}

export default Email
