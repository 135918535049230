import { isEmpty } from 'lodash'
import { PhoneNumberModel } from '@models/entities/addresses/PhoneNumberModel'

class PhoneService {
  static format(phoneNumber: PhoneNumberModel | string): string | null {
    if (isEmpty(phoneNumber)) {
      return null
    }

    if (typeof phoneNumber === 'string') {
      return phoneNumber
    }

    return `${phoneNumber.countryCode}${phoneNumber.number}`
  }
}

export default PhoneService
