import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { CircularProgress } from '../MaterialUI/core'
import { makeStyles } from '../MaterialUI/styles'

const useStyles = (settings) =>
  makeStyles((theme) => ({
    root: {},
    block: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      margin: theme.spacing(2),
    },
    inline: {
      display: 'flex',
    },
    circle: {
      color: settings.color ? settings.color : theme.palette.primary,
    },
  }))

const Loader = ({ variant, color }) => {
  const classes = useStyles({ color })()
  const size = variant === 'inline' ? 20 : 40

  return (
    <div className={clsx(classes.root, classes[variant])}>
      <CircularProgress size={size} className={classes.circle} />
    </div>
  )
}

Loader.propTypes = {
  variant: PropTypes.oneOf(['block', 'inline']),
  color: PropTypes.string,
}

Loader.defaultProps = {
  variant: 'block',
}

export default Loader
