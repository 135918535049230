import { Dictionary } from '@reduxjs/toolkit'
import { HasIdEntity } from '@models/entities/HasIdEntity'
import { PaginationState } from '@features/@common'

interface PaginationSelectors<State, Entity extends HasIdEntity> {
  isLoading: (state: State) => boolean
  getEntities: (state: State) => Dictionary<Entity>
  getPage: (state: State) => number
  getPerPage: (state: State) => number
  getTotal: (state: State) => number
  getPages: (state: State) => number
}

type SelectPaginationState<State, Entity extends HasIdEntity> = (state: State) => PaginationState<Entity>

export const getPaginationSelectors = <State, Entity extends HasIdEntity>(
  selectState: SelectPaginationState<State, Entity>,
): PaginationSelectors<State, Entity> => {
  return {
    isLoading: (state: State): boolean => {
      const paginationState = selectState(state)

      return paginationState.isLoading
    },
    getEntities: (state: State): Dictionary<Entity> => {
      const paginationState = selectState(state)

      return paginationState.entities
    },
    getPage: (state: State): number => {
      const paginationState = selectState(state)

      return paginationState.page
    },
    getPerPage: (state: State): number => {
      const paginationState = selectState(state)

      return paginationState.perPage
    },
    getTotal: (state: State): number => {
      const paginationState = selectState(state)

      return paginationState.total
    },
    getPages: (state: State): number => {
      const paginationState = selectState(state)

      return paginationState.pages
    },
  }
}
