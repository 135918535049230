import { ArticleInclude } from '@features/articles/api/model/ArticleInclude'

export default [
  ArticleInclude.AttributeValues,
  ArticleInclude.Brand,
  ArticleInclude.Category,
  ArticleInclude.ClientChannels,
  ArticleInclude.Color,
  ArticleInclude.Dimensions,
  ArticleInclude.Images,
  ArticleInclude.MetaData,
  ArticleInclude.Predecessor,
  ArticleInclude.Prices,
  ArticleInclude.Stock,
  ArticleInclude.Successor,
  ArticleInclude.Supplier,
  ArticleInclude.Taxes,
  ArticleInclude.Translations,
  ArticleInclude.UniqueSellingPoint,
]
