import { get, isEmpty } from 'lodash'

export const skipElementsExistingIn2ndArray = <T, U>(
  arrayA: T[],
  propertyA: string,
  arrayB: U[],
  propertyB: string,
): T[] => {
  if (isEmpty(arrayA) || isEmpty(propertyA)) {
    return []
  }

  if (isEmpty(arrayB) || isEmpty(propertyB)) {
    return arrayA
  }

  const result: T[] = []

  arrayA.forEach((elementA: T, index: number) => {
    const foundIndex = arrayB.some((elementB: U) => {
      const valueA = get(elementA, propertyA)
      const valueB = get(elementB, propertyB)

      return valueA === valueB
    })

    if (foundIndex) {
      return
    }

    const object = arrayA[index]
    result.push(object)
  })

  return result
}

export const refuseElementsByCount = (source: any[], maxElements: number): any[] => {
  if (maxElements === 0) {
    return []
  }

  if (maxElements >= source.length || maxElements < 0) {
    return source
  }

  return source.filter((item, itemIndex) => itemIndex < maxElements)
}

export const refuseFalsyItems = (source: any[]) => source.filter((item) => !!item)

export const areIdentical = (arrayOne: any[] | undefined, arrayTwo: any[] | undefined): boolean => {
  if (!!arrayOne && arrayOne.length && !!arrayTwo && arrayTwo.length) {
    return arrayOne.length === arrayTwo.length && arrayOne.every((value, index) => value === arrayTwo[index])
  }

  return false
}
