import { entityAdapter } from '../adapter'
import CreateSliceState from '../model/slice/CreateSliceState'

const createInitialState: CreateSliceState = {
  ...entityAdapter.getInitialState(),
  isLoading: false,
  success: undefined,
  error: undefined,
}

export default createInitialState
