import auth from './auth.json'
import dataTable from './dataTable.json'
import errors from './errors.json'
import form from './form.json'
import global from './global.json'
import messages from './messages.json'
import supplier from './supplier.json'
import validation from './validation.json'
import sidebarView from './sidebarView.json'

export default {
  auth,
  dataTable,
  errors,
  form,
  global,
  messages,
  supplier,
  validation,
  sidebarView,
}
