import http from '@utils/http'
import { generateUrl } from '@utils/route'
import { GetItemsPaginatedParameters } from '@models/api/request'

export const BRAND_CONDITIONS_API_URL = '/api/brand_conditions/:id(\\d+)'
const BRAND_CONDITIONS_FROM_BRAND_API_URL = '/api/brands/:id(\\d+)/brand_conditions'
const UPDATE_BRAND_CONDITION_API_URL = '/api/brand_conditions/:id(\\d+)'
const UPDATE_BRAND_CONDITION_FOR_CLIENT = 'api/client_has_brand_conditions/:id(\\d+)'
const CREATE_BRAND_CONDITION = 'api/brand_conditions'

interface FetchParameters extends Omit<GetItemsPaginatedParameters, 'id'> {
  id: number
}

interface UpdateParameters {
  id: number
  changed: {
    isActive?: boolean
    brand?: string
    items?: string[]
  }
}

interface UpdateClientConditionParameters {
  id: number
  changed: {
    hasBuilderPrice?: boolean
    client?: string
    brandCondition?: string
  }
}

interface CreateBrandConditionParameters {
  isActive: boolean
  brand: string
  items: string[]
}

const fetchBrandConditionsForBrand = async (parameters: FetchParameters) => {
  const url = generateUrl(BRAND_CONDITIONS_FROM_BRAND_API_URL, { id: parameters.id })

  return http.get(url, {
    params: {
      ...parameters.pagination,
      ...parameters.sorting,
      include: ['clientHasBrandConditions', 'client', 'brand', 'items'],
    },
  })
}

const updateBrandCondition = async (parameters: UpdateParameters) => {
  const url = generateUrl(UPDATE_BRAND_CONDITION_API_URL, { id: parameters.id })

  return http.patch(url, parameters.changed)
}

const updateBrandConditionForClient = async (parameters: UpdateClientConditionParameters) => {
  const url = generateUrl(UPDATE_BRAND_CONDITION_FOR_CLIENT, { id: parameters.id })

  return http.patch(url, parameters.changed)
}

const createBrandCondition = async (parameters: CreateBrandConditionParameters) => {
  return http.post(CREATE_BRAND_CONDITION, parameters)
}

export type { FetchParameters, UpdateParameters, UpdateClientConditionParameters, CreateBrandConditionParameters }
export { createBrandCondition, fetchBrandConditionsForBrand, updateBrandCondition, updateBrandConditionForClient }
