import { RuleTypeMessageModel } from '@models/form/rules/RuleTypeMessageModel'

export const mergeRules = (rules?: RuleTypeMessageModel[]): RuleTypeMessageModel => {
  if (!rules || rules.length === 0) {
    return {}
  }

  let result: RuleTypeMessageModel = {}

  rules.forEach((rule) => {
    result = { ...result, ...rule }
  })

  return result
}
