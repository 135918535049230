import React from 'react'
import PropTypes from 'prop-types'
import defaultPropTypes from '../common/propTypes'
import { MenuItem, Select as BaseSelect } from '../../../MaterialUI/core'

const Select = ({ options, ...rest }) => {
  return (
    <BaseSelect {...rest}>
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </BaseSelect>
  )
}

Select.propTypes = {
  ...defaultPropTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
      label: PropTypes.any.isRequired,
    }),
  ).isRequired,
}

export default Select
