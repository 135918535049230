import React, { FC } from 'react'
import { isEmpty } from 'lodash'
import { Breadcrumbs as MuiBreadcrumbs } from '../MaterialUI/core'
import Breadcrumb from './Breadcrumb'
import Item from '@features/breadcrumbs/model/Item'

interface Props {
  items: Item[]
}

const Breadcrumbs: FC<Props> = ({ items = [] }) => {
  if (isEmpty(items)) {
    return null
  }

  return (
    <MuiBreadcrumbs separator='›'>
      {items.map((item, index) => (
        <Breadcrumb key={index} item={item} />
      ))}
    </MuiBreadcrumbs>
  )
}

export default Breadcrumbs
