import { entityAdapter } from '../adapter'
import ReadSliceState from '../model/slice/ReadSliceState'

const readInitialState: ReadSliceState = {
  ...entityAdapter.getInitialState(),
  isLoading: true,
  loadingItems: [],
  error: undefined,
  success: undefined,
}

export default readInitialState
