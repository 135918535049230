import MainSliceState from '../model/slice/MainSliceState'
import { entityAdapter } from '../adapter'

const mainInitialState: MainSliceState = {
  ...entityAdapter.getInitialState(),
  isLoading: true,
  error: undefined,
}

export default mainInitialState
