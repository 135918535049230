import { RouteConfig } from 'react-router-config'
import authRoutes from './routes/auth'
import supplierRoutes from './routes/supplier'
import clientRoutes from './routes/client'
import EmployeeType from '../features/auth/model/EmployeeType'

const getRoutes = (userType: EmployeeType | undefined): RouteConfig[] => {
  if (!!userType && userType.toString() === EmployeeType.SupplierEmployee.toString()) {
    return [authRoutes, supplierRoutes]
  }

  return [authRoutes, clientRoutes]
}

export default getRoutes
