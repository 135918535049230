import invariant from 'invariant'
import { get } from 'lodash'

interface ApiConfig {
  url: string
  mercureUrl: string
}

const config = Object.freeze<ApiConfig>({
  url: get(process.env, 'REACT_APP_API_URL', ''),
  mercureUrl: get(process.env, 'REACT_APP_MERCURE_URL', ''),
})

invariant(config.url, 'API url is not defined. Did you forget to setup your environment?')
invariant(config.mercureUrl, 'Mercure url is not defined. Did you forget to setup your environment?')

export default config
