export { default as AddIcon } from '@material-ui/icons/Add'
export { default as AndroidIcon } from '@material-ui/icons/Android'
export { default as ArrowDropDownIcon } from '@material-ui/icons/ArrowDropDown'
export { default as ArrowDropUpIcon } from '@material-ui/icons/ArrowDropUp'
export { default as ArrowForwardIcon } from '@material-ui/icons/ArrowForward'
export { default as ArtTrackIcon } from '@material-ui/icons/ArtTrack'
export { default as AssessmentIcon } from '@material-ui/icons/Assessment'
export { default as CatalogueIcon } from '@material-ui/icons/ImportContacts'
export { default as CheckIcon } from '@material-ui/icons/Check'
export { default as ChevronLeftIcon } from '@material-ui/icons/ChevronLeft'
export { default as ChevronRightIcon } from '@material-ui/icons/ChevronRight'
export { default as ChevronUpIcon } from '@material-ui/icons/KeyboardArrowUp'
export { default as ChevronDownIcon } from '@material-ui/icons/KeyboardArrowDown'
export { default as CodeIcon } from '@material-ui/icons/Code'
export { default as ContactMailIcon } from '@material-ui/icons/ContactMail'
export { default as ContactSupportIcon } from '@material-ui/icons/ContactSupport'
export { default as CrossIcon } from '@material-ui/icons/Close'
export { default as DashboardIcon } from '@material-ui/icons/Dashboard'
export { default as DeleteForeverIcon } from '@material-ui/icons/DeleteForever'
export { default as DeleteTwoToneIcon } from '@material-ui/icons/DeleteTwoTone'
export { default as DescriptionIcon } from '@material-ui/icons/Description'
export { default as EditIcon } from '@material-ui/icons/Edit'
export { default as ExpandLessIcon } from '@material-ui/icons/ExpandLess'
export { default as ExpandMoreIcon } from '@material-ui/icons/ExpandMore'
export { default as FolderIcon } from '@material-ui/icons/Folder'
export { default as InfoIcon } from '@material-ui/icons/InfoOutlined'
export { default as InputIcon } from '@material-ui/icons/Input'
export { default as KitchenIcon } from '@material-ui/icons/Kitchen'
export { default as LibraryBooksIcon } from '@material-ui/icons/LibraryBooks'
export { default as ListItemIcon } from '@material-ui/core/ListItemIcon'
export { default as LocalShippingIcon } from '@material-ui/icons/LocalShipping'
export { default as LockIcon } from '@material-ui/icons/Lock'
export { default as LockOpenIcon } from '@material-ui/icons/LockOpen'
export { default as MenuIcon } from '@material-ui/icons/Menu'
export { default as MoreHorizIcon } from '@material-ui/icons/MoreHoriz'
export { default as PdfIcon } from '@material-ui/icons/PictureAsPdf'
export { default as PeopleIcon } from '@material-ui/icons/People'
export { default as PersonIcon } from '@material-ui/icons/Person'
export { default as ReorderIcon } from '@material-ui/icons/Reorder'
export { default as SearchIcon } from '@material-ui/icons/Search'
export { default as SettingsIcon } from '@material-ui/icons/Settings'
export { default as ShoppingCartIcon } from '@material-ui/icons/ShoppingCart'
export { default as StoreIcon } from '@material-ui/icons/Store'
export { default as WebIcon } from '@material-ui/icons/Language'
