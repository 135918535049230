import CreateSliceState from '../model/slice/CreateSliceState'

const createInitialState: CreateSliceState = {
  isLoading: true,
  success: undefined,
  errors: undefined,
  newId: undefined,
}

export default createInitialState
