import React, { ReactNode } from 'react'
import { v4 } from 'uuid'
import { VoidFunction } from '@models/function/VoidFunction'

const useFieldsComponent = (fields?: ReactNode[], onClick?: VoidFunction): ReactNode => {
  if (!fields) {
    return
  }

  return (
    <div role='button' tabIndex={0} onClick={onClick} onKeyDown={onClick}>
      {fields.map((field) => (
        <div key={v4()}>{field}</div>
      ))}
    </div>
  )
}

export default useFieldsComponent
