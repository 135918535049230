import { createSlice } from '@reduxjs/toolkit'
import { createClientOrder } from '../action'
import CreateSliceState from '../model/slice/CreateSliceState'
import CreateSliceReducer from '../model/slice/CreateSliceReducer'
import createInitialState from '../initialState/createState'
import { ClientOrderModel } from '@models/entities/clientOrders'
import { FulfilledAction, RejectedValidationAction } from '@utils/redux/action'

const createOrderSlice = createSlice<CreateSliceState, CreateSliceReducer>({
  name: 'clientOrders_create',
  initialState: createInitialState,
  reducers: {},
  extraReducers: {
    [createClientOrder.pending.type]: (state: CreateSliceState) => {
      state.isLoading = true
      state.success = createInitialState.success
      state.newId = createInitialState.newId
      state.errors = createInitialState.errors
    },
    [createClientOrder.fulfilled.type]: (state: CreateSliceState, action: FulfilledAction<ClientOrderModel>) => {
      state.isLoading = false
      state.success = action.payload.documentData.reference
      state.newId = action.payload.id
      state.errors = createInitialState.errors
    },
    [createClientOrder.rejected.type]: (state: CreateSliceState, action: RejectedValidationAction) => {
      state.isLoading = false
      state.success = createInitialState.success
      state.newId = createInitialState.newId
      state.errors = action.payload.errors
    },
  },
})

export default createOrderSlice
