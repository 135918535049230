import React, { FC, Suspense } from 'react'
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config'
import { makeStyles } from '../../components/MaterialUI/styles'
import { Loader } from '../../components'
import { Theme } from '../../components/MaterialUI/theme'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    ...theme.mixins.centered,
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      height: '100vh',
      overflow: 'auto',
      padding: theme.spacing(2),
    },
  },
}))

const AuthLayout: FC<RouteConfigComponentProps> = ({ route }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Suspense fallback={<Loader />}>{route && renderRoutes(route.routes)}</Suspense>
      </main>
    </div>
  )
}

export default AuthLayout
