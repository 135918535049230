export const selectInputContent = (input: HTMLInputElement) => {
  input.focus()
  input.select()
}

export const selectEventInputContent = (event: any) => {
  if (navigator.userAgent.search('Firefox') > -1) {
    return
  }

  event.preventDefault()
  selectInputContent(event.target)
}

export const addCustomEventListener = (
  element: HTMLInputElement,
  eventName: string,
  callback: (event: any) => void,
) => {
  element.addEventListener(eventName, callback)
}
