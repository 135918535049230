import React, { ChangeEvent, FC, LazyExoticComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@components/MaterialUI/styles'
import { AppBar, Tab, Tabs as MuiTabs } from '@components/MaterialUI/core'
import TilesContainer from './TilesContainer'
import { TabPanel } from '@components/index'
import getShowDetailsComponent from '@config/selectedObject'
import { useTranslation } from '@utils/translation'
import { Theme } from '@components/MaterialUI/theme'
import { noHeight } from '@theme/styles/noHeight'

const DASHBOARD_TAB_VALUE = 0
const DETAILS_TAB_VALUE = 1

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
  tabWidth: {
    maxWidth: '17vw',
  },
}))

const useStylesForTabPanel = makeStyles(() => ({
  wrapper: {
    height: 'calc(100% - 50px)',
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
  },
  content: {
    height: '100%',
    width: '100%',
    padding: '25px 0 25px 25px',
  },
}))

interface Props {
  component: LazyExoticComponent<any> | null
  object: Record<string, any> | null
  className: string
}

const Tabs: FC<Props> = ({ component: Component = null, object = null, className = '' }) => {
  const theme = useTheme<Theme>()
  const [value, setValue] = useState(DASHBOARD_TAB_VALUE)
  const classes = useStyles()
  const tabPanelClasses = useStylesForTabPanel()
  const { t } = useTranslation()
  const objectIsSelected = object !== null

  const handleChange = (event: ChangeEvent<Record<string, any>>, newValue: any) => {
    setValue(newValue)
  }

  useEffect(() => {
    const activeTab = Component ? DETAILS_TAB_VALUE : DASHBOARD_TAB_VALUE
    setValue(activeTab)
  }, [Component, object])

  const getDetailsComponent = () => {
    if (Component && object) {
      return <Component className={className} object={object.id} />
    }
  }

  return (
    <div className={classes.fullHeight}>
      <AppBar position='sticky' color='default'>
        <MuiTabs
          value={value}
          onChange={handleChange}
          textColor='primary'
          variant='fullWidth'
          aria-label={t('sidebarView:dashboard.label')}
          TabIndicatorProps={noHeight}
        >
          <Tab className={classes.tabWidth} label={t('global:sidebar.dashboard')} />
          <Tab
            className={classes.tabWidth}
            label={t('global:sidebar.details')}
            selected={objectIsSelected}
            disabled={!objectIsSelected}
          />
        </MuiTabs>
      </AppBar>
      <TabPanel
        idPrefix='dashboard-tab'
        value={value}
        index={DASHBOARD_TAB_VALUE}
        dir={theme.direction}
        cssClass={tabPanelClasses}
      >
        <TilesContainer />
      </TabPanel>
      <TabPanel
        idPrefix='details-tab'
        value={value}
        index={DETAILS_TAB_VALUE}
        dir={theme.direction}
        cssClass={tabPanelClasses}
      >
        {getDetailsComponent()}
      </TabPanel>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  const { object, type } = state.selectedObject

  return {
    component: getShowDetailsComponent(type),
    object,
  }
}

export default connect(mapStateToProps)(Tabs)
