import clsx from 'clsx'
import {
  Column,
  isActionsColumn,
  isCheckboxColumn,
  isDropDownSelectColumn,
  isEditableColumn,
  isIconButtonColumn,
  isPrefixedColumn,
} from '../Model/Column'
import ActionsColumn from '../Model/ActionsColumn'
import PrefixedColumn from '../Model/PrefixedColumn'
import { Action } from '../Props/ActionValueProps'
import { isValidFunction } from '@utils/numbers'
import { makeStyles } from '../../MaterialUI/styles'
import { cellStyle } from '../Styles/royal-list-styles'
import EditableColumn from '../Model/EditableColumn'
import { FetchedValue } from '../Model/FetchedValue'
import DropDownSelectColumn from '@components/RoyalList/Model/DropDownSelectColumn'
import { DropdownOption } from '@models/form/dropdown/DropdownOption'
import { DropdownValueModel } from '@models/form/dropdown/DropdownValueModel'
import { AsyncThunk } from '@reduxjs/toolkit'
import useRoyalListService from '@components/RoyalList/Hook/useRoyalListService'
import IconButtonColumn from '@components/RoyalList/Model/IconButtonColumn'
import { ClickEvent } from '@models/events/ClickEvent'

const useStyles = makeStyles(() => ({
  cell: {
    ...cellStyle,
  },
  actions: {
    padding: 0,
  },
}))

interface DistinctiveCellProps {
  className: string
  checked?: boolean
  actions?: Action[]
  prefix?: string
  isValid?: isValidFunction
  isInteger?: boolean
  allowNegativeValues?: boolean
  groupOptions?: DropdownOption[]
  groupTitle?: string
  onChangeSelection?: (item: DropdownValueModel) => void
  dropdownAction?: AsyncThunk<any, any, any>
  actionParam?: FetchedValue
  selectors?: any
  mapToOption?: (value: Record<string, unknown>) => DropdownOption | undefined
  iconButton?: any
  onClick?: (event: ClickEvent, object: Record<string, any>) => void
}

export const useDistinctiveCellProps = (
  column: Column,
  value: FetchedValue,
  object: Record<string, unknown>,
): DistinctiveCellProps => {
  const classes = useStyles()
  const service = useRoyalListService()

  const isActionsCell = isActionsColumn(column)
  const isPrefixedCell = isPrefixedColumn(column)
  const isEditableCell = isEditableColumn(column)
  const isCheckboxCell = isCheckboxColumn(column)
  const isDropdownCell = isDropDownSelectColumn(column)
  const isIconButtonCell = isIconButtonColumn(column)

  let checked
  let actions
  let prefix
  let isValid
  let isInteger
  let allowNegativeValues
  let groupOptions
  let groupTitle
  let onChangeSelection
  let dropdownAction
  let actionParam
  let selectors
  let mapToOption
  let iconButton
  let onClick

  const className = clsx(classes.cell, {
    [classes.actions]: isActionsCell,
  })

  if (isActionsCell) {
    actions = (column as ActionsColumn).actions
  }

  if (isPrefixedCell) {
    prefix = (column as PrefixedColumn).prefix
  }

  if (isEditableCell) {
    isValid = (column as EditableColumn).isValid
    isInteger = (column as EditableColumn).isInteger
    allowNegativeValues = (column as EditableColumn).allowNegativeValues
  }

  if (isCheckboxCell) {
    checked = value as boolean
  }

  if (isDropdownCell) {
    mapToOption = (column as DropDownSelectColumn).mapToOption
    actionParam = (column as DropDownSelectColumn).actionParam

    if (actionParam) {
      // todo Why this value has to be taken from object?
      // todo Why it’s needed here?
      //  More:
      //  https://bitbucket.org/royalcrown/crown-base/pull-requests/349#comment-222733220
      actionParam = service.getValue(object, actionParam)
    }

    selectors = (column as DropDownSelectColumn).selectors
    groupOptions = (column as DropDownSelectColumn).groupOptions
    groupTitle = (column as DropDownSelectColumn).groupTitle
    onChangeSelection = (column as DropDownSelectColumn).onChangeSelection
    dropdownAction = (column as DropDownSelectColumn).dropdownAction
  }

  if (isIconButtonCell) {
    iconButton = (column as IconButtonColumn).iconButton
    onClick = (column as IconButtonColumn).onClick
  }

  return {
    className,
    checked,
    actions,
    prefix,
    isValid,
    isInteger,
    allowNegativeValues,
    dropdownAction,
    groupOptions,
    groupTitle,
    onChangeSelection,
    actionParam,
    selectors,
    mapToOption,
    iconButton,
    onClick,
  }
}
