import React, { FC, useState } from 'react'
import clsx from 'clsx'
import { ChevronLeftIcon, ChevronRightIcon } from '@components/MaterialUI/icons'
import { makeStyles } from '@components/MaterialUI/styles'
import Tabs from './Tabs'
import { Theme } from '@components/MaterialUI/theme'
import { ClickEvent } from '@models/events/ClickEvent'
import { executeClickEvent } from '@utils/events/clickEvents'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  collapsed: {
    display: 'none',
  },
  notCollapsed: {
    width: '29vw',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'none',
    backgroundColor: theme.palette.sidePanel.background,
    borderColor: theme.palette.divider,
    borderLeft: '1px solid',
    width: 'calc(100vw - 10px)',
    right: 0,
  },
  handleBar: {
    width: '2px',
    height: '100vh',
    left: 0,
    backgroundColor: theme.palette.primary.main,
  },
  highlightHandleBar: {
    width: '8px',
  },
  handleBarIcon: {
    position: 'absolute',
    top: 'calc(50% + 22px)',
    zIndex: 999,
  },
  right: {
    borderRadius: '0 50% 50% 0',
  },
  left: {
    marginLeft: '-20px',
    borderRadius: '50% 0 0 50%',
  },
  chevron: {
    height: '25px',
    lineHeight: '25px',
    width: '20px',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    outline: 'none',
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.royal.gray,
      color: theme.palette.primary.main,
    },
  },
  chevronContent: {
    height: 'inherit',
  },
  margin: {
    marginLeft: '25px',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: 'calc(40% - 72px)',
    padding: theme.spacing(2),
    marginTop: '3px',
    borderTop: `2px solid ${theme.palette.divider}`,
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    boxSizing: 'border-box',
    height: '60%',
  },
  tabs: {
    height: 'inherit',
  },
  sideView: {
    height: '100%',
  },
  quickLog: {
    backgroundColor: theme.palette.common.white,
    bottom: 0,
    height: '100%',
  },
}))

const RightPane: FC = () => {
  const [isOpened, setIsOpened] = useState(true)
  const classes = useStyles()
  const [hovered, setHovered] = useState<boolean>(false)

  const toggleDrawer = (event: ClickEvent) => {
    executeClickEvent(event, () => setIsOpened(!isOpened))
  }

  const toggleHover = () => {
    setHovered(!hovered)
  }

  const handleBarClasses = clsx(classes.handleBar, { [classes.highlightHandleBar]: hovered && isOpened })

  const chevronClasses = clsx(classes.chevron, {
    [classes.right]: isOpened,
    [classes.left]: !isOpened,
  })

  const contentClasses = clsx(classes.content, {
    [classes.margin && classes.collapsed]: !isOpened,
  })

  return (
    <div className={clsx(classes.root, { [classes.notCollapsed]: isOpened })}>
      <div className={handleBarClasses}>
        <div className={classes.handleBarIcon}>
          <div
            role='button'
            tabIndex={0}
            onKeyDown={toggleDrawer}
            onFocus={toggleHover}
            onBlur={toggleHover}
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className={chevronClasses}
            onClick={toggleDrawer}
          >
            {isOpened ? (
              <ChevronRightIcon className={classes.chevronContent} />
            ) : (
              <ChevronLeftIcon className={classes.chevronContent} />
            )}
          </div>
        </div>
      </div>
      <div className={contentClasses}>
        <div className={classes.sideView}>
          <div className={classes.top}>
            <Tabs className={classes.tabs} />
          </div>
          <div className={classes.bottom}>
            <div className={classes.quickLog} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RightPane
