import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import { RouteConfigComponentProps } from 'react-router-config'
import { GuestGuard } from '../../features/auth/components'
import { AuthLayout } from '../../layouts'
import { ACCOUNT_CONFIRMATION_PATH, DASHBOARD_PATH, LOGIN_PATH } from './paths'
import { createPath } from '@utils/route'

const authRoutes = {
  path: createPath('/auth'),
  component: (props: RouteConfigComponentProps) => (
    <GuestGuard>
      <AuthLayout {...props} />
    </GuestGuard>
  ),
  routes: [
    {
      path: createPath(LOGIN_PATH),
      exact: true,
      component: lazy(() => import('../../views/Login/Login')),
    },
    {
      path: createPath(ACCOUNT_CONFIRMATION_PATH),
      exact: true,
      component: lazy(() => import('../../views/Account/Confirmation')),
    },
    {
      component: () => <Redirect to={DASHBOARD_PATH} />,
    },
  ],
}

export default authRoutes
