export enum Separator {
  Decimal = ',',
  Thousand = '.',
}

export enum PrefixSign {
  EURO = '€',
  PERCENTAGE = '%',
  AMOUNT = '#',
  NONE = '',
}

export enum MinMax {
  DefaultMinimum = 0,
  AbsoluteMaximum = 999999999,
}
