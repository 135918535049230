import React, { FC } from 'react'
import clsx from 'clsx'
import { v4 } from 'uuid'
import { InputLabel } from '@components/MaterialUI/core'
import { makeStyles } from '@components/MaterialUI/styles'
import { useTranslation } from '@utils/translation'
import { FONT_WEIGHT_BOLD } from '@theme/constants'
import LabeledStyles from './LabeledStyles'

const useStyles = makeStyles(() => ({
  row: {
    ...LabeledStyles.row,
    marginTop: '20px',
  },
  line: {
    ...LabeledStyles.line,
    fontWeight: FONT_WEIGHT_BOLD,
  },
  indent: {
    ...LabeledStyles.indent,
  },
}))

interface Props {
  labelText: string
  indent?: boolean
  className?: string
}

const LabelWithoutValue: FC<Props> = ({ labelText, indent = false, className }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={clsx(classes.row, className)} key={v4()}>
      <InputLabel className={clsx(classes.line, { [classes.indent]: indent })}>{`${t(labelText)}:`}</InputLabel>
    </div>
  )
}

export default LabelWithoutValue
