import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { formatAddress } from '@utils/address'

const Address = ({ address }) => {
  if (isEmpty(address)) {
    return null
  }

  return formatAddress(address)
}

Address.propTypes = {
  address: PropTypes.shape({
    street: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
  }),
}

export default Address
