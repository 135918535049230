export enum FieldType {
  Text = 'string',
  Textarea = 'text',
  Number = 'number',
  Checkbox = 'checkbox',
  Dropdown = 'dropdown',
  Date = 'date',
  Object = 'object',
  List = 'list',
  TreeView = 'treeView',
  NotEditable = 'notEditable',
  Collection = 'collection',
}
