import { combineReducers } from 'redux'
import { createGroupOrderSlice, mainSlice, paginationSlice, readSlice, updateSlice } from './slice'

const reducer = combineReducers({
  main: mainSlice.reducer,
  pagination: paginationSlice.reducer,
  read: readSlice.reducer,
  create: createGroupOrderSlice.reducer,
  update: updateSlice.reducer,
})

export default reducer
