import { compile } from 'path-to-regexp'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import localeConfiguration from '../config/locale'
import { RouteParameters } from '@models/route/RouteParameters'

type RedirectToFunction = (path: string, parameters?: RouteParameters, additionalParameters?: string) => void

const createPath = (path: string): string => {
  const locales = localeConfiguration.locales.join('|')

  return `/:lang(${locales})?${path}`
}

const generateUrl = (route: string, parameters?: RouteParameters): string => {
  const toPath = compile(route)

  return toPath(parameters)
}

const useRedirectTo = (): RedirectToFunction => {
  const history = useHistory()

  return (path: string, parameters?: RouteParameters, additionalParameters?: string) => {
    let url = generateUrl(path, parameters)

    if (!isEmpty(additionalParameters)) {
      url = `${url}?${additionalParameters}`
    }

    history.push(url)
  }
}

export { createPath, generateUrl, useRedirectTo }
