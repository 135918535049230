import http from '@utils/http'
import authStorage from '@features/auth/storage'
import { generateUrl } from '@utils/route'
import { DocumentsTypesToRelease } from '@features/global/releaseDocuments/model/DocumentsTypesToRelease'
import { DocumentsTypes } from '@features/global/releaseDocuments/model/DocumentsTypes'

const RELEASE_DOCUMENTS_API_URL = '/api/users/:id(\\d+)/release_documents'

export const releaseAllDocumentsOfCurrentUser = async () => {
  return releaseDocumentsOfCurrentUser({
    [DocumentsTypes.ClientOrders]: true,
  })
}

export const releaseDocumentsOfCurrentUser = async (typesToRelease: DocumentsTypesToRelease) => {
  const authData = authStorage.getData()

  if (!authData) {
    return
  }

  const url = generateUrl(RELEASE_DOCUMENTS_API_URL, { id: authData.userId })

  return await http.patch(url, typesToRelease)
}
