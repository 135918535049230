import React from 'react'
import defaultPropTypes from '../common/propTypes'
import { TextField } from '../../../MaterialUI/core'

const Textarea = (props) => {
  return <TextField multiline rows={4} {...props} />
}

Textarea.propTypes = defaultPropTypes

export default Textarea
