import createApiAction from '@features/@common/actions/api-actions'
import * as api from '../api'
import { parsePaginatedCollection } from '@utils/api/data'

const fetchSuggestedUSPsForArticle = createApiAction<any, number, any>(
  'uniqueSellingPoint.fetch',
  async (articleId: number) => {
    const response = await api.fetchSuggestedUSPsForArticle(articleId)

    return parsePaginatedCollection(response)
  },
)

export { fetchSuggestedUSPsForArticle }
