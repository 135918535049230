import React, { FC, MouseEvent, useState } from 'react'
import { connect } from 'react-redux'
import { IconButton, ListItemText, Menu, MenuItem } from '@components/MaterialUI/core'
import { InputIcon, ListItemIcon, PersonIcon } from '@components/MaterialUI/icons'
import { selectCurrentUser } from '@features/auth/selector'
import { useTranslation } from '@utils/translation'
import * as authActions from '@features/auth/action'
import { AuthUserModel } from '@models/entities/authUser/AuthUserModel'
import { VoidFunction } from '@models/function/VoidFunction'

interface Props {
  user: AuthUserModel
  logout: VoidFunction
}

const UserActions: FC<Props> = ({ user, logout }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const logoutText = t('auth:actions.logout')

  const openUserMenu = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const closeUserMenu = () => {
    setAnchorEl(null)
  }

  const logoutUser = () => {
    closeUserMenu()
    logout()
  }

  return (
    <div>
      <IconButton color='inherit' onClick={openUserMenu}>
        <PersonIcon />
      </IconButton>
      <Menu id='user-actions' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeUserMenu}>
        <MenuItem disabled>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText>{user.email}</ListItemText>
        </MenuItem>
        <MenuItem onClick={logoutUser}>
          <ListItemIcon>
            <InputIcon />
          </ListItemIcon>
          <ListItemText>{logoutText}</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  user: selectCurrentUser(state),
})

const mapDispatchToProps = {
  logout: authActions.logout,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserActions)
