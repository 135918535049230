import Box from '@material-ui/core/Box'
import React, { FC, PropsWithChildren } from 'react'
import { makeStyles } from '../../MaterialUI/styles'
import { Padding } from '@theme/styles/padding'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  box: {
    height: '100%',
    display: 'flex',
    width: '100%',
    padding: `${Padding.Small} 25px`,
    flexDirection: 'column',
  },
  fullHeight: {
    width: '100%',
    overflow: 'auto',
  },
}))

interface Props {
  value: number
  index: number
}

const VerticalTabPanel: FC<PropsWithChildren<Props>> = ({ children, value, index }) => {
  const classes = useStyles()

  return (
    <div
      className={classes.fullHeight}
      role='tabpanel'
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box className={classes.box} p={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

export default VerticalTabPanel
