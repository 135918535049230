import createApiAction from '@features/@common/actions/api-actions'
import { parsePaginatedCollection } from '@utils/api/data'
import * as api from '../api'
import {
  CreateBrandConditionParameters,
  FetchParameters,
  UpdateClientConditionParameters,
  UpdateParameters,
} from '../api'

const fetchBrandConditionsForBrand = createApiAction<any, FetchParameters, any>(
  'brandConditions.forBrand.fetch',
  async (parameters: FetchParameters) => {
    const response = await api.fetchBrandConditionsForBrand(parameters)
    return parsePaginatedCollection(response)
  },
)

const updateBrandCondition = createApiAction<any, UpdateParameters, any>(
  'brandConditions.update',
  api.updateBrandCondition,
)

const updateBrandConditionForClient = createApiAction<any, UpdateClientConditionParameters, any>(
  'brandConditions.update',
  api.updateBrandConditionForClient,
)

const createBrandCondition = createApiAction<any, CreateBrandConditionParameters, any>(
  'brandCondition.create',
  api.createBrandCondition,
)

export { fetchBrandConditionsForBrand, updateBrandConditionForClient, updateBrandCondition, createBrandCondition }
