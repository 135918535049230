import { lazy, LazyExoticComponent } from 'react'
import ComponentDefinition from '@models/selectedObject/ComponentDefinition'
import ObjectType from '@models/selectedObject/ObjectType'

const components: ComponentDefinition[] = [
  {
    type: ObjectType.ClientOrder,
    component: lazy<any>(() => import('../features/clientOrderItems/components/MetaDetails/ClientOrderMetaDetails')),
  },
  {
    type: ObjectType.Article,
    component: lazy<any>(() => import('../features/articles/components/Details/MetaDetails/ArticleMetaDetails')),
  },
  {
    type: ObjectType.GroupOrder,
    component: lazy<any>(() => import('../features/groupOrders/components/Details/MetaDetails/GroupOrderMetaDetails')),
  },
  {
    type: ObjectType.Brand,
    component: lazy<any>(() => import('../features/brands/components/Details/metaDetails/BrandMetaDetails')),
  },
]

const getShowDetailsComponent = (type: ObjectType): LazyExoticComponent<any> | null => {
  if (components.length === 0) {
    return null
  }

  const definition = components.find((definition: ComponentDefinition) => {
    return definition.type === type
  })

  if (definition === undefined) {
    return null
  }

  return definition.component
}

export default getShowDetailsComponent
