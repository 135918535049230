import invariant from 'invariant'
import { AuthenticationData } from '@models/api/authentication'

const KEY = 'crown_app_auth'

enum TokenType {
  Application,
  Mercure,
}

class AuthStorage {
  private storage: Storage

  constructor(storage: Storage) {
    this.storage = storage
  }

  getApplicationToken(): string | undefined {
    return this.getToken(TokenType.Application)
  }

  getMercureToken(): string | undefined {
    return this.getToken(TokenType.Mercure)
  }

  storeData(data: AuthenticationData) {
    const jsonData = JSON.stringify(data)

    this.storage.setItem(KEY, jsonData)
  }

  getData(): AuthenticationData | undefined {
    try {
      const jsonData = this.storage.getItem(KEY)
      const result: AuthenticationData = JSON.parse(jsonData as string)

      invariant(result.token, 'Token should be set')
      invariant(result.mercureToken, 'Mercure token should be set')
      invariant(result.userId, 'User ID should be set')

      return result
    } catch (e) {
      return undefined
    }
  }

  clear() {
    this.storage.removeItem(KEY)
  }

  private getToken(type: TokenType): string | undefined {
    const data = this.getData()

    if (!data) {
      return undefined
    }

    if (type === TokenType.Application && data.token) {
      return data.token
    }

    if (type === TokenType.Mercure && data.mercureToken) {
      return data.mercureToken
    }

    return undefined
  }
}

export default new AuthStorage(window.localStorage)
