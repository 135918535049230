import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { createApiAction } from '../@common'
import authStorage from './storage'
import * as api from './api'
import { beforeLogout } from '@features/auth/beforeLogout'

export const login = createApiAction('auth.login', api.login)

export const logout = createAsyncThunk('auth.logout', () => {
  beforeLogout.map(async (before) => {
    await before.callback(before.arguments)
  })
})

export const tokenReceived = createAction('auth.token.received')
export const checkConfirmation = createApiAction('auth.confirmation.check', api.checkConfirmation)
export const passwordSetup = createApiAction('auth.account.password.setup', api.passwordSetup)
export const fetchProfile = createApiAction('auth.profile.fetch', api.fetchProfile)

export const fetchCurrentUser = createApiAction('auth.user.fetch', () => {
  const data = authStorage.getData()

  return api.fetchProfile(data?.userId)
})
