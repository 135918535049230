import { combineReducers } from 'redux'
import { mainSlice } from './slice'
import readSlice from './slice/read'

const reducer = combineReducers({
  main: mainSlice.reducer,
  read: readSlice.reducer,
})

export default reducer
