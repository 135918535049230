import { TreeViewFieldModel } from '@models/form/fields/FieldModels'
import { TreeNodeModel } from '@models/form/tree/TreeNodeModel'
import { HasIdEntity } from '@models/entities/HasIdEntity'
import { NodeFieldModel, Parent } from '@components/Form/FormFields/model/TreeView'

interface Field<T> {
  id: number
  name: string
  parent?: Parent<T>
}

const setNestedTreeNode = <V extends HasIdEntity>(fields: Field<V>[], node: TreeNodeModel<V>) => {
  if (!node.parent) {
    return
  }

  fields.unshift(node.parent.object)
  setNestedTreeNode(fields, node.parent)
}

export const getNodeFieldModels = <V extends HasIdEntity>(field: TreeViewFieldModel): NodeFieldModel<V>[] => {
  let result: NodeFieldModel<V>[] = []

  if (field.value) {
    const treeNode = field.value as TreeNodeModel<V>
    result = [treeNode.object]
    setNestedTreeNode<V>(result, treeNode)
  }

  return result
}
