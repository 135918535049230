import { formatWithTwoDecimals } from './numbers'
import { PerUnit } from '@config/time'

export const minutes2milliseconds = (minutes: number): number | undefined => {
  if (minutes < 0) {
    return
  }

  return PerUnit.MillisecondsPerMinute * minutes
}

export const milliseconds2minutes = (milliseconds: number): number | undefined => {
  if (milliseconds < 0) {
    return
  }

  const result = milliseconds / PerUnit.MillisecondsPerMinute

  if (result < 1) {
    return Number(formatWithTwoDecimals(result))
  }

  return result
}

export const milliseconds2seconds = (milliseconds: number): number | undefined => {
  if (milliseconds < 0) {
    return
  }

  return milliseconds / PerUnit.MillisecondsPerSecond
}
