import { CSSProperties } from '@material-ui/core/styles/withStyles'
import palette from '../../theme/palette'

const tooltipTextColor = palette.primary.main
const tooltipBackgroundColor = palette.royal.lightGray
const tooltipMargin = '10px'
const tooltipArrowSize = '6px'

const wrapper: CSSProperties = {
  display: 'inline-block',
  position: 'relative',
}

const tip: CSSProperties = {
  position: 'absolute',
  borderRadius: '5px',
  left: '50%',
  transform: `translateX(${-50}%)`,
  padding: '6px',
  color: `${tooltipTextColor}`,
  background: `${tooltipBackgroundColor}`,
  fontSize: '14px',
  lineHeight: 1,
  zIndex: 100,
  whiteSpace: 'nowrap',
  border: `1px solid ${palette.primary.main}`,
  '&:before': {
    left: '50%',
    border: 'solid transparent',
    height: 0,
    width: 0,
    position: 'absolute',
    pointerEvents: 'none',
    borderWidth: `${tooltipArrowSize}`,
    marginLeft: `calc(${tooltipArrowSize} * ${-1})`,
    content: '" "',
  },
}

const top: CSSProperties = {
  top: `calc(${tooltipMargin} * -1)`,
  transform: `translateX(${-50}%) translateY(${-100}%)`,
  '&:before': {
    top: '100%',
    borderTopColor: `${palette.primary.main}`,
  },
}

const right: CSSProperties = {
  top: '50% ',
  transform: `translateX(${0}) translateY(${-50}%)`,
  '&:before': {
    left: `calc((${tooltipArrowSize} * ${-1}) - 1px)`,
    position: 'absolute',
    top: '50%',
    transform: `translateX(${0}) translateY(${-50}%)`,
    borderRight: ` ${tooltipArrowSize} solid ${palette.primary.main}`,
  },
}

const bottom: CSSProperties = {
  bottom: `calc((${tooltipMargin} * -1) + -2px)`,
  transform: `translateX(${-50}%) translateY(${+100}%)`,
  '&:before': {
    bottom: 'calc(100% + 1px)',
    borderBottomColor: `${palette.primary.main}`,
  },
}

const left: CSSProperties = {
  top: '50%',
  transform: `translateX(${-100}%) translateY(${-50}%)`,
  '&:before': {
    left: `calc(100% + (${tooltipArrowSize}))`,
    top: '50%',
    transform: `translateX(${0}) translateY(${-50}%)`,
    borderLeft: `${tooltipArrowSize} solid ${palette.primary.main}`,
  },
}

type ToolTipTipType = {
  wrapper: CSSProperties
  tip: CSSProperties
  top: CSSProperties
  right: CSSProperties
  bottom: CSSProperties
  left: CSSProperties
}

const TooltipStyle: ToolTipTipType = {
  wrapper,
  tip,
  top,
  right,
  bottom,
  left,
}

export default TooltipStyle
