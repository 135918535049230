import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from '@utils/translation'
import { ProcessingButton } from '@components/index'
import { ButtonType } from '@app/enums/form/ButtonType'
import { createFormFields } from '@utils/form/fields/createFormFields'
import { getFormDataObject } from '@utils/form/submitData/getFormDataObject'
import useFieldsComponent from '@hooks/form/useFieldsComponent'
import { VoidFunction } from '@models/function/VoidFunction'

interface Props {
  onSave: (formData: Record<string, any>) => void
  formObject?: Record<string, any>
  disabled?: boolean
  autoSave?: boolean
  onClick?: VoidFunction
}

const Form: FC<Props> = ({ formObject, onSave, disabled = true, autoSave = false, onClick }) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    watch,
  } = useForm<any, any>()

  const data = watch()
  const { t } = useTranslation()
  const fields = createFormFields(formObject, control, disabled as boolean)
  const component = useFieldsComponent(fields, onClick)

  const onSubmit = (data: Record<string, any>) => {
    const dataObject: Record<string, any> = getFormDataObject(data)
    onSave(dataObject)
  }

  useEffect(() => {
    if (autoSave && isDirty && !disabled) {
      onSave(data)
    }
  }, [autoSave, isDirty, data, disabled, onSave])

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {component}

      {!disabled && isDirty && !autoSave && (
        <ProcessingButton type={ButtonType.Submit}>{t('form:action.save')}</ProcessingButton>
      )}
    </form>
  )
}

export default Form
