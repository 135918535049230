import React, { ChangeEvent, FC, useState } from 'react'
import { Theme } from '@components/MaterialUI/theme'
import { Tab, Tabs } from '@components/MaterialUI/core'
import { makeStyles } from '@components/MaterialUI/styles'
import VerticalTabPanel from './VerticalTabPanel'
import { TabPanel } from '../Type/TabPanel'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

interface Props {
  tabPanels: TabPanel[]
  activeTab: number
  onTabChange: (value: number) => void
}

const VerticalTabs: FC<Props> = ({ tabPanels, activeTab, onTabChange }) => {
  const classes = useStyles()
  const [value, setValue] = useState(activeTab)

  const handleChange = (event: ChangeEvent<Record<string, any>>, newValue: number) => {
    setValue(newValue)
    onTabChange(newValue)
  }

  const tabs = tabPanels.map((tab) => <Tab key={tab.title} label={tab.title} />)

  const panels = tabPanels.map((tabPanel, index) => (
    <VerticalTabPanel key={index} index={index} value={value}>
      {tabPanel.component}
    </VerticalTabPanel>
  ))

  return (
    <div className={classes.root}>
      <Tabs orientation='vertical' variant='scrollable' value={value} onChange={handleChange} className={classes.tabs}>
        {tabs}
      </Tabs>
      {panels}
    </div>
  )
}

export default VerticalTabs
