import EmployeeType from './model/EmployeeType'
import { User } from './model/User'

/**
 * Selects current authenticated user
 *
 * @param state
 * @returns User
 */
const selectCurrentUser = (state: any): User => state.auth.main.user

const selectCurrentUserType = (state: any): EmployeeType | undefined => {
  const person = state.auth?.main?.user?.person

  if (person) {
    return person['@type']
  }

  return undefined
}

// "any" is used here, because at this moment returned type is unpredicted
const selectClientFromAuthContext = (state: any): any => {
  return state.auth.context.client
}

const selectHasSwitchedClient = (state: any): boolean => {
  const client = selectClientFromAuthContext(state)
  const currentClientIri = state.auth?.main?.user?.person?.client

  return client !== null && client['@id'] !== currentClientIri
}

// eslint-disable-next-line import/prefer-default-export
export { selectCurrentUser, selectCurrentUserType, selectClientFromAuthContext, selectHasSwitchedClient }
