import { CSSProperties, makeStyles } from '@components/MaterialUI/styles'
import { noArrowsInputStyle } from '@theme/custom'
import { SMALL_FONT_SIZE } from '@theme/constants'
import shape from '@theme/shape'
import palette from '@theme/palette'
import { Padding } from '@theme/styles/padding'

type StylesProps = {
  noArrowsInputStyle: CSSProperties
}

const root: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}

const editClasses: CSSProperties = {
  borderRadius: shape.borderRadius,
  border: `1px solid ${palette.royal.lightGray}`,
  margin: '3px 2px',
  paddingRight: Padding.Small,
  '&:hover': {
    border: `1px solid ${palette.royal.nearlyWhite}`,
    cursor: 'text',
  },
}

const editFormClasses: CSSProperties = {
  ...editClasses,
  '&:hover': {
    border: `1px solid ${palette.royal.highlight}`,
    cursor: 'text',
  },
  '&:focus': {
    backgroundColor: `${palette.royal.highlight}`,
  },
}

const displayClasses = (settings: StylesProps, forEdit: boolean, form: boolean): CSSProperties => {
  const forEditClasses = forEdit && !form ? editClasses : ''
  const forEditFormClasses = forEdit && form ? editFormClasses : ''
  const textAlign = form ? 'left' : 'right'

  return {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    fontSize: SMALL_FONT_SIZE,
    '& input': {
      textAlign: `${textAlign}`,
      ...settings.noArrowsInputStyle,
    },
    ...forEditClasses,
    ...forEditFormClasses,
  }
}

const createNumberStyles = (settings: StylesProps, forEdit: boolean, form?: boolean) => {
  const forForm = form ? form : false

  return makeStyles(() => ({
    root: {
      ...root,
    },
    numberField: {
      ...displayClasses(settings, forEdit, forForm),
    },
  }))
}

const useDisplayNumberStyles = () => {
  return createNumberStyles({ noArrowsInputStyle }, false)()
}

const useEditNumberStyles = () => {
  return createNumberStyles({ noArrowsInputStyle }, true)()
}

const useFormEditNumberStyles = () => {
  return createNumberStyles({ noArrowsInputStyle }, true, true)()
}

const useFormDisplayNumberStyles = () => {
  return createNumberStyles({ noArrowsInputStyle }, false, true)()
}

export { useDisplayNumberStyles, useEditNumberStyles, useFormDisplayNumberStyles, useFormEditNumberStyles }
