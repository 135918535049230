import { DropdownOption } from '@models/form/dropdown/DropdownOption'
import { ListDropdownModel } from '@models/form/list'

export const getDropdownOptionIndexFromListByOptionId = (
  dropdownOptions: DropdownOption[],
  optionId: number,
): number => {
  return dropdownOptions.findIndex((item: DropdownOption) => item.id === optionId)
}

export const getDropdownsWithValues = (
  dropdownFields: ListDropdownModel[],
  dropdownOption: DropdownOption,
  defaultIsUndefined?: boolean,
): ListDropdownModel[] => {
  dropdownFields.forEach((dropdownField) => {
    const foundIndex = getDropdownOptionIndexFromListByOptionId(dropdownField.dropdown.options, dropdownOption.id)

    if (foundIndex > -1) {
      dropdownField.dropdown.value = defaultIsUndefined ? undefined : dropdownOption
    }
  })

  return dropdownFields
}
