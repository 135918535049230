import { renderRoutes } from 'react-router-config'
import { connect } from 'react-redux'
import { FC } from 'react'
import getRoutes from '../config/routes'
import { selectCurrentUserType } from '@features/auth/selector'
import EmployeeType from '@features/auth/model/EmployeeType'

interface Props {
  userType: EmployeeType | undefined
}

const Router: FC<Props> = ({ userType }) => {
  const routes = getRoutes(userType)

  return renderRoutes(routes)
}

const mapStateToProps = (state: any) => ({
  userType: selectCurrentUserType(state),
})

export default connect(mapStateToProps)(Router)
