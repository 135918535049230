import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { Page } from '@components/index'
import EmptyComponent from '../Placeholder'
import { useTranslation } from '@utils/translation'
import { actions as selectObjectActions } from '@features/selectObject/redux/slice'
import { SlideToggle } from '@components/Animations/SlideToggle'

type Props = {
  hideSelectObjectDetails: ActionCreatorWithPayload<void> | ActionCreatorWithoutPayload
}

const Dashboard: FC<Props> = ({ hideSelectObjectDetails }) => {
  const { t } = useTranslation()

  useEffect(() => {
    hideSelectObjectDetails()
  }, [hideSelectObjectDetails])

  return (
    <Page title={t('navigation.dashboard')}>
      <SlideToggle>
        <EmptyComponent title='This is a Dashboard' />
      </SlideToggle>
    </Page>
  )
}

const mapDispatchToProps = {
  hideSelectObjectDetails: selectObjectActions.hideDetails,
}

export default connect(null, mapDispatchToProps)(Dashboard)
