import {
  CommonNodeFieldModel,
  CreateNodeFieldCallback,
  NodeFieldModel,
} from '@components/Form/FormFields/model/TreeView'
import { createNodeFields } from '@utils/form/fields/TreeView'

const findFirstParent = (nodesData: Record<string, any>[]): Record<string, any> | undefined => {
  if (!!nodesData && !!nodesData[0]) {
    return nodesData[0].parent
  }

  return undefined
}

export const getParentNodeFieldsFromNode = (
  createNodeField: CreateNodeFieldCallback,
  nodeFields: CommonNodeFieldModel[],
  nodesData: Record<string, any>[],
): CommonNodeFieldModel[] => {
  if (nodeFields.length === 0) {
    return []
  }

  const result = nodeFields
  const parent = findFirstParent(nodesData)

  if (parent) {
    const parentId = parent.id
    const fields = createNodeFields(createNodeField, nodesData)

    result.forEach((nodeField, index) => {
      if (nodeField.id === parentId) {
        result[index] = new NodeFieldModel(nodeField.name, nodeField.id, nodeField.iriId, nodeField.parent, fields)
      }

      if (result[index].children && result[index].children.length) {
        return getParentNodeFieldsFromNode(createNodeField, result[index].children, nodesData)
      }
    })
  }

  return result
}
