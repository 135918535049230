import React, { FC } from 'react'
import { Column } from '../Model/Column'
import HeaderCell from './HeaderCell'
import { makeStyles } from '@components/MaterialUI/styles'
import palette from '@theme/palette'
import { Theme } from '@components/MaterialUI/theme'

const useStyles = makeStyles((theme: Theme) => {
  const height = '35px'

  return {
    header: {
      display: 'flex',
      width: '100%',
      height: height,
      lineHeight: height,
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
      backgroundColor: palette.sidePanel.background,
      color: palette.royal.gray,
    },
  }
})

type Props = {
  columns: Column[]
}

const Header: FC<Props> = ({ columns }) => {
  const classes = useStyles()

  return (
    <div className={classes.header}>
      {columns.map((column, index) => (
        <HeaderCell
          key={index}
          name={column.name}
          className={column.className}
          abbreviation={column.abbreviation}
          sortBy={column.sortBy}
          alignment={column.alignment?.name}
        />
      ))}
    </div>
  )
}

export default Header
