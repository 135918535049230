export enum ApiParameter {
  Sorting = 'order',
}

export enum UrlParameter {
  Sorting = 'sort',
  Filtering = 'filters',
}

export enum SortingDirection {
  Ascending = 'asc',
  Descending = 'desc',
}
