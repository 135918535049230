import React, { FC, PropsWithChildren } from 'react'
import { Box, IconButton } from '@components/MaterialUI/core'
import a11yProps from '../Helper/a11yProps'
import { EditIcon } from '@components/MaterialUI/icons'
import { makeStyles } from '@components/MaterialUI/styles'
import { editIcon } from '@components/Tabs/styles/tabStyles'
import clsx from 'clsx'
import { VoidFunction } from '@models/function/VoidFunction'

const useStyles = makeStyles(() => ({
  icon: {
    ...editIcon,
  },
  tabPanel: {
    height: '100%',
    padding: '0 0 50px 0',
    '&:focus': {
      outline: 'none',
    },
  },
}))

interface Props {
  idPrefix: string
  index: number
  value: number
  withEditIcon?: boolean
  onEditIconClick?: VoidFunction
  cssClass?: {
    wrapper: string
    content: string
  }
  [property: string]: any
}

const TabPanel: FC<PropsWithChildren<Props>> = ({
  idPrefix,
  index,
  value,
  cssClass,
  children,
  withEditIcon,
  onEditIconClick,
  ...other
}) => {
  const props = a11yProps(idPrefix, index)
  const classes = useStyles()
  const wrapper = clsx(cssClass?.wrapper, classes.tabPanel)

  return (
    <div
      role='tabpanel'
      tabIndex={0}
      hidden={value !== index}
      id={props['aria-controls']}
      aria-labelledby={props.id}
      className={wrapper}
      {...other}
    >
      {value === index && (
        <Box p={2} className={cssClass?.content}>
          {withEditIcon && (
            <IconButton className={classes.icon} onClick={onEditIconClick}>
              <EditIcon />
            </IconButton>
          )}
          {children}
        </Box>
      )}
    </div>
  )
}

export default TabPanel
