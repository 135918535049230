import React, { FC } from 'react'
import clsx from 'clsx'
import { Card, CardActionArea } from '../MaterialUI/core'
import { makeStyles } from '../MaterialUI/styles'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'fit-content',
  },
  actionArea: {
    height: '100%',
  },
  media: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    height: '100%',
    maxHeight: '200px',
  },
})

interface Props {
  imageUrl: string
  alt?: string
  onImageClick?: (value: string) => void
  className?: string
}

const ImageCard: FC<Props> = ({ imageUrl, alt, onImageClick, className }) => {
  const classes = useStyles()
  const cardStyle = clsx(classes.root, className)

  const handleClick = () => {
    if (onImageClick) {
      onImageClick(imageUrl)
    }
  }

  const handleKeyDown = () => true
  const handleKeyPress = () => true
  const handleKeyUp = () => true

  return (
    <Card className={cardStyle}>
      <CardActionArea className={classes.actionArea}>
        <div className={classes.media}>
          <img
            className={classes.image}
            src={imageUrl}
            alt={alt}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            onKeyPress={handleKeyPress}
            onKeyUp={handleKeyUp}
            role='presentation'
          />
        </div>
      </CardActionArea>
    </Card>
  )
}

export default ImageCard
