let clientIri: string | undefined

type Result = {
  clientIri?: string
  setClientIri: (clientIri: string) => void
}

const useClientIri = (): Result => {
  const setClientIri = (newClientIri: string) => {
    clientIri = newClientIri
  }

  return {
    clientIri,
    setClientIri,
  }
}

export default useClientIri
