import React, { ChangeEvent, FC, ReactNode, Ref, useEffect, useRef, useState } from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase'
import { MinMax, PrefixSign, Separator } from '@config/number'
import { isValidFunction, isValueBetween } from '@utils/numbers'
import { extractFirstNumber } from '@utils/regex'
import { RoyalTooltip } from '@components/index'
import { makeStyles } from '@components/MaterialUI/styles'
import NumberInputDisplay from './partial/NumberInputDisplay'
import { connect } from 'react-redux'
// todo This component shouldn't be Redux-aware, since it's located in src/components it shouldn't use functionality
//  from src/features)
import actions from '@features/focusedInput/redux/action'
import { addCustomEventListener, selectEventInputContent, selectInputContent } from '@utils/input'
import { getFocusedId } from '@features/focusedInput/redux/selector'
import { FieldError } from 'react-hook-form'

interface NumberChangeEvent {
  target: {
    name: string
    value: string
  }
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: NumberChangeEvent) => void
}

const useStyles = makeStyles(() => ({
  tooltip: {
    transform: 'translate3d(-50%, calc(100% - 5px), 0) !important',
  },
}))

interface Props {
  value: number
  id: string
  title?: string
  disabled?: boolean
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void
  prefix?: string
  decimalSeparator?: string
  thousandSeparator?: string
  min?: number
  max?: number
  isInteger?: boolean
  isFocused?: boolean
  inputProps?: InputBaseComponentProps
  isValid?: isValidFunction
  className?: string
  ref?: Ref<HTMLInputElement>
  allowNegativeValues?: boolean
  setFocusedId: (value: string) => void
  focusedId?: string
  error?: FieldError
}

const defaultIsValidFunction = (min?: number, max?: number): isValidFunction => {
  return (value?: number): boolean => {
    return isValueBetween(min as number, max as number, value)
  }
}

const NumberInput: FC<Props> = ({
  title,
  id,
  disabled = false,
  value,
  onBlur,
  decimalSeparator = Separator.Decimal,
  thousandSeparator = Separator.Thousand,
  prefix = PrefixSign.NONE,
  min = MinMax.DefaultMinimum,
  max = MinMax.AbsoluteMaximum,
  className = '',
  isInteger = false,
  isValid,
  inputProps,
  allowNegativeValues = false,
  isFocused = false,
  setFocusedId,
  focusedId,
  error,
}) => {
  const [currentValue, setCurrentValue] = useState<number>(value)
  const [focused, setFocused] = useState<boolean>(isFocused as boolean)
  const classes = useStyles()
  const amountOfDecimals: number = isInteger ? 0 : 2
  const inputRef = useRef<HTMLInputElement>()

  const handleIsAllowed = (values: NumberFormatValues): boolean => {
    if (isValid) {
      return isValid(values.floatValue)
    }

    return true
  }

  const handleBlur = (event: ChangeEvent<HTMLInputElement>): void => {
    const extracted = extractFirstNumber(event.target.value, allowNegativeValues)

    if (onBlur && extracted !== currentValue) {
      let currentValue = extracted
      const isValid = defaultIsValidFunction(min, max)

      if (!!extracted && !isValid(extracted)) {
        if (extracted < min) {
          currentValue = Number(min)
        }

        if (extracted > max) {
          currentValue = Number(max)
        }
      }

      if (!currentValue) {
        currentValue = 0
      }

      setCurrentValue(currentValue)
      return onBlur(event)
    }
  }

  const getFormattedPrefix = (): string => {
    if (prefix) {
      return `${prefix} `
    }

    return ''
  }

  const selectAllText = () => {
    if (disabled) {
      return
    }

    setFocusedId(id)
  }

  useEffect(() => {
    setFocused(id === focusedId)
  }, [id, focusedId])

  useEffect(() => {
    if (focused) {
      const input = inputRef.current

      if (!input) {
        return
      }

      addCustomEventListener(input, 'select', selectEventInputContent)
      selectInputContent(input)
    }
  }, [focused])

  const NumberFormatCustom = ({ inputRef, ...other }: NumberFormatCustomProps): ReactNode => {
    return (
      <RoyalTooltip value={title} className={classes.tooltip}>
        <NumberFormat
          {...other}
          getInputRef={inputRef}
          onBlur={handleBlur}
          isAllowed={(values) => handleIsAllowed(values)}
          thousandSeparator={thousandSeparator}
          decimalSeparator={decimalSeparator}
          isNumericString
          prefix={getFormattedPrefix()}
          onFocus={selectAllText}
        />
      </RoyalTooltip>
    )
  }

  useEffect(() => {
    if (value < 0 && !allowNegativeValues) {
      return
    }

    setCurrentValue(value)
  }, [value, allowNegativeValues])

  return (
    <NumberInputDisplay
      error={error}
      value={currentValue}
      inputComponent={NumberFormatCustom as FC}
      inputProps={inputProps}
      disabled={disabled}
      className={className}
      amountOfDecimals={amountOfDecimals}
      inputRef={inputRef}
      id={id}
    />
  )
}

const mapStateToProps = (state: any) => ({
  focusedId: getFocusedId(state),
})

const mapDispatchToProps = {
  setFocusedId: actions.setFocusedId,
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberInput)
