import { LazyExoticComponent } from 'react'
import { get, has } from 'lodash'
import CellComponentDefinition from '../Model/Cell/CellComponentDefinition'
import { DEFAULT_VALUE_COMPONENT_INDEX, NO_VALUE_PLACEHOLDER } from '../Configuration/constants'
import { GetValueFunction } from '../Model/Column'
import ComponentOrType from '../Model/Cell/ComponentOrType'
import { MissingConfigurationError } from '../Error/Service/MissingConfigurationError'
import { FetchedValue } from '../Model/FetchedValue'
import { DisabledConditionally } from '@components/RoyalList/Model/DisabledConditionally'

class RoyalListService {
  constructor(private configuration: CellComponentDefinition[]) {}

  getCellComponent(componentOrType?: ComponentOrType): LazyExoticComponent<any> {
    // Cell's component is defined? Let's use it
    if (typeof componentOrType === 'object') {
      return componentOrType
    }

    // Try to find component for the given type of cell
    const index = this.configuration.findIndex((definition) =>
      componentOrType ? definition.type === componentOrType : -1,
    )

    // Oops, cannot determine the component. Let's use the default component.
    if (componentOrType === undefined || index < 0) {
      return this.getDefaultValueComponent()
    }

    return this.configuration[index].component
  }

  getTitle(
    source: Record<string, unknown>,
    valueAccessor: string | GetValueFunction,
    header: string,
    prefix?: string,
  ): string {
    const value = this.getValue(source, valueAccessor, prefix)

    if (header) {
      return `${header}: ${value}`
    }

    return value.toString()
  }

  getValue(source: Record<string, unknown>, valueAccessor: string | GetValueFunction, prefix?: string): FetchedValue {
    const value = RoyalListService.findValue(source, valueAccessor)

    if (value === null) {
      return NO_VALUE_PLACEHOLDER
    }

    return RoyalListService.wrap(value, prefix)
  }

  isHighlighted(source: Record<string, unknown>, highlightIfTruthy: string | GetValueFunction): boolean {
    return !!RoyalListService.findValue(source, highlightIfTruthy)
  }

  isDisabled(source: Record<string, unknown>, conditionally: DisabledConditionally): boolean {
    if (conditionally.disableIfTruthy) {
      return !!RoyalListService.findValue(source, conditionally.disableIfTruthy)
    }

    return false
  }

  private static findValue(
    source: Record<string, unknown>,
    valueAccessor: string | GetValueFunction,
  ): FetchedValue | null {
    if (valueAccessor instanceof Function) {
      return valueAccessor(source)
    }

    if (has(source, valueAccessor)) {
      return get(source, valueAccessor) as FetchedValue
    }

    return null
  }

  private static wrap(value: FetchedValue, prefix?: string): string | FetchedValue {
    if (prefix) {
      return `${prefix} ${value}`
    }

    return value
  }

  private getDefaultValueComponent(): LazyExoticComponent<any> {
    if (this.configuration.length === 0) {
      throw new MissingConfigurationError('Cannot find the default component, because no components were provided')
    }

    return this.configuration[DEFAULT_VALUE_COMPONENT_INDEX].component
  }
}

export default RoyalListService
