import { FieldType } from '@app/enums/form/FieldType'
import { AbstractFieldModel } from '@models/form/fields/FieldModels'

export class ListFieldModel extends AbstractFieldModel<FieldType.List, Record<string, any>[] | undefined> {
  private _listItemType?: FieldType = FieldType.List

  constructor() {
    super(FieldType.List)
  }

  get listItemType(): FieldType | undefined {
    return this._listItemType
  }

  set listItemType(value: FieldType | undefined) {
    this._listItemType = value
  }
}
