import { createMuiTheme, responsiveFontSizes } from '../components/MaterialUI/styles'
import * as overrides from './overrides'
import mixins from './mixins'
import palette from './palette'
import typography from './typography'
import shape from './shape'
import { Theme } from '@components/MaterialUI/theme'

const configureTheme = (): Theme => {
  const theme = createMuiTheme({
    overrides,
    mixins,
    palette,
    shape,
    typography,
  })

  theme.overrides = {
    ...overrides,
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'transparent',
        height: 'fit-value',
        width: 'fit-value',
      },
      tooltipPlacementRight: {
        margin: 0,
        [theme.breakpoints.up('sm')]: {
          margin: 0,
        },
      },
      tooltipPlacementLeft: {
        margin: 0,
        [theme.breakpoints.up('sm')]: {
          margin: 0,
        },
      },
      tooltipPlacementBottom: {
        margin: 0,
        [theme.breakpoints.up('sm')]: {
          margin: 0,
        },
      },
      tooltipPlacementTop: {
        margin: 0,
        [theme.breakpoints.up('sm')]: {
          margin: 0,
        },
      },
    },
  }

  return responsiveFontSizes(theme)
}

export default configureTheme
