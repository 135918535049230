import { createSlice } from '@reduxjs/toolkit'
import { CollectionSliceState } from '@features/@common/slices/collection/CollectionSliceState'
import { ColorModel } from '@models/entities/color/ColorModel'
import { CollectionSliceReducers } from '@features/@common/reducers/CollectionSliceReducers'
import { createCollectionInitialState } from '@features/@common/slices/collection/createCollectionInitialState'
import { fetchColors } from '@features/colors/redux/action'
import entityAdapter from '@features/colors/redux/adapter'

const initialState = createCollectionInitialState<ColorModel>(entityAdapter)

export const slice = createSlice<CollectionSliceState<ColorModel>, CollectionSliceReducers<ColorModel>>({
  name: 'colors.list',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchColors.pending.type]: (state) => {
      state.isLoading = true
      state.error = undefined
    },
    [fetchColors.fulfilled.type]: (state, action) => {
      state.isLoading = false
      entityAdapter.upsertMany(state, action.payload.data)
    },
    [fetchColors.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    },
  },
})
