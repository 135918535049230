import { createSlice } from '@reduxjs/toolkit'
import { entityAdapter } from '../adapter'
import readInitialState from '../initialState/readState'
import ReadSliceState from '../model/slice/ReadSliceState'
import ReadSliceReducer from '../model/slice/ReadSliceReducer'
import { readBrand } from '../action'

const readSlice = createSlice<ReadSliceState, ReadSliceReducer>({
  name: 'brand.readOne',
  initialState: readInitialState,
  reducers: {},
  extraReducers: {
    [readBrand.pending.type]: (state) => {
      state.isLoading = readInitialState.isLoading
      state.error = readInitialState.error
    },
    [readBrand.fulfilled.type]: (state, action) => {
      entityAdapter.upsertOne(state, action.payload)
      state.isLoading = false
      state.error = readInitialState.error
    },
    [readBrand.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default readSlice
