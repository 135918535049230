import { useTranslation } from '@utils/translation'
import appDetails from '../../../package.json'

const PARTS_SEPARATOR = ' - '

export const useAppTitle = (moduleTitle?: string): string => {
  const { t } = useTranslation()
  const { version } = appDetails
  const appName = t('global:app.name', { version })

  if (moduleTitle) {
    return [moduleTitle, appName].join(PARTS_SEPARATOR)
  }

  return appName
}
