import { createEntityAdapter } from '@reduxjs/toolkit'
import { ClientHasBrandConditionModel } from '@models/entities/clientOrders'

const selectId = (entity: ClientHasBrandConditionModel) => entity.id

const entityAdapter = createEntityAdapter<ClientHasBrandConditionModel>({
  selectId,
})

export { selectId, entityAdapter }
