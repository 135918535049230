import createApiAction from '@features/@common/actions/api-actions'
import * as api from '../api'
import { parsePaginatedCollection } from '@utils/api/data'

const readArticlePrices = createApiAction('prices.read', async (id: number) => {
  const response = await api.readPrices(id)
  return parsePaginatedCollection(response)
})

export { readArticlePrices }
