import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createGroupOrder } from '../action'
import { entityAdapter } from '../adapter'
import CreateSliceState from '../model/slice/CreateSliceState'
import CreateSliceReducer from '../model/slice/CreateSliceReducer'
import createInitialState from '../initialState/createState'
import { GroupOrderModel } from '@models/entities/groupOrder/GroupOrderModel'

const createGroupOrderSlice = createSlice<CreateSliceState, CreateSliceReducer>({
  name: 'groupOrders_create',
  initialState: createInitialState,
  reducers: {},
  extraReducers: {
    [createGroupOrder.pending.type]: (state) => {
      state.isLoading = true
      state.error = createInitialState.error
      state.success = createInitialState.success
    },
    [createGroupOrder.fulfilled.type]: (state, action: PayloadAction<GroupOrderModel>) => {
      state.isLoading = false
      state.success = action.payload
      entityAdapter.upsertOne(state, action.payload)
    },
    [createGroupOrder.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload.errors
      state.success = createInitialState.success
    },
  },
})

export default createGroupOrderSlice
