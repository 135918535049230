import { useCallback } from 'react'
import { debounce } from 'lodash'
import { useHistory } from 'react-router-dom'
import useUrlParam from './use-url-param'
import { UrlParameter } from '@config/sorting'
import { AnyFunction } from '@models/function/AnyFunction'

const DELAY = 500

export const useSearchHandler = () => {
  const history = useHistory()
  const [filters, getSearchRaw] = useUrlParam(UrlParameter.Filtering)

  const handler = debounce((search: string) => {
    const allFilters = {
      ...filters,
      search: search.trim(),
    }

    history.push({
      search: getSearchRaw(allFilters),
    })
  }, DELAY)

  return useCallback(handler as AnyFunction, [history, getSearchRaw, filters])
}
