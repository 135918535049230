import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { reducer as formReducer } from 'redux-form'
import { Action } from 'redux'
import tokenMiddleware from './middleware/token-middleware'
import auth from '@features/auth/reducer'
import { logout } from '@features/auth/action'
import suppliers from '@features/suppliers/redux/reducer'
import brands from '@features/brands/redux/reducer'
import clientOrders from '@features/clientOrders/redux/reducer'
import articles from '@features/articles/redux/reducer'
import groupOrders from '@features/groupOrders/redux/reducer'
import deliveryAddresses from '@features/deliveryAddresses/redux/reducer'
import clientOrderItems from '@features/clientOrderItems/redux/reducer'
import clients from '@features/clients/redux/reducer'
import emailAddressReducer from '@features/emailAddresses/redux/reducer'
import selectObjectReducer from '@features/selectObject/redux/reducer'
import focusedInputReducer from '@features/focusedInput/redux/reducer'
import { actions as selectObjectActions } from '@features/selectObject/redux/slice'
import articlePrices from '@features/articlePrices/redux/reducer'
import brandConditions from '@features/brandConditions/redux/reducer'
import clientHasBrandConditions from '@features/clientHasBrandConditions/redux/reducer'
import categories from '@features/categories/redux/reducer'
import colors from '@features/colors/redux/reducer'
import attributes from '@features/attributes/redux/reducer'
import mediaObjects from '@features/mediaObjects/redux/reducer'
import uniqueSellingPoints from '@features/uniqueSellingPoints/redux/reducer'
import clientOrderTotals from '@features/clientOrderTotals/redux/reducer'
import clientOrderAdditionalFees from '@features/clientOrderAdditionalFees/redux/reducer'

const reducer = combineReducers({
  form: formReducer,
  auth,
  suppliers,
  clientOrders,
  brands,
  clientOrderItems,
  emailAddresses: emailAddressReducer,
  selectedObject: selectObjectReducer,
  focusedInput: focusedInputReducer,
  deliveryAddresses,
  articles,
  clients,
  groupOrders,
  articlePrices,
  brandConditions,
  clientHasBrandConditions,
  colors,
  mediaObjects,
  categories,
  attributes,
  uniqueSellingPoints,
  clientOrderTotals,
  clientOrderAdditionalFees,
})

const rootReducer = (state: any, action: Action<string>) => {
  if (action.type === logout.fulfilled.type) {
    state = undefined
  }

  return reducer(state, action)
}

export const createStore = () => {
  const middlewareOptions = {
    serializableCheck: {
      // Required to avoid "A non-serializable value was detected in the state" error.
      // More: https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
      ignoredActions: [selectObjectActions.showDetails.type],
    },
  }

  return configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware(middlewareOptions), tokenMiddleware],
  })
}

export const store = createStore()
