import http from '../../utils/http'
import { DeliveryAddressModel } from '@models/entities/addresses/DeliveryAddressModel'
import { generateUrl } from '@utils/route'

const DELIVERY_ADDRESSES_API_URL = '/api/delivery_addresses'
const DELIVERY_ADDRESS_API_URL = '/api/clients/:id(\\d+)/delivery_addresses'
const DELIVERY_ADDRESS_UPDATE_API_URL = '/api/delivery_addresses/:id(\\d+)'

interface Props {
  id: number
  delivery_address: DeliveryAddressModel
}

const fetchDeliveryAddresses = async () => {
  const url = generateUrl(DELIVERY_ADDRESSES_API_URL)

  return http.get(url, {
    params: {
      include: ['address', 'client', 'phoneNumber'],
    },
  })
}

const readDeliveryAddresses = async (clientId: number) => {
  const url = generateUrl(DELIVERY_ADDRESS_API_URL, { id: clientId })

  return http.get(url, {
    params: {
      include: ['address', 'client', 'phoneNumber'],
    },
  })
}

const createDeliveryAddress = async (delivery_address: DeliveryAddressModel) => {
  return http.post(DELIVERY_ADDRESSES_API_URL, delivery_address)
}

const updateDeliveryAddress = async ({ id, delivery_address }: Props) => {
  const url = generateUrl(DELIVERY_ADDRESS_UPDATE_API_URL, { id })

  return http.put(url, delivery_address)
}

export { fetchDeliveryAddresses, readDeliveryAddresses, createDeliveryAddress, updateDeliveryAddress }
