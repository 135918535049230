import { CSSProperties } from '@material-ui/core/styles/withStyles'

const noArrowsInputStyle: CSSProperties = {
  '-moz-appearance': 'textfield',

  '&::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
  },
}

export { noArrowsInputStyle }
