import { AsyncThunk, AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { StatusCodes } from 'http-status-codes'
import {
  HttpStatusParserMap,
  Response,
  ResponseErrorsParserResult,
  ResponseParserResult,
  Violation,
} from '@models/api/response'

export const parseServerError = (
  responseData: Record<string, unknown>,
  status = StatusCodes.INTERNAL_SERVER_ERROR,
): ResponseParserResult => {
  // trans('errors:unexpected')
  let message = 'errors:unexpected'
  const description = responseData['hydra:description']

  if (description) {
    message = description as string
  }

  return {
    status,
    message,
  }
}

export const parseAccessDeniedError = (
  responseData: Record<string, unknown>,
  status = StatusCodes.FORBIDDEN,
): ResponseParserResult => {
  // trans('errors:access_denied')
  const message = 'errors:access_denied'

  return {
    status,
    message,
  }
}

export const parsePageNotFound = (
  responseData: Record<string, unknown>,
  status = StatusCodes.NOT_FOUND,
): ResponseParserResult => {
  // trans('errors:not_found')
  const message = 'errors:not_found'

  return {
    status,
    message,
  }
}

const parseValidationErrors = (
  responseData: Record<string, unknown>,
): ResponseParserResult | ResponseErrorsParserResult => {
  const violations: Violation[] = responseData.violations as Violation[]

  if (!violations) {
    return parseServerError(responseData, StatusCodes.BAD_REQUEST)
  }

  const errors: Record<string, unknown> = violations.reduce(
    (parsedErrors: Record<string, unknown>, violation: Violation) => {
      const result: Record<string, unknown> = parsedErrors
      result[violation.propertyPath] = violation.message

      return result
    },
    {},
  )

  const result = {
    status: StatusCodes.BAD_REQUEST,
    errors,
  }

  return result as ResponseErrorsParserResult
}

const parseUnauthorized = (responseData: Record<string, unknown>): ResponseParserResult => ({
  status: StatusCodes.UNAUTHORIZED,
  message: responseData.message as string,
})

const mapErrorToPayload: HttpStatusParserMap = {
  [StatusCodes.BAD_REQUEST]: parseValidationErrors,
  [StatusCodes.UNAUTHORIZED]: parseUnauthorized,
  [StatusCodes.FORBIDDEN]: parseAccessDeniedError,
  [StatusCodes.NOT_FOUND]: parsePageNotFound,
  [StatusCodes.NOT_ACCEPTABLE]: parseValidationErrors,
  [StatusCodes.UNPROCESSABLE_ENTITY]: parseValidationErrors,
  [StatusCodes.INTERNAL_SERVER_ERROR]: parseServerError,
}

const wrapPayloadCreator =
  <Returned, ThunkArg, ThunkApiConfig>(creator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>) =>
  async (args: any, thunk: any) => {
    try {
      return await creator(args, thunk)
    } catch (error) {
      const { response }: { response: Response } = error
      const transform = mapErrorToPayload[response.status] ?? null

      if (response && response.status && !!transform) {
        return thunk.rejectWithValue(transform(response.data))
      }

      if (!response && error.message === 'Network Error') {
        return thunk.rejectWithValue({
          status: StatusCodes.INTERNAL_SERVER_ERROR,
          message: 'errors:network_error', // trans('errors:network_error')
        })
      }

      throw error
    }
  }

const createApiAction = <Returned, ThunkArg, ThunkApiConfig>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
): AsyncThunk<Returned, ThunkArg, ThunkApiConfig> => {
  return createAsyncThunk<Returned, ThunkArg, ThunkApiConfig>(typePrefix, wrapPayloadCreator(payloadCreator))
}

export default createApiAction
