import { KeyboardEvent } from 'react'
import { KeyboardKey } from '@models/events/KeyboardKey'
import { ClickEventType } from '@models/events/ClickEventType'
import { ClickEvent } from '@models/events/ClickEvent'

const isKeyboardEvent = (event: ClickEvent): boolean => {
  return event.type === ClickEventType.KEY_DOWN
}

const isClickEvent = (event: ClickEvent): boolean => {
  return event.type === ClickEventType.CLICK
}

const isKeyboardEnterEvent = (event: ClickEvent): boolean => {
  return (event as KeyboardEvent).key === KeyboardKey.ENTER
}

export const executeClickEvent = (event: ClickEvent, action: (param?: any) => void) => {
  if (isClickEvent(event)) {
    return action()
  }

  if (isKeyboardEvent(event) && isKeyboardEnterEvent(event)) {
    return action()
  }
}
