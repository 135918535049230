import createApiAction from '@features/@common/actions/api-actions'
import { parsePaginatedCollection } from '@utils/api/data'
import * as api from '../api'
import { FetchBrandParams, UpdateBrandParameters, UpdateParameters } from '../api'

const fetchBrands = createApiAction<any, FetchBrandParams, any>(
  'brands.fetch',
  async (parameters: FetchBrandParams) => {
    const response = await api.fetchBrands(parameters)
    return parsePaginatedCollection(response)
  },
)

const readBrand = createApiAction<any, number, any>('brands.read', api.readBrand)
const updateBrand = createApiAction<any, UpdateBrandParameters, any>('brands.update', api.updateBrand)

const updateBrandConditionItem = createApiAction<any, UpdateParameters, any>(
  'brands.update',
  api.updateBrandConditionItem,
)

export { fetchBrands, readBrand, updateBrandConditionItem, updateBrand }
