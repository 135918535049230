import { createSlice } from '@reduxjs/toolkit'
import { deleteClientHasBrandCondition } from '../action'
import DeleteSliceState from '@features/clientHasBrandConditions/redux/model/slice/DeleteSliceState'
import DeleteSliceReducer from '@features/clientHasBrandConditions/redux/model/slice/DeleteSliceReducer'
import { entityAdapter } from '@features/clientHasBrandConditions/redux/adapter'
import deleteInitialState from '@features/clientHasBrandConditions/redux/initialState/deleteState'

const deleteClientHasBrandConditionSlice = createSlice<DeleteSliceState, DeleteSliceReducer>({
  name: 'clientHasBrandConditions.delete',
  initialState: deleteInitialState,
  reducers: {},
  extraReducers: {
    [deleteClientHasBrandCondition.pending.type]: (state) => {
      state.isLoading = true
      state.deleteSuccess = false
      state.deletedId = deleteInitialState.deletedId
      state.error = deleteInitialState.error
    },
    [deleteClientHasBrandCondition.fulfilled.type]: (state, action: any) => {
      state.deletedId = action.meta.arg
      state.deleteSuccess = true
      entityAdapter.removeOne(state, action.meta.arg)
      state.isLoading = false
      state.error = deleteInitialState.error
    },
    [deleteClientHasBrandCondition.rejected.type]: (state, action) => {
      state.isLoading = false
      state.deleteSuccess = false
      state.error = action
      state.deletedId = deleteInitialState.deletedId
    },
  },
})

export default deleteClientHasBrandConditionSlice
