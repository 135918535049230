enum Size {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Original = 'original',
}

enum Variant {
  Signet,
  Full,
}

enum Color {
  Red,
  White,
}

export { Size, Variant, Color }
