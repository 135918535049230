import { createSlice } from '@reduxjs/toolkit'
import { readArticleBelongsTo } from '../action'
import { entityAdapter } from '../adapter'
import BelongsToSliceState from '../model/slice/BelongsToSliceState'
import BelongsToReducer from '../model/slice/BelongsToReducer'
import belongsToInitialState from '../initialState/belongsToState'

const belongsToSlice = createSlice<BelongsToSliceState, BelongsToReducer>({
  name: 'articles.readMany.belongsTo',
  initialState: belongsToInitialState,
  reducers: {},
  extraReducers: {
    [readArticleBelongsTo.pending.type]: (state) => {
      state.isLoading = belongsToInitialState.isLoading
      entityAdapter.removeAll(state)
      state.error = belongsToInitialState.error
    },
    [readArticleBelongsTo.fulfilled.type]: (state, action) => {
      entityAdapter.upsertMany(state, action.payload.data)
      state.isLoading = false
    },
    [readArticleBelongsTo.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default belongsToSlice
