enum CellType {
  DisplayText,
  DisplayBoolean,
  DisplayCheckbox,
  DisplayStatus,
  DisplayDate,
  DisplayEmail,
  DisplayPhone,
  DisplayIsClosed,
  DisplayIcon,
  DisplayNumber,
  EditText,
  EditCheckbox,
  EditDate,
  EditNumber,
  Actions,
  EditSelect,
}

export default CellType
