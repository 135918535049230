import { createEntityAdapter } from '@reduxjs/toolkit'
import { BrandModel } from '../../../app/models/entities/brands/BrandModel'

const selectId = (entity: BrandModel) => entity.id

const entityAdapter = createEntityAdapter<BrandModel>({
  selectId,
})

export { selectId, entityAdapter }
