import http from '@utils/http'
import { ArticleDimensionsModel, ArticleModel } from '@models/entities/articles'
import { generateUrl } from '@utils/route'
import { GetItemsPaginatedParameters, UpdateParameters } from '@models/api/request'
import { Url } from '@features/articles/api/model/Url'
import { ArticleInclude } from '@features/articles/api/model/ArticleInclude'
import readArticleIncludes from '@features/articles/api/config/readArticleIncludes'
import { DescriptionModel } from '@models/entities/description/DescriptionModel'

interface UpdateArticleParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    article: ArticleModel
  }
}

interface FetchParameters extends Omit<GetItemsPaginatedParameters, 'extra'> {
  extra: {
    articleNumber?: string
  }
}

interface UpdateDimensionParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    dimensions?: ArticleDimensionsModel
  }
}

interface UpdateCategoryAndAttributesParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    category: string
    attributeValues?: string[]
  }
}

interface UpdateImageParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    images?: string[]
  }
}

interface UpdateArticleColorAndDescription extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    color?: string
    translations: DescriptionModel[]
  }
}

interface UpdateUniqueSellingPointsParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    uniqueSellingPoints: string[]
  }
}

interface UpdateArticleNoteParameters extends Omit<UpdateParameters, 'changed'> {
  id: number
  changed: {
    notes: string
  }
}

interface FetchParametersWithFilter extends Omit<GetItemsPaginatedParameters, 'extra'> {
  extra: {
    'brand.name': string
    'category.translations.name': string
    'category.parent.translations.name': string
    priceListDate: string
    articleNumber: string
    typeNumber: string
  }
}

const fetchArticles = async (parameters: FetchParameters) => {
  const url = generateUrl(Url.Articles)

  return http.get(url, {
    headers: parameters.headers,
    params: {
      ...parameters.pagination,
      ...parameters.sorting,
      ...parameters.extra,
    },
  })
}

const fetchArticlesForListWithBrandAndCategory = async (parameters: FetchParametersWithFilter) => {
  return http.get(Url.Articles, {
    params: {
      ...parameters.pagination,
      ...parameters.sorting,
      ...parameters.extra,
      include: [ArticleInclude.Brand, ArticleInclude.Category, ArticleInclude.Prices],
    },
  })
}

const readArticle = async (id: number) => {
  const url = generateUrl(Url.Details, { id })

  return http.get(url, {
    params: {
      include: readArticleIncludes,
    },
  })
}

const readArticlesFromBrands = async (id: number) => {
  const url = generateUrl(Url.ArticlesByBrand, { id })

  return http.get(url)
}

const readArticleDownSellers = async (id: number) => {
  const url = generateUrl(Url.DownSellers, { id })

  return http.get(url)
}

const readArticleUpSellers = async (id: number) => {
  const url = generateUrl(Url.UpSellers, { id })

  return http.get(url)
}

const readArticleAccessories = async (id: number) => {
  const url = generateUrl(Url.Accessories, { id })

  return http.get(url)
}

const readArticleBelongsTo = async (id: number) => {
  const url = generateUrl(Url.BelongsTo, { id })

  return http.get(url)
}

const readArticlesFromCategories = async (id: number) => {
  const url = generateUrl(Url.ArticlesByCategory, { id })

  return http.get(url)
}

// todo implement after api has required fields
const createArticle = async (article: ArticleModel) => {
  return http.post(Url.Articles, article)
}

const updateArticle = async (parameters: UpdateArticleParameters) => {
  const url = generateUrl(Url.Update, { id: parameters.id })

  return http.put(url, parameters.changed)
}

const updateArticleDimensions = async (parameters: UpdateDimensionParameters) => {
  const url = generateUrl(Url.Update, { id: parameters.id })
  return http.patch(url, parameters.changed)
}

const updateArticleCategoryAndAttributes = async (parameters: UpdateCategoryAndAttributesParameters) => {
  const url = generateUrl(Url.Update, { id: parameters.id })
  return http.patch(url, parameters.changed)
}

const updateArticleImages = async (parameters: UpdateImageParameters) => {
  const url = generateUrl(Url.Update, { id: parameters.id })
  return http.patch(url, parameters.changed)
}

const updateArticleColorAndDescription = async (parameters: UpdateArticleColorAndDescription) => {
  const url = generateUrl(Url.Update, { id: parameters.id })
  return http.patch(url, parameters.changed)
}

const updateArticleUniqueSellingPoints = async (parameters: UpdateUniqueSellingPointsParameters) => {
  const url = generateUrl(Url.Update, { id: parameters.id })
  return http.patch(url, parameters.changed)
}

const updateArticleNotes = async (parameters: UpdateArticleNoteParameters) => {
  const url = generateUrl(Url.Update, { id: parameters.id })
  return http.patch(url, parameters.changed)
}

export type {
  UpdateArticleParameters,
  FetchParameters,
  FetchParametersWithFilter,
  UpdateDimensionParameters,
  UpdateCategoryAndAttributesParameters,
  UpdateArticleColorAndDescription,
  UpdateImageParameters,
  UpdateUniqueSellingPointsParameters,
  UpdateArticleNoteParameters,
}

export {
  fetchArticles,
  fetchArticlesForListWithBrandAndCategory,
  readArticle,
  readArticlesFromBrands,
  readArticlesFromCategories,
  readArticleDownSellers,
  readArticleUpSellers,
  readArticleAccessories,
  readArticleBelongsTo,
  createArticle,
  updateArticle,
  updateArticleDimensions,
  updateArticleCategoryAndAttributes,
  updateArticleColorAndDescription,
  updateArticleImages,
  updateArticleUniqueSellingPoints,
  updateArticleNotes,
}
