import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react'
import { ImageDimension } from '@config/upload'
import { makeStyles } from '@components/MaterialUI/styles'
import { Theme } from '@components/MaterialUI/theme'
import { InputLabel } from '@components/MaterialUI/core'
import FileUploadButton from '@components/MediaUpload/components/FileUploadButton'
import { useTranslation } from '@utils/translation'
import moment from 'moment'
import { formatDate } from '@utils/date'
import { DateFormat } from '@config/dateFormat'
import { v4 } from 'uuid'
import { UploadType } from '@components/MediaUpload/config/UploadType'
import { Padding } from '@theme/styles/padding'
import { getFirstUploadedFile } from '@utils/upload'

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    height: '100px',
    width: 'auto',
  },
  error: {
    color: theme.palette.primary.main,
    margin: `${Padding.Small} 0`,
  },
}))

interface UploadProps {
  onUpload: (file?: File) => void
}

const ImageUpload: FC<UploadProps> = ({ onUpload }) => {
  const [selectedImage, setSelectedImage] = useState<undefined | File>()
  const [imageURI, setImageURI] = useState<undefined | string>()
  const [errors, setErrors] = useState<undefined | string[]>([])
  const [height, setHeight] = useState<number>()
  const [width, setWidth] = useState<number>()
  const classes = useStyles()
  const { t } = useTranslation()
  const reader = new FileReader()

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const uploadedFile = getFirstUploadedFile(event.target.files)

      if (!uploadedFile) {
        return
      }

      setImage(uploadedFile)
    }
  }

  const validateDimensions = (height: number, width: number): string[] => {
    const errors: string[] = []

    if (height > ImageDimension.Height) {
      errors.push(t('upload:errors.heightExceeded', { height: height, maxHeight: ImageDimension.Height }))
    }

    if (width > ImageDimension.Width) {
      errors.push(t('upload:errors.widthExceeded', { width: width, maxWidth: ImageDimension.Width }))
    }

    return errors
  }

  const setImage = (file: File) => {
    if (file) {
      reader.readAsDataURL(file)

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const image = new Image()

        if (event.target && event.target.result) {
          image.onload = () => {
            const imageErrors = validateDimensions(image.height, image.width)
            setErrors(imageErrors)

            if (!imageErrors.length) {
              setHeight(image.height)
              setWidth(image.width)
              setSelectedImage(file)
            }
          }

          const source = event.target.result as string
          image.src = source
          setImageURI(source)
        }
      }
    }
  }

  const createImageTag = (imageUri: string, selectedImage: File): ReactNode => {
    return (
      <div className={classes.image}>
        <img className={classes.image} src={imageURI} alt={selectedImage.name} />
      </div>
    )
  }

  const getFileData = (selectedFile: File): ReactNode => {
    const date = new Date(selectedFile.lastModified)

    return (
      <div>
        <h2>{t('upload:file.fileDetails')}</h2>
        <p>
          {t('upload:file.fileName')}: {selectedFile.name}
        </p>
        <p>
          {t('upload:file.fileType')}: {selectedFile.type}
        </p>
        <p>
          {t('upload:file.dimensions')}: ({height}px) x ({width}px)
        </p>
        <p>
          {t('upload:file.lastModified')}:{formatDate(moment(date), DateFormat.WithoutTime)}
        </p>
      </div>
    )
  }

  const renderErrors = (errors?: string[]): ReactNode => {
    if (errors && errors.length) {
      return errors.map((error: string) => {
        return (
          <InputLabel key={v4()} className={classes.error}>
            {error}
          </InputLabel>
        )
      })
    }
  }

  const getUploadInfo = (): ReactNode => {
    return <InputLabel>{t('upload:actions:chooseImage')}</InputLabel>
  }

  const renderFileInfo = (): ReactNode => {
    if (selectedImage) {
      return getFileData(selectedImage)
    }

    return getUploadInfo()
  }

  useEffect(() => {
    if (errors && errors.length) {
      setSelectedImage(undefined)
    }
  }, [errors])

  useEffect(() => {
    if (selectedImage) {
      setErrors([])
    }

    onUpload(selectedImage)
  }, [selectedImage, onUpload])

  return (
    <div>
      {selectedImage && imageURI && createImageTag(imageURI, selectedImage)}
      {renderFileInfo()}

      <FileUploadButton
        onFileChange={onFileChange}
        tooltipTitle={t('upload:actions:chooseImage')}
        uploadType={UploadType.Image}
      />

      {renderErrors(errors)}
    </div>
  )
}

export default ImageUpload
