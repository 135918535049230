import React, { Component, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { SnackbarProvider } from 'notistack'
import authStorage from '@features/auth/storage'
import * as actions from '@features/auth/action'
import { Loader, LocaleUpdater } from './components'
import Router from './app/Router'
import { ThemeProvider } from '@components/MaterialUI/styles'
import { CssBaseline } from '@components/MaterialUI/core'
import configureTheme from '@theme/index'
import './config/moment'

const App = ({ fetchCurrentUser, isBooting, authContext }) => {
  const authData = useRef(authStorage.getData())
  const theme = configureTheme()

  useEffect(() => {
    if (authData.current) {
      fetchCurrentUser()
    }
  }, [fetchCurrentUser])

  const generateAuthContextKey = ({ client }) => {
    if (client) {
      return `client_context_${client.id}`
    }

    return 'client_context_default'
  }

  return (
    <div key={generateAuthContextKey(authContext)}>
      <CssBaseline />
      <LocaleUpdater />
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={theme}>{isBooting ? <Loader /> : <Router />}</ThemeProvider>
      </SnackbarProvider>
    </div>
  )
}

App.propTypes = {
  authContext: PropTypes.object.isRequired,
  isBooting: PropTypes.bool.isRequired,
  fetchCurrentUser: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  authContext: state.auth.context,
  isBooting: state.auth.main.isBooting,
})

const mapDispatchToProps = {
  fetchCurrentUser: actions.fetchCurrentUser,
}

const Application = connect(mapStateToProps, mapDispatchToProps)(App)

class AppWithErrorHandler extends Component {
  componentDidCatch(error) {
    switch (error.name) {
      case 'ChunkLoadError':
        if (window) {
          // when chunk cannot be found (usually after deployment new version)
          // then refresh the page.
          window.location.reload()
        }
        break
      default:
        throw error
    }
  }

  render() {
    return <Application />
  }
}

export default AppWithErrorHandler
