export enum Url {
  Articles = '/api/articles',
  ArticlesByBrand = '/api/brands/:id(\\d+)/articles',
  ArticlesByCategory = '/api/categories/:id(\\d+)/articles',
  Details = '/api/articles/:id(\\d+)',
  Update = '/api/articles/:id(\\d+)',
  DownSellers = '/api/articles/:id(\\d+)/down_sellers',
  UpSellers = '/api/articles/:id(\\d+)/up_sellers',
  Accessories = '/api/articles/:id(\\d+)/accessories',
  BelongsTo = '/api/articles/:id(\\d+)/belongs_tos',
}
