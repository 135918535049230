const BORDER_RADIUS = 4
const HTML_FONT_SIZE = 14
const FONT_SIZE = 10
const SMALL_FONT_SIZE = '0.75rem'
const FONT_WEIGHT_BOLD = 700
const LARGE_BORDER_RADIUS = 4 * BORDER_RADIUS
const MARGIN = '1rem'
const NAV_ITEM_SIZE = '60px'
const PADDING = '12px'

export {
  BORDER_RADIUS,
  LARGE_BORDER_RADIUS,
  HTML_FONT_SIZE,
  FONT_SIZE,
  SMALL_FONT_SIZE,
  FONT_WEIGHT_BOLD,
  MARGIN,
  NAV_ITEM_SIZE,
  PADDING,
}
