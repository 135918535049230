import { createSlice } from '@reduxjs/toolkit'
import { fetchGroupOrders } from '../action'
import { entityAdapter } from '../adapter'
import MainSliceState from '../model/slice/MainSliceState'
import MainSliceReducer from '../model/slice/MainSliceReducer'
import mainInitialState from '../initialState/mainState'

const mainSlice = createSlice<MainSliceState, MainSliceReducer>({
  name: 'groupOrders',
  initialState: mainInitialState,
  reducers: {},
  extraReducers: {
    [fetchGroupOrders.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchGroupOrders.fulfilled.type]: (state, action) => {
      state.isLoading = false
      entityAdapter.upsertMany(state, action.payload.data)
    },
    [fetchGroupOrders.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default mainSlice
