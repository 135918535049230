import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { FilterOptionsState } from '@material-ui/lab'
import { AutocompleteChangeReason, Value } from '@material-ui/lab/useAutocomplete/useAutocomplete'
import { TextFieldVariant } from '@models/form/TextFieldVariant'
import { createFilterOptions } from '@components/MaterialUI/lab'
import Loader from '../Loader'

export type Multiple = boolean | undefined
export type DisableClearable = boolean | undefined
export type FreeSolo = boolean | undefined

interface Props<ItemType> {
  label: string
  items: ItemType[]
  changeFilter: (inputValue: string) => any
  filterVariant?: TextFieldVariant
  getItemLabel: (option: ItemType) => string
  onClear?: (value: Value<ItemType, Multiple, DisableClearable, FreeSolo>, reason: AutocompleteChangeReason) => void
  onChange?: (value: Value<ItemType, Multiple, DisableClearable, FreeSolo>, reason: AutocompleteChangeReason) => void
  isItemSelected: (option: ItemType, value: ItemType) => boolean
  defaultItemSelected?: ItemType
  loading?: boolean
}

export const AutocompleteDropdown = <ItemType extends Record<string, any>>({
  label,
  items,
  changeFilter,
  filterVariant = TextFieldVariant.Outlined,
  getItemLabel,
  isItemSelected,
  onChange,
  onClear,
  defaultItemSelected,
  loading = false,
}: Props<ItemType>): ReactElement => {
  const [options, setOptions] = useState<ItemType[]>(items)
  const [inputValue, setInputValue] = useState<string>('')
  const [valueWasCleared, setValueWasCleared] = useState<boolean>(false)
  const filter = createFilterOptions<ItemType>()
  const filterSelectedOptions = options.findIndex((item) => getItemLabel(item) === inputValue) > -1

  const handleFilterOption = (options: ItemType[], params: FilterOptionsState<ItemType>) => {
    const values: ItemType[] = filter(options, params)

    // when there is a default item selected, in order to trigger
    if (defaultItemSelected && onClear) {
      onClear(null, 'clear')
    }

    changeFilter(params.inputValue)

    return values
  }

  const handleChange = (
    event: ChangeEvent<Record<string, unknown>>,
    value: Value<ItemType, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason === 'clear' && !!onClear) {
      onClear(null, reason)
      return
    }

    if (onChange) {
      onChange(value, reason)
    }
  }

  const handleInputChange = (event: ChangeEvent<Record<string, unknown>>, newInputValue: string) => {
    if (newInputValue === '') {
      setValueWasCleared(true)
    }

    setInputValue(newInputValue)
  }

  useEffect(() => {
    if (inputValue === '') {
      setOptions(items)

      if (valueWasCleared && !!onClear) {
        onClear(null, 'clear')
      }

      setValueWasCleared(false)
    }
  }, [items, inputValue, valueWasCleared, onClear])

  useEffect(() => {
    if (!loading) {
      setOptions(items)
    }
  }, [loading, items])

  useEffect(() => {
    if (!loading && items && onChange && defaultItemSelected) {
      onChange(defaultItemSelected, 'select-option')
    }
  }, [loading, items, defaultItemSelected, onChange])

  if (loading && inputValue === '') {
    return <Loader />
  }

  return (
    <Autocomplete
      getOptionLabel={getItemLabel}
      getOptionSelected={isItemSelected}
      filterOptions={handleFilterOption}
      options={options}
      autoComplete
      freeSolo
      selectOnFocus
      defaultValue={defaultItemSelected}
      loading={loading}
      onChange={handleChange}
      filterSelectedOptions={filterSelectedOptions}
      onInputChange={handleInputChange}
      renderInput={(params) => <TextField {...params} label={label} variant={filterVariant} />}
    />
  )
}
