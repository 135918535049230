import React, { FC } from 'react'
import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '../../MaterialUI/styles'
import { cellStyle, headerLinkStyle } from '../Styles/royal-list-styles'
import { GetValueFunction } from '../Model/Column'
import { SMALL_FONT_SIZE } from '@theme/constants'
import { Link } from '../../MaterialUI/core'
import { useSortingUrl } from '@features/@common'
import HeaderSortingArrow from './HeaderSortingArrow'
import Alignment from '@models/Alignment'
import useAlignmentClasses from '../Styles/use-alignment-classes'

const useStyles = makeStyles(() => ({
  cell: {
    ...cellStyle,
    border: '0 !important',
    backgroundColor: 'inherit !important',
    fontSize: `${SMALL_FONT_SIZE} !important`,
  },
  link: {
    ...headerLinkStyle,
  },
}))

type Props = {
  name: string
  className?: string
  abbreviation?: string | GetValueFunction
  sortBy?: string
  alignment?: Alignment
}

const HeaderCell: FC<Props> = ({ className, name, abbreviation, sortBy, alignment = Alignment.Left }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { sortingUrl, sortingParameter } = useSortingUrl(sortBy)

  const getName = (): string => {
    if (abbreviation instanceof Function) {
      return name
    }

    return abbreviation as string
  }

  const label = getName() ?? name
  const nameTranslated = t(name)

  const wrapperClasses = clsx(className, classes.cell, useAlignmentClasses(alignment))

  return (
    <div className={wrapperClasses} title={t(label)}>
      {isEmpty(sortBy) || sortingUrl === null ? (
        nameTranslated
      ) : (
        <Link component={NavLink} to={sortingUrl} className={classes.link}>
          <span>{nameTranslated}</span>
          <HeaderSortingArrow sortBy={sortBy} sortingParameter={sortingParameter} />
        </Link>
      )}
    </div>
  )
}

export default HeaderCell
