import { AbstractFieldModel } from '@models/form/fields/FieldModels'
import { FieldType } from '@app/enums/form/FieldType'
import { slugifyNoLowerCase } from '@utils/string'

export const getInputName = (formField: AbstractFieldModel<FieldType, any>, sectionLabel?: string): string => {
  let name: string = formField.fieldName

  if (sectionLabel) {
    name = `${sectionLabel} ${formField.fieldName}`
  }

  return slugifyNoLowerCase(name)
}
