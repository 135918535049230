import { entityAdapter } from '../adapter'
import AccessoriesSliceState from '../model/slice/AccessoriesSliceState'

const accessoriesInitialState: AccessoriesSliceState = {
  ...entityAdapter.getInitialState(),
  isLoading: true,
  error: undefined,
}

export default accessoriesInitialState
