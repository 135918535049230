import React, { FC, Fragment, ReactNode, useState } from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { Theme } from '../../../../components/MaterialUI/theme'
import { ListItemIcon } from '../../../../components/MaterialUI/icons'
import { makeStyles } from '../../../../components/MaterialUI/styles'
import { DASHBOARD_PATH } from '../../../../config/routes/paths'
import { NavigationItemModel } from '../../../../app/models/navigation/NavigationItemModel'
import { RoyalTooltip } from '../../../../components'
import { TooltipDirection } from '../../../../components/RoyalTooltip/config'
import { Collapse, Divider, ListItem, ListItemText } from '../../../../components/MaterialUI/core'
import NavListOneLevel from './NavListOneLevel'

type Props = {
  route: string
  title: string
  icon: any
  childItems: NavigationItemModel[]
  level: number
  isOpen: boolean
}

const useStyles = (level: number) =>
  makeStyles((theme: Theme) => ({
    item: {
      padding: theme.spacing(2),
      paddingLeft: level * theme.spacing(2),
      display: 'flex',
    },
    tooltip: {
      display: 'inline-flex',
    },
  }))

const NavListItem: FC<Props> = ({ route, title, icon: Icon, childItems = [], level = 1, isOpen }) => {
  const matched = useRouteMatch({ path: route })
  const [open, setOpen] = useState(matched !== null)
  const classes = useStyles(level)()

  const isSelected = (): boolean => {
    if (childItems && childItems.length > 0) {
      return false
    }

    return !!matched && route.startsWith(matched.path) && matched.path !== DASHBOARD_PATH
  }

  const handleClick = () => {
    if (childItems && childItems.length > 0) {
      setOpen(!open)
    }
  }

  const iconItem = (
    <ListItemIcon>
      <Icon />
    </ListItemIcon>
  )

  const iconWithTooltip = (
    <RoyalTooltip value={title} direction={TooltipDirection.RIGHT}>
      {iconItem}
    </RoyalTooltip>
  )

  const renderIconItem = (): ReactNode => {
    if (Icon) {
      if (isOpen) {
        return iconItem
      }

      return iconWithTooltip
    }

    return <></>
  }

  const renderIconLabel = (): ReactNode => {
    if (isOpen) {
      return <ListItemText primary={title} />
    }

    return <></>
  }

  return (
    <Fragment key={route}>
      <ListItem
        button
        onClick={handleClick}
        className={classes.item}
        selected={isSelected()}
        component={NavLink}
        to={route}
      >
        {renderIconItem()}
        {renderIconLabel()}
      </ListItem>

      {childItems.length > 0 && (
        <Collapse in={open}>
          <NavListOneLevel open={isOpen} childItems={childItems} level={level + 1} />
        </Collapse>
      )}

      {level === 1 && <Divider />}
    </Fragment>
  )
}

export default NavListItem
