import { AsyncThunk, createSlice, Dictionary, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { DEFAULT_PAGE_NUMBER, DEFAULT_PER_PAGE } from '@config/pagination'
import { HasIdEntity } from '@models/entities/HasIdEntity'
import { ResponseSuccessful } from '@models/api/response'
import { PaginationState } from '@features/@common'
import { ManyEntitiesMeta } from '@features/@common/model/redux/Action'

type PaginationSliceReducers<Entity extends HasIdEntity> = SliceCaseReducers<PaginationState<Entity>>

interface Response<Entity extends HasIdEntity> extends Omit<ResponseSuccessful, 'data'> {
  data: Entity[]
}

const createDefaultState = <Entity extends HasIdEntity>(): PaginationState<Entity> => ({
  isLoading: false,
  page: DEFAULT_PAGE_NUMBER,
  perPage: DEFAULT_PER_PAGE,
  total: 0,
  pages: 0,
  entities: {},
  ids: [],
})

const createEntitiesDictionary = <Entity extends HasIdEntity>(entities: Entity[]): Dictionary<Entity> => {
  const result: Dictionary<Entity> = {}

  entities.forEach((entity: Entity) => {
    result[entity.id] = entity
  })

  return result
}

const extractEntitiesIds = <Entity extends HasIdEntity>(entities: Entity[]): number[] => {
  return entities.map((entity) => entity.id)
}

export const createPaginationSlice = <Entity extends HasIdEntity>(
  namePrefix: string,
  thunkAction: AsyncThunk<any, any, any>,
  initialState: PaginationState<Entity> = createDefaultState<Entity>(),
) =>
  createSlice<PaginationState<Entity>, PaginationSliceReducers<Entity>>({
    name: `${namePrefix}/pagination`,
    initialState,
    reducers: {
      setEntities: (state: PaginationState<HasIdEntity>, action: PayloadAction<Entity[]>) => {
        state.entities = createEntitiesDictionary(action.payload)
      },
      setPage: (state: PaginationState<HasIdEntity>, action: PayloadAction<number>) => {
        state.page = action.payload
      },
      setPerPage: (state: PaginationState<HasIdEntity>, action: PayloadAction<number>) => {
        state.perPage = action.payload
      },
      replaceEntity: (state: PaginationState<HasIdEntity>, action: PayloadAction<Entity>) => {
        state.entities[action.payload.id] = action.payload
      },
      removeEntity: (state: PaginationState<HasIdEntity>, action: PayloadAction<number>) => {
        delete state.entities[action.payload]
      },
    },
    extraReducers: {
      [thunkAction.pending.type]: (state: PaginationState<HasIdEntity>) => {
        state.isLoading = true
      },
      [thunkAction.fulfilled.type]: (
        state: PaginationState<HasIdEntity>,
        action: PayloadAction<Response<Entity>, string, ManyEntitiesMeta>,
      ) => {
        state.isLoading = false
        state.total = action.payload.pagination.totalItems
        state.pages = action.payload.pagination.totalPages
        state.ids = extractEntitiesIds<Entity>(action.payload.data)
        state.entities = createEntitiesDictionary<Entity>(action.payload.data)
      },
      [thunkAction.rejected.type]: (state: PaginationState<HasIdEntity>) => {
        const defaultState = createDefaultState<Entity>()

        state.isLoading = defaultState.isLoading
        state.page = defaultState.page
        state.perPage = defaultState.perPage
        state.total = defaultState.total
        state.entities = defaultState.entities
        state.ids = defaultState.ids
      },
    },
  })
