import { ChangeEvent } from 'react'
import useDebouncedChangeHandler from '@hooks/form/useDebouncedChangeHandler'

type CustomOnChangeHandler = (event: ChangeEvent<HTMLInputElement>) => void
type Result = (event: ChangeEvent<HTMLInputElement>, onChange?: CustomOnChangeHandler) => void

export const useInputChangeHandler = (): Result => {
  const debounceChangeHandler = useDebouncedChangeHandler()

  return (event: ChangeEvent<HTMLInputElement>, onChange?: CustomOnChangeHandler) => {
    event.persist()

    if (onChange) {
      debounceChangeHandler(event, onChange)
    }
  }
}
