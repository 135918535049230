import { createSlice } from '@reduxjs/toolkit'
import { entityAdapter } from '../adapter'
import { ClientOrderItemModel } from '@models/entities/clientOrders'
import { createClientOrderItem } from '../action'
import CreateSliceState from '../model/slice/CreateSliceState'
import CreateSliceReducer from '../model/slice/CreateSliceReducer'
import createState from '../initialState/createState'
import { actionVerifier } from '@utils/redux/actionVerifier'
import { AnyAction } from 'redux'
import { FulfilledAction, RejectedAction } from '@utils/redux/action'

const verifier = actionVerifier([createClientOrderItem])

const isFulfilledAction = (action: AnyAction): action is FulfilledAction<ClientOrderItemModel> => {
  return verifier.isFulfilledAction<ClientOrderItemModel>(action)
}

const createClientOrderItemSlice = createSlice<CreateSliceState, CreateSliceReducer>({
  name: 'clientOrderItems_create',
  initialState: createState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(verifier.isPendingAction, (state) => {
        state.isLoading = true
        state.error = createState.error
        state.success = createState.success
      })
      .addMatcher(isFulfilledAction, (state, action: FulfilledAction<ClientOrderItemModel>) => {
        state.isLoading = false
        state.error = createState.error
        state.success = action.payload
        entityAdapter.upsertOne(state as CreateSliceState, action.payload)
      })
      .addMatcher(verifier.isRejectedAction, (state, action: RejectedAction) => {
        state.isLoading = false
        state.error = action.error
        state.success = createState.success
      })
  },
})

export default createClientOrderItemSlice
