import { createEntityAdapter } from '@reduxjs/toolkit'
import { DeliveryAddressModel } from '../../../app/models/entities/addresses/DeliveryAddressModel'

const selectId = (entity: DeliveryAddressModel) => entity.id

const entityAdapter = createEntityAdapter({
  selectId,
})

export { selectId, entityAdapter }
