import http from '@utils/http'
import { AxiosRequestConfig } from 'axios'
import { HasIriEntity } from '@models/entities/HasIriEntity'

export const readResource = (iri: string, includes?: string[]): Promise<HasIriEntity> => {
  let config: AxiosRequestConfig = {}

  if (!!includes && includes.length > 0) {
    config = {
      params: {
        include: includes,
      },
    }
  }

  return http.get(iri, config)
}
