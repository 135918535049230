import { useHistory, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { DEFAULT_PAGE_NUMBER } from '@config/pagination'

const useRedirectToRoot = ({ totalItems, perPage, path }) => {
  const history = useHistory()
  const { page = DEFAULT_PAGE_NUMBER } = useParams()
  const totalPages = Math.ceil(totalItems / perPage)

  useEffect(() => {
    if (totalItems && +page > +totalPages) {
      // Redirect to root page, if current page > totalPages
      history.push(path)
    }
  }, [history, totalItems, page, totalPages, path])

  return {
    totalPages,
  }
}

export default useRedirectToRoot
