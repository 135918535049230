import { connect } from 'react-redux'
import { AuthUserType } from '../prop-types'
import { selectCurrentUser } from '../selector'
import EmployeeType from '../model/EmployeeType'

const selectPersonType = (user) => user && user.person && user.person['@type']

const permissionMap = {
  'royal-crown': (user) => selectPersonType(user) === EmployeeType.RoyalCrownEmployee,
  supplier: (user) => selectPersonType(user) === EmployeeType.SupplierEmployee,
  client: (user) => [EmployeeType.ClientEmployee, EmployeeType.RoyalCrownEmployee].includes(selectPersonType(user)),
}

const Permission = ({ permission, user, children, fallback }) => {
  let hasAccess = false

  if (typeof permissionMap[permission] === 'function') {
    hasAccess = permissionMap[permission](user)
  }

  if (hasAccess) {
    return children
  }

  if (fallback) {
    return fallback
  }

  return null
}

Permission.propTypes = {
  user: AuthUserType,
}

const mapStateToProps = (state) => ({
  user: selectCurrentUser(state),
})

export default connect(mapStateToProps)(Permission)
