import React, { FC } from 'react'
import { isEmpty } from 'lodash'
import PhoneService from '../../service/PhoneService'
import { PhoneNumberModel } from '@models/entities/addresses/PhoneNumberModel'
import { Link } from '../MaterialUI/core'

interface Props {
  phone?: PhoneNumberModel | string
}

const Phone: FC<Props> = ({ phone }) => {
  if (isEmpty(phone)) {
    return null
  }

  const phoneFormatted = PhoneService.format(phone as PhoneNumberModel)

  return (
    <Link href={`tel:${phoneFormatted}`} color='secondary'>
      {phoneFormatted}
    </Link>
  )
}

export default Phone
