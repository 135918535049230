import createUpdateSlice from '@features/@common/slices/update/createUpdateSlice'
import { updateArticleCategoryAndAttributes } from '../action'

const updateArticleCategoryAndAttributesSlice = createUpdateSlice(
  'articles.update.category.and.attributes',
  updateArticleCategoryAndAttributes,
)

const updateArticleCategoryAndAttributesActions = updateArticleCategoryAndAttributesSlice.actions
export { updateArticleCategoryAndAttributesSlice, updateArticleCategoryAndAttributesActions }
