import { ARTICLES_PATH, CONDITIONS_PATH } from '@config/routes/paths'
import { ArtTrackIcon, ShoppingCartIcon } from '@components/MaterialUI/icons'
import { generateUrl } from '@utils/route'
import { NavigationItemModel } from '@models/navigation/NavigationItemModel'

const supplierItems: NavigationItemModel[] = [
  {
    route: generateUrl(ARTICLES_PATH),
    title: 'sidebar.articles',
    icon: ArtTrackIcon,
  },
  {
    route: generateUrl(CONDITIONS_PATH),
    title: 'sidebar.conditions',
    icon: ShoppingCartIcon,
  },
]

export default supplierItems
