import MomentUtils from '@date-io/moment'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '../MaterialUI/pickers'
import { DateFormat } from '@config/dateFormat'
import { useTranslation } from '@utils/translation'
import { isBeforeTodayButInCurrentWeek, setFirstDayOfWeek } from './utilities/functions'
import { getNextWorkdayDate, isBeforeToday, parseDate } from '@utils/date'
import Day from './partial/Day'
import { DEFAULT_COUNTRY_ISO_CODE } from '@config/locale'

interface Props {
  dateFormat: string
  date: Moment | null
  onValueChange: (date: string, week?: boolean) => any
  warningText: ReactNode | string
  disabled?: boolean
}

const DatePicker: FC<Props> = ({ date, dateFormat, warningText, onValueChange, disabled = false }) => {
  const { t } = useTranslation()
  const [keyboardFocus, setKeyboardFocus] = useState<boolean>(false)
  const [isOpened, setIsOpened] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Moment | null>(date)

  setFirstDayOfWeek()

  const open = () => {
    setIsOpened(true)
  }

  const close = () => {
    setKeyboardFocus(false)
    setIsOpened(false)
  }

  const handleDateChange = (newDate: Moment | string | null) => {
    let date = parseDate(newDate as Moment, DateFormat.WithoutTime)

    // Do not allow selection of date before today
    if (!keyboardFocus && isBeforeToday(date)) {
      return
    }

    if (isBeforeTodayButInCurrentWeek(date)) {
      date = getNextWorkdayDate()
    }

    onValueChange(date.format(DateFormat.WithoutTime))
    setSelectedDate(date)
  }

  const setInput = () => {
    setKeyboardFocus(true)
  }

  const handleOpen = () => {
    open()
  }

  const handleClose = () => {
    close()
  }

  const handleBlur = () => {
    close()
  }

  useEffect(() => {
    if (date) {
      setSelectedDate(date)
    }
  }, [date])

  const renderDay = (date: MaterialUiPickersDate, selectedDate: MaterialUiPickersDate, dayInCurrentMonth: boolean) => (
    <Day date={date} selectedDate={selectedDate} dayInCurrentMonth={dayInCurrentMonth} />
  )

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={DEFAULT_COUNTRY_ISO_CODE}>
      <KeyboardDatePicker
        size='small'
        disabled={disabled}
        variant='inline'
        format={dateFormat}
        margin='normal'
        value={selectedDate}
        onInput={setInput}
        onOpen={handleOpen}
        onClose={handleClose}
        onBlur={handleBlur}
        onChange={handleDateChange}
        renderDay={renderDay}
        open={isOpened}
        KeyboardButtonProps={{
          'aria-label': t('date:changeDate'),
        }}
        disableToolbar
        error={warningText !== null}
        helperText={warningText}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
