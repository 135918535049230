import { createPaginationSlice } from '@features/@common'
import { fetchClientHasBrandConditionsForClient } from '../action'
import { ClientHasBrandConditionModel } from '@models/entities/clientOrders'

const mainForClientSlice = createPaginationSlice<ClientHasBrandConditionModel>(
  'clientHasBrandConditions.forClient.fetch',
  fetchClientHasBrandConditionsForClient,
)

export { mainForClientSlice }
