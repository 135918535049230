import RoyalListService from '../Service/RoyalListService'
import components from '../Configuration/components'

let service: RoyalListService

const useRoyalListService = (): RoyalListService => {
  if (!service) {
    service = new RoyalListService(components)
  }

  return service
}

export default useRoyalListService
