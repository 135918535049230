import createApiAction from '@features/@common/actions/api-actions'
import { parsePaginatedCollection } from '@utils/api/data'
import * as api from '../api'
import {
  FetchParameters,
  FetchParametersWithFilter,
  UpdateArticleParameters,
  UpdateDimensionParameters,
  UpdateCategoryAndAttributesParameters,
  UpdateImageParameters,
  UpdateArticleColorAndDescription,
  UpdateUniqueSellingPointsParameters,
  UpdateArticleNoteParameters,
} from '../api'
import { ArticleModel } from '@models/entities/articles'

const fetchArticles = createApiAction<any, FetchParameters, any>(
  'articles.fetch',
  async (parameters: FetchParameters) => {
    const response = await api.fetchArticles(parameters)

    return parsePaginatedCollection(response)
  },
)

const fetchArticlesForListWithBrandAndCategory = createApiAction<any, FetchParametersWithFilter, any>(
  'articles.fetchForList',
  async (parameters: FetchParametersWithFilter) => {
    const response = await api.fetchArticlesForListWithBrandAndCategory(parameters)

    return parsePaginatedCollection(response)
  },
)

const readArticle = createApiAction<any, number, any>('articles.readOne', api.readArticle)

const readArticleFromBrands = createApiAction<any, number, any>('articles.readOne.from.brands', async (id: number) => {
  const response = await api.readArticlesFromBrands(id)
  return parsePaginatedCollection(response)
})

const readArticleFromCategories = createApiAction<any, number, any>(
  'articles.readOne.from.categories',
  async (id: number) => {
    const response = await api.readArticlesFromCategories(id)
    return parsePaginatedCollection(response)
  },
)

const readArticleDownSellers = createApiAction<any, number, any>(
  'articles.readMany.downSellers',
  async (id: number) => {
    const response = await api.readArticleDownSellers(id)
    return parsePaginatedCollection(response)
  },
)

const readArticleUpSellers = createApiAction<any, number, any>('articles.readMany.upSellers', async (id: number) => {
  const response = await api.readArticleUpSellers(id)
  return parsePaginatedCollection(response)
})

const readArticleAccessories = createApiAction<any, number, any>(
  'articles.readMany.accessories',
  async (id: number) => {
    const response = await api.readArticleAccessories(id)
    return parsePaginatedCollection(response)
  },
)

const readArticleBelongsTo = createApiAction<any, number, any>('articles.readMany.belongTo', async (id: number) => {
  const response = await api.readArticleBelongsTo(id)
  return parsePaginatedCollection(response)
})

const updateArticle = createApiAction<any, UpdateArticleParameters, any>('articles.update', api.updateArticle)

const updateArticleDimensions = createApiAction<any, UpdateDimensionParameters, any>(
  'articles.update.dimensions',
  api.updateArticleDimensions,
)

const updateArticleCategoryAndAttributes = createApiAction<any, UpdateCategoryAndAttributesParameters, any>(
  'articles.update.category.and.attributes',
  api.updateArticleCategoryAndAttributes,
)

const updateArticleImages = createApiAction<any, UpdateImageParameters, any>(
  'articles.update.images',
  api.updateArticleImages,
)

const updateArticleColorAndDescription = createApiAction<any, UpdateArticleColorAndDescription, any>(
  'article.update.color.and.description',
  api.updateArticleColorAndDescription,
)

const updateArticleUniqueSellingPoints = createApiAction<any, UpdateUniqueSellingPointsParameters, any>(
  'article.update.uniqueSellingPoints',
  api.updateArticleUniqueSellingPoints,
)

const updateArticleNotes = createApiAction<any, UpdateArticleNoteParameters, any>(
  'article.update.notes',
  api.updateArticleNotes,
)

const createArticle = createApiAction<any, ArticleModel, any>('articles.create', api.createArticle)

export {
  fetchArticles,
  fetchArticlesForListWithBrandAndCategory,
  readArticle,
  readArticleFromBrands,
  readArticleFromCategories,
  readArticleDownSellers,
  readArticleUpSellers,
  readArticleAccessories,
  readArticleBelongsTo,
  updateArticle,
  updateArticleDimensions,
  updateArticleCategoryAndAttributes,
  updateArticleColorAndDescription,
  updateArticleImages,
  updateArticleUniqueSellingPoints,
  createArticle,
  updateArticleNotes,
}
