import Address from './Address'
import Email from './Email'
import Phone from './Phone'
import PostalCode from './PostalCode'
import Price from './Price'

const Attributes = {
  Address,
  Email,
  Phone,
  PostalCode,
  Price,
}

export default Attributes
