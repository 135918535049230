import createApiAction from '@features/@common/actions/api-actions'
import { parsePaginatedCollection } from '@utils/api/data'
import * as api from '../api'

const fetchEmailTypes = createApiAction('emailAddressTypes.fetch', async (parameters) => {
  const response = await api.fetchEmailTypes(parameters)
  return parsePaginatedCollection(response)
})

const readEmailType = createApiAction('emailAddressTypes.read', api.readEmailType)
const createEmailType = createApiAction('emailAddressTypes.create', api.createEmailType)

// eslint-disable-next-line import/prefer-default-export
export { fetchEmailTypes, readEmailType, createEmailType }
