import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import i18next from 'i18next'
import get from 'lodash/get'
import { EnhancedStore } from '@reduxjs/toolkit'
import { StatusCodes } from 'http-status-codes'
import apiConfig from '@config/api'
import authStorage from '@features/auth/storage'

let store: EnhancedStore

const instance = axios.create({
  baseURL: apiConfig.url,
})

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = authStorage.getApplicationToken()
  const shouldUseAuth = get(config, 'secure', true)

  if (config.method?.toLowerCase() === 'patch') {
    config.headers['Content-Type'] = 'application/merge-patch+json'
  }

  config.headers.Accept = 'application/ld+json'

  if (token && shouldUseAuth) {
    config.headers.Authorization = `Bearer ${token}`

    if (store) {
      const state = store.getState()
      const { client } = state.auth.context

      if (client) {
        const existingHeaders = config.headers
        const clientHeaders = getClientHeaders(client.id)

        config.headers = Object.assign(clientHeaders, existingHeaders)
      }
    }
  }

  if (i18next.language) {
    config.headers['Accept-Language'] = i18next.language
  }

  return config
})

instance.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error) => {
    if (get(error, 'response.status') === StatusCodes.UNAUTHORIZED && !!store) {
      const state = store.getState()
      const user = get(state, 'auth.user')

      if (user) {
        store.dispatch({ type: 'logout' })
      }
    }

    throw error
  },
)

const setStore = (injectedStore: EnhancedStore) => {
  store = injectedStore
}

const createCancelToken = (signal: AbortSignal) => {
  const source = axios.CancelToken.source()

  signal.addEventListener('abort', () => {
    source.cancel()
  })

  return source.token
}

const getClientHeaders = (clientId: number) => {
  const clientIdHeader = 'X-Client-Id'

  return {
    [clientIdHeader]: clientId,
  }
}

export { setStore, createCancelToken, getClientHeaders }
export default instance
