import { useLocation } from 'react-router-dom'
import { SortingParameter } from '@utils/api/sorting'
import useUrlSortingParameter from './use-url-sorting-parameter'

type Result = {
  sortingUrl: string | null
  sortingParameter?: SortingParameter
}

const useSortingUrl = (field?: string): Result => {
  const location = useLocation()
  const { sortingParameter, createSortingUrlParameter } = useUrlSortingParameter(field)

  const createSortingUrl = (): string | null => {
    if (field) {
      const urlParameter = createSortingUrlParameter()

      if (urlParameter) {
        return `${location.pathname}?${urlParameter}`
      }
    }

    return null
  }

  const sortingUrl = createSortingUrl()

  return {
    sortingUrl,
    sortingParameter,
  }
}

export default useSortingUrl
