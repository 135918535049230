import React, { FC } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@components/MaterialUI/styles'
import logo from '../../assets/logo.png'
import logoSignet from '../../assets/logo-signet.png'
import logoSignetWhite from '../../assets/logo-white-signet.png'
import logoWhite from '../../assets/logo-white.png'
import { Color, Size, Variant } from './Options'
import { Theme } from '@components/MaterialUI/theme'
import { ClickEvent } from '@models/events/ClickEvent'

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    display: 'flex',
    justifyContent: 'center',
  },
  small: {
    maxHeight: theme.spacing(3),
  },
  medium: {
    maxHeight: theme.spacing(6),
  },
  large: {
    maxHeight: theme.spacing(10),
  },
  original: {}, // Required by Size.Original while preparing class name with clsx() function
  clickable: {
    cursor: 'pointer',
  },
}))

interface Props {
  className?: string
  size?: Size
  variant?: Variant
  color?: Color.Red | Color.White
  handleClick?: (event: ClickEvent) => void
}

const AppLogo: FC<Props> = ({ handleClick, size = Size.Small, variant = Variant.Full, color = Color.Red }) => {
  const getLogoImage = (variant?: Variant, color?: Color): string => {
    if (color === Color.White) {
      if (variant === Variant.Signet) {
        return logoSignetWhite
      }

      return logoWhite
    }

    // if color is different than "White", means that it's a "Red" color, because there are no more colors
    if (variant === Variant.Signet) {
      return logoSignet
    }

    return logo
  }

  const classes = useStyles()
  const className = clsx(classes[size], handleClick && classes.clickable)
  const logoImage = getLogoImage(variant, color)

  return (
    <div className={classes.logo} role={'button'} tabIndex={0} onClick={handleClick} onKeyDown={handleClick}>
      <img src={logoImage} alt='Logo' className={className} />
    </div>
  )
}

export default AppLogo
