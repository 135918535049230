import { combineReducers } from 'redux'
import {
  accessoriesSlice,
  belongsToSlice,
  downSellerSlice,
  mainWithClientHeaderSlice,
  mainSlice,
  readSlice,
  updateSlice,
  upSellerSlice,
  updateDimensionsSlice,
  updateArticleCategoryAndAttributesSlice,
  updateArticleUniqueSellingPointsSlice,
  updateImagesSlice,
  updateColorAndDescriptionSlice,
  updateNotesSlice,
} from './slice'

const reducer = combineReducers({
  mainWithClientHeader: mainWithClientHeaderSlice.reducer,
  read: readSlice.reducer,
  update: updateSlice.reducer,
  main: mainSlice.reducer,
  upSellers: upSellerSlice.reducer,
  downSellers: downSellerSlice.reducer,
  accessories: accessoriesSlice.reducer,
  belongsTo: belongsToSlice.reducer,
  updateDimensions: updateDimensionsSlice.reducer,
  updateArticleCategoryAndAttributes: updateArticleCategoryAndAttributesSlice.reducer,
  updateColorAndDescription: updateColorAndDescriptionSlice.reducer,
  updateUniqueSellingPoints: updateArticleUniqueSellingPointsSlice.reducer,
  updateImages: updateImagesSlice.reducer,
  notes: updateNotesSlice.reducer,
})

export default reducer
