import { Dialog } from './Dialog'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from '@utils/translation'
import { Timeout } from '@config/idle'
import { milliseconds2seconds, minutes2milliseconds } from '@utils/time'
import { logout as logoutAction } from '@features/auth/action'
import { useDispatch } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'
import { useVisibility } from '@hooks/toggle/useVisibility'
import { VoidFunction } from '@models/function/VoidFunction'

const useButtonText = () => {
  const { t } = useTranslation()

  return {
    confirmation: t('idle:modalButton.confirm'),
    cancel: t('idle:modalButton.cancel'),
  }
}

const useLogoutAction = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(logoutAction())
  }, [dispatch])
}

const useLogoutTimeout = () => {
  return minutes2milliseconds(Timeout.Logout) as number
}

const useLogoutTimeLeft = () => {
  const logoutTimeout = useLogoutTimeout()
  const [logoutTimeLeft, updateLogoutTimeLeft] = useState<number>(logoutTimeout)

  return {
    logoutTimeLeft,
    updateLogoutTimeLeft,
  }
}

const useDialogContent = (logoutTimeLeft: number) => {
  const { t } = useTranslation()
  const logoutInSeconds = milliseconds2seconds(logoutTimeLeft)

  const title = t('idle:title', { minutesCount: Timeout.Idle })
  const text = t('idle:text', { secondsCount: logoutInSeconds })

  return {
    title,
    text,
  }
}

const useDialogHandlers = (hideDialog: VoidFunction) => {
  const logout = useLogoutAction()

  const handleConfirm = () => {
    hideDialog()
  }

  const handleCancel = () => {
    hideDialog()
    logout()
  }

  return {
    handleConfirm,
    handleCancel,
  }
}

const useTimerHandlers = (showDialog: VoidFunction) => {
  const handleIdle = useCallback(() => {
    showDialog()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    handleIdle,
  }
}

const useAutoLogoutTimeouts = (
  isVisible: boolean,
  logoutTimeLeft: number,
  updateLogoutTimeLeft: (logoutTimeout: number) => void,
) => {
  const logoutTimeout = useLogoutTimeout()
  const logout = useLogoutAction()

  // Automatically logout user when is still inactive (after displaying modal window)
  useEffect(() => {
    if (!isVisible) {
      return
    }

    const timeoutId = setTimeout(() => {
      logout()
    }, logoutTimeout)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [isVisible, logout, logoutTimeout])

  // Countdown time to automatic logout
  useEffect(() => {
    if (!isVisible) {
      updateLogoutTimeLeft(logoutTimeout)
      return
    }

    const refreshInterval = 1000 // 1 second

    const timer = setTimeout(() => {
      updateLogoutTimeLeft(logoutTimeLeft - refreshInterval)
    }, refreshInterval)

    return () => {
      clearTimeout(timer)
    }
  }, [isVisible, logoutTimeout, logoutTimeLeft, updateLogoutTimeLeft])

  return {}
}

const useTimerOptions = (showDialog: VoidFunction) => {
  const { handleIdle } = useTimerHandlers(showDialog)
  const timeout = minutes2milliseconds(Timeout.Idle) as number

  return {
    timeout,
    onIdle: handleIdle,
  }
}

export const IdleDialog: FC = () => {
  const buttonText = useButtonText()
  const { logoutTimeLeft, updateLogoutTimeLeft } = useLogoutTimeLeft()
  const { title, text } = useDialogContent(logoutTimeLeft)
  const { isVisible, show, hide } = useVisibility()
  const { handleCancel, handleConfirm } = useDialogHandlers(hide)
  const timerOptions = useTimerOptions(show)

  useAutoLogoutTimeouts(isVisible, logoutTimeLeft, updateLogoutTimeLeft)
  useIdleTimer(timerOptions)

  return (
    <Dialog
      title={title}
      text={text}
      buttonText={buttonText}
      isOpened={isVisible}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    />
  )
}
