import React, { ReactNode } from 'react'
import { Control } from 'react-hook-form'
import {
  CheckboxFieldModel,
  CollectionFieldModel,
  DropdownFieldModel,
  ListFieldModel,
  NumberFieldModel,
  ObjectFieldModel,
  TextareaFieldModel,
  TreeViewFieldModel,
  TextFieldModel,
} from '@models/form/fields/FieldModels'
import {
  FormCheckboxField,
  FormCollectionField,
  FormDropdownField,
  FormListField,
  FormNumberField,
  FormSection,
  FormTextareaField,
  FormTextField,
  FormTreeView,
} from '@components/Form/FormFields'
import { refuseFalsyItems } from '@utils/array'
import { v4 } from 'uuid'

const createSection = (
  source: Record<string, any>,
  control: Control<any>,
  disabled: boolean,
): ReactNode | undefined => {
  if (!(source instanceof ObjectFieldModel)) {
    return
  }

  return <FormSection key={source.label} section={source} control={control} disabled={disabled} />
}

const createTextField = (
  source: Record<string, any>,
  control: Control<any>,
  disabled: boolean,
  sectionLabel?: string,
): ReactNode | undefined => {
  if (!(source instanceof TextFieldModel)) {
    return
  }

  return (
    <FormTextField key={v4()} formField={source} control={control} disabled={disabled} sectionLabel={sectionLabel} />
  )
}

const createTextareaField = (
  source: Record<string, any>,
  control: Control<any>,
  disabled: boolean,
  sectionLabel?: string,
): ReactNode | undefined => {
  if (!(source instanceof TextareaFieldModel)) {
    return
  }

  return (
    <FormTextareaField
      key={v4()}
      formField={source}
      control={control}
      disabled={disabled}
      sectionLabel={sectionLabel}
    />
  )
}

const createNumberField = (
  source: Record<string, any>,
  control: Control<any>,
  disabled: boolean,
  sectionLabel?: string,
): ReactNode | undefined => {
  if (!(source instanceof NumberFieldModel)) {
    return
  }

  return (
    <FormNumberField key={v4()} formField={source} control={control} disabled={disabled} sectionLabel={sectionLabel} />
  )
}

const createCheckboxField = (
  source: Record<string, any>,
  control: Control<any>,
  disabled: boolean,
  sectionLabel?: string,
): ReactNode | undefined => {
  if (!(source instanceof CheckboxFieldModel)) {
    return
  }

  return (
    <FormCheckboxField
      key={v4()}
      formField={source}
      control={control}
      disabled={disabled}
      sectionLabel={sectionLabel}
    />
  )
}

const createDropdownField = (
  source: Record<string, any>,
  control: Control<any>,
  disabled: boolean,
  sectionLabel?: string,
): ReactNode | undefined => {
  if (!(source instanceof DropdownFieldModel)) {
    return
  }

  return (
    <FormDropdownField
      key={v4()}
      formField={source}
      control={control}
      disabled={disabled}
      sectionLabel={sectionLabel}
    />
  )
}

const createCollectionField = (
  source: Record<string, any>,
  control: Control<any>,
  disabled: boolean,
  sectionLabel?: string,
): ReactNode | undefined => {
  if (!(source instanceof CollectionFieldModel)) {
    return
  }

  return <FormCollectionField formFields={source} control={control} disabled={disabled} sectionLabel={sectionLabel} />
}

const createTreeViewField = (
  label: string,
  source: Record<string, any>,
  control: Control<any>,
  disabled: boolean,
  sectionLabel?: string,
): ReactNode | undefined => {
  if (!(source instanceof TreeViewFieldModel)) {
    return
  }

  return (
    <FormTreeView
      label={label}
      formField={source}
      control={control}
      disabled={disabled}
      key={v4()}
      sectionLabel={sectionLabel}
    />
  )
}

const createListViewField = (
  source: Record<string, any>,
  control: Control<any>,
  disabled: boolean,
  sectionLabel?: string,
): ReactNode | undefined => {
  if (!(source instanceof ListFieldModel)) {
    return
  }
  return (
    <FormListField formField={source} control={control} disabled={disabled} key={v4()} sectionLabel={sectionLabel} />
  )
}

export const createFormFields = (
  source: Record<string, any> | undefined,
  control: Control<any>,
  disabled: boolean,
  sectionLabel?: string,
): ReactNode[] => {
  if (!source) {
    return []
  }

  const createFields = (): ReactNode[] =>
    Object.values(source).map((entry) => {
      const section = createSection(entry, control, disabled)
      const textField = createTextField(entry, control, disabled, sectionLabel)
      const textareaField = createTextareaField(entry, control, disabled, sectionLabel)
      const numberField = createNumberField(entry, control, disabled, sectionLabel)
      const checkboxField = createCheckboxField(entry, control, disabled, sectionLabel)
      const dropdownField = createDropdownField(entry, control, disabled, sectionLabel)
      const collectionField = createCollectionField(entry, control, disabled, sectionLabel)
      const treeView = createTreeViewField(source.label, entry, control, disabled, sectionLabel)
      const listField = createListViewField(entry, control, disabled, sectionLabel)

      return (
        section ??
        textField ??
        textareaField ??
        numberField ??
        checkboxField ??
        dropdownField ??
        collectionField ??
        treeView ??
        listField
      )
    })

  const fields = createFields()

  return refuseFalsyItems(fields)
}
