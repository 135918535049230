import React, { ChangeEvent, FC } from 'react'
import clsx from 'clsx'
import { useTranslation } from '@utils/translation'
import useRoyalListService from '../Hook/useRoyalListService'
import { makeStyles } from '../../MaterialUI/styles'
import { Column } from '../Model/Column'
import { Theme } from '@components/MaterialUI/theme'
import EditableColumn from '../Model/EditableColumn'
import { useDistinctiveCellProps } from '../Hook/useDistinctiveCellProps'
import { useIdGenerator } from '@features/focusedInput/hooks/useIdGenerator'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.royal.lightGray}`,
    display: 'flex',
    width: '100%',
  },
}))

type Props = {
  object: Record<string, unknown>
  column: Column
  rowIndex: number
  cellIndex: number
}

const Cell: FC<Props> = ({ object, column, rowIndex, cellIndex }) => {
  const { t } = useTranslation()
  const service = useRoyalListService()
  const classes = useStyles()
  const { generateId } = useIdGenerator('cell')

  const CellComponent = service.getCellComponent(column.cell)
  const value = service.getValue(object, column.value)
  const cellProps = useDistinctiveCellProps(column, value, object)
  const title = service.getTitle(object, column.abbreviation ?? column.value, t(column.name), cellProps.prefix)
  const disabled = service.isDisabled(object, column)

  const handleChange = (event: ChangeEvent<HTMLInputElement>, extraData?: any) => {
    const editableColumn = column as EditableColumn

    if (editableColumn.onChange) {
      editableColumn.onChange(event, object, extraData)
    }
  }

  const wrapperClasses = clsx(column.className, classes.wrapper)
  const id = generateId(`-${rowIndex}-${cellIndex}`)

  return (
    <div className={wrapperClasses}>
      <CellComponent
        title={title}
        value={value}
        onChange={handleChange}
        icon={column.icon}
        disabled={disabled}
        id={id}
        alignment={column.alignment?.content}
        {...cellProps}
      />
    </div>
  )
}

export default Cell
