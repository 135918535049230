import { Day } from './date'

enum CountryIsoCode {
  Belgium = 'nl_BE',
}

export enum Locale {
  French = 'fr',
  Dutch = 'nl',
  English = 'en',
}

interface LocaleConfiguration {
  locales: string[]
  fallBackLocale: string
  firstDayOfWeek: Day
}

const localeConfiguration: LocaleConfiguration = Object.freeze({
  locales: [Locale.French, Locale.Dutch, Locale.English],
  fallBackLocale: Locale.Dutch,
  firstDayOfWeek: Day.Monday,
})

export default localeConfiguration
export const DEFAULT_COUNTRY_ISO_CODE = CountryIsoCode.Belgium
