import React, { FC, useEffect, useState } from 'react'
import { UploadType } from '@components/MediaUpload/config/UploadType'
import { makeStyles } from '@components/MaterialUI/styles'
import ProcessingButton from '@components/Button/components/ProcessingButton'
import { MediaUploadProps } from '@models/form/upload/MadiaUploadModel'
import ImageUpload from '@components/MediaUpload/components/ImageUpload'
import { Color } from '@models/common/Color'
import { useTranslation } from '@utils/translation'
import { useDispatch, useSelector } from 'react-redux'
import { default as selectors } from '@features/mediaObjects/redux/selector/create'
import { createImageMediaObject } from '@features/mediaObjects/redux/action'
import { Padding } from '@theme/styles/padding'

const useStyles = makeStyles(() => ({
  uploadButton: {
    margin: `${Padding.Medium} 0`,
  },
}))

const FILE_ATTRIBUTE = 'file'

const MediaUpload: FC<MediaUploadProps> = ({ uploadType, onUploadSuccess }) => {
  const [selectedFile, setSelectedFile] = useState<undefined | File>()
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const getNewMediaObject = (state: any) => selectors.selectNewMediaObject(state)
  const newMediaObject = useSelector(getNewMediaObject)

  const onFileUpload = () => {
    if (selectedFile) {
      if (uploadType === UploadType.Image) {
        uploadImageFile(selectedFile)
      }
    }
  }

  const uploadImageFile = (selectedFile: File) => {
    const formData = new FormData()
    formData.append(FILE_ATTRIBUTE, selectedFile)
    dispatch(createImageMediaObject(formData))
  }

  const onUpload = (file?: File) => {
    setSelectedFile(file)
  }

  useEffect(() => {
    if (newMediaObject && selectedFile) {
      onUploadSuccess(newMediaObject)
    }
  }, [newMediaObject, onUploadSuccess, selectedFile])

  return (
    <div>
      {uploadType === UploadType.Image && <ImageUpload onUpload={onUpload} />}

      {selectedFile && (
        <ProcessingButton color={Color.Primary} className={classes.uploadButton} onClick={onFileUpload}>
          {t('upload:actions.upload')}
        </ProcessingButton>
      )}
    </div>
  )
}

export default MediaUpload
