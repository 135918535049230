export const contains = (haystack: string, needle: string): boolean => {
  if (haystack === '' || needle === '') {
    return false
  }

  const regex = new RegExp(needle)

  return regex.test(haystack)
}

export const slugifyNoLowerCase = (...args: (string | number)[]): string => {
  if (args.length === 0) {
    return ''
  }

  const value = args.join(' ')

  return value
    .normalize('NFD') // split an accented letter in the base letter and the accent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .trim()
    .replace(/[^a-zA-Z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '-') // separator
}

export const slugify = (...args: (string | number)[]): string => {
  return slugifyNoLowerCase(...args).toLowerCase()
}
