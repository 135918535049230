import { ResponseDataCollection, ResponseSuccessful } from '@models/api/response'
import { HasIriEntity } from '@models/entities/HasIriEntity'
import { ResponseDataBasic } from '@models/api/response/ResponseDataBasic'

const parsePaginatedCollection = (response: any): ResponseSuccessful => {
  return {
    data: parseCollection(response) as Record<string, unknown>[],
    pagination: {
      totalItems: parseTotalItems(response),
      totalPages: parsePageCount(response),
    },
  }
}

const parseJwt = (token: string): any => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

const parsePageCount = (response: ResponseDataCollection): number => {
  try {
    return Number(response['hydra:view']['hydra:last'].match(/page=(\d+)/)![1])
  } catch (e) {
    return 1
  }
}

const parseCollection = (response: ResponseDataBasic): Record<string, unknown>[] => response['hydra:member']
const parseTotalItems = (response: ResponseDataCollection): number => response['hydra:totalItems']

const extractIri = (resource: HasIriEntity): string => {
  return resource['@id']
}

export { parsePaginatedCollection, parseJwt, extractIri }
