const DEFAULT_VALUE_COMPONENT_INDEX = 0
const NO_VALUE_PLACEHOLDER = '-'

// Required to determine what was clicked
// (see details in handleClick() function in the src/components/RoyalList/Row/Row.tsx component)
enum ActionsClasses {
  ShowIcon = 'royal-show-actions',
  Item = 'royal-action',
}

export { DEFAULT_VALUE_COMPONENT_INDEX, NO_VALUE_PLACEHOLDER, ActionsClasses }
