import { NAV_ITEM_SIZE, PADDING } from '../constants'

export default {
  root: {
    padding: `${PADDING} 0`,
    flexGrow: 1,
    flexShrink: 1,
    height: '100%',
    maxHeight: NAV_ITEM_SIZE,

    '& span': {
      display: 'inline-flex',
    },
  },
}
