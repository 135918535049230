import shape from '../shape'

export default {
  root: {
    borderRadius: `${shape.largeBorderRadius}px`,
    padding: '8px 36px',
    width: 'fit-value',
    height: 'fit-value',
    minHeight: '25px',
  },
}
