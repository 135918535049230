import { entityAdapter } from '../adapter'
import BelongsToSliceState from '../model/slice/BelongsToSliceState'

const belongsToInitialState: BelongsToSliceState = {
  ...entityAdapter.getInitialState(),
  isLoading: true,
  error: undefined,
}

export default belongsToInitialState
