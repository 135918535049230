enum Day {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

enum Month {
  January,
}

enum UnitOfTime {
  Day = 'day',
  Week = 'week',
  Minute = 'minute',
  Millisecond = 'millisecond',
}

export { Day, Month, UnitOfTime }
