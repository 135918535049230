import React from 'react'
import { SELECT_FIELD_TYPE, TEXT_FIELD_TYPE, TEXTAREA_FIELD_TYPE } from '../common/fieldType'
import Textarea from './Textarea'
import Select from './Select'
import Input from './Input'

const FieldControl = ({ type, ...rest }) => {
  switch (type) {
    case TEXTAREA_FIELD_TYPE: {
      return <Textarea {...rest} />
    }

    case SELECT_FIELD_TYPE: {
      return <Select {...rest} />
    }

    case TEXT_FIELD_TYPE:
    default: {
      return <Input type={type} {...rest} />
    }
  }
}

export default FieldControl
