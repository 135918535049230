import createApiAction from '@features/@common/actions/api-actions'
import { parsePaginatedCollection } from '@utils/api/data'
import * as api from '../api'

const fetchSuppliers = createApiAction('suppliers.fetch', async (parameters) => {
  const response = await api.fetchSuppliers(parameters)

  return parsePaginatedCollection(response)
})

const createSupplier = createApiAction('suppliers.create', api.createSupplier)
const readSupplier = createApiAction('suppliers.read', api.readSupplier)
const updateSupplier = createApiAction('suppliers.update', api.updateSupplier)

// eslint-disable-next-line import/prefer-default-export
export { fetchSuppliers, createSupplier, readSupplier, updateSupplier }
