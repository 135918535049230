import { combineReducers } from 'redux'
import authContextSlice from './slice/authContextSlice'
import authReducer from './reducer/authReducer'

const reducer = combineReducers({
  main: authReducer,
  context: authContextSlice.reducer,
})

export default reducer
