import React, { FC, useEffect, useRef } from 'react'
import { Theme } from '@components/MaterialUI/theme'
import { ChevronDownIcon, ChevronUpIcon } from '@components/MaterialUI/icons'
import { makeStyles } from '@components/MaterialUI/styles'
import { useTranslation } from '@utils/translation'
import { IconButton } from '@components/MaterialUI/core'
import { RoyalTooltip } from '@components/index'
import { VoidFunction } from '@models/function/VoidFunction'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.royal.lightGray,
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  tooltip: {
    transform: 'translate3d(-50%, calc(100% - 15px), 0) !important',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  visible: {
    display: 'flex',
  },
  inVisible: {
    display: 'none',
  },
}))

interface Props {
  toggle: VoidFunction
  isOpen: boolean
  title: string
  className?: string
}

const CollapsablePane: FC<Props> = ({ toggle, isOpen, title, children, className }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const myRef = useRef<any>()

  useEffect(() => {
    if (myRef.current && isOpen) {
      myRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    }
  }, [isOpen])

  const tableClass = isOpen ? classes.visible : classes.inVisible
  return (
    <div className={className}>
      <div className={classes.container}>
        <RoyalTooltip value={t(title)} className={classes.tooltip}>
          <IconButton className={classes.button} onClick={toggle} tabIndex={0} onKeyDown={toggle}>
            {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </IconButton>
        </RoyalTooltip>
      </div>

      <div className={tableClass} ref={myRef}>
        {children}
      </div>
    </div>
  )
}

export default CollapsablePane
