import React, { ChangeEvent, FC, Ref, useEffect, useState } from 'react'
import clsx from 'clsx'
import { v4 } from 'uuid'
import { makeStyles } from '@components/MaterialUI/styles'
import LabeledStyles from './LabeledStyles'
import { FONT_WEIGHT_BOLD } from '@theme/constants'
import { useTranslation } from 'react-i18next'
import { InputLabel, TextField } from '@components/MaterialUI/core'
import { useInputChangeHandler } from '@utils/form/events/useInputChangeHandler'
import { FieldError } from 'react-hook-form'
import { useErrorMessage } from '@utils/form/errors/useErrorMessage'
import { capitalize } from '@components/MaterialUI/functions'

const useStyles = makeStyles(() => ({
  row: {
    ...LabeledStyles.row,
  },
  line: {
    ...LabeledStyles.line,

    '& input': {
      fontWeight: FONT_WEIGHT_BOLD,
      paddingLeft: '4px',
    },
  },
  indent: {
    ...LabeledStyles.indent,
  },
  bottomSpace: {
    marginBottom: '20px',
  },
}))

export interface LabeledTextFieldProps {
  value?: string | number
  labelText?: string
  indent?: boolean
  className?: string
  showIfEmptyValue?: boolean
  withBottomMargin?: boolean
  ref?: Ref<any>
  error?: FieldError
  disabled?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  minRows?: number
}

const LabeledTextField: FC<LabeledTextFieldProps> = ({
  value,
  labelText = '',
  showIfEmptyValue = false,
  withBottomMargin = false,
  indent = false,
  className,
  disabled = true,
  ref,
  error,
  onChange,
  minRows,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const changeHandler = useInputChangeHandler()
  const message = useErrorMessage(error)
  const [errorMessage, setErrorMessage] = useState<undefined | string>(message)
  const isMultiline = minRows ? minRows > 0 : false

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    value = event.target.value
    changeHandler(event, onChange)
  }

  useEffect(() => {
    setErrorMessage(message)
  }, [message])

  if (!value && !showIfEmptyValue) {
    return <></>
  }

  return (
    <div className={clsx(classes.row, className, { [classes.bottomSpace]: withBottomMargin })} key={v4()}>
      <InputLabel className={clsx(classes.line, { [classes.indent]: indent })}>
        {labelText && capitalize(t(labelText))}
      </InputLabel>
      <TextField
        error={!!errorMessage}
        size='small'
        required
        className={classes.line}
        name={labelText}
        type='text'
        defaultValue={value}
        disabled={disabled}
        inputRef={ref}
        onChange={handleChange}
        helperText={errorMessage}
        rows={minRows}
        multiline={isMultiline}
      />
    </div>
  )
}

export default LabeledTextField
