enum DateErrors {
  DATE_BEFORE_MINIMAL_DATE,
  INVALID_DATE_FORMAT,
  INVALID_WEEK_PICKER_FORMAT,
  DATE_AFTER_MAXIMAL_DATE,
  WEEK_DOES_NOT_EXIST,
  NO_DELIVERIES_DURING_WEEKEND,
  NO_DELIVERIES_ON_SAME_DAY_AS_TODAY,
}

export { DateErrors }
