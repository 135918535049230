import { has } from 'lodash'

const getRowId = (row) => {
  if (has(row, 'original.id')) {
    return row.original.id
  }

  return null
}

const getRowSelectionOptions = (providedOptions) => {
  const defaults = {
    enabled: false,
    getRowId: (row) => getRowId(row),
  }

  return {
    ...defaults,
    ...providedOptions,
  }
}

const toggleRowSelection = (isChecked, id, selectedRowsIds, setSelectedRowsIds) => {
  if (isChecked) {
    selectedRowsIds.push(id)
    setSelectedRowsIds([...selectedRowsIds])

    return
  }

  const index = selectedRowsIds.indexOf(id)

  if (index > -1) {
    selectedRowsIds.splice(index, 1)
    setSelectedRowsIds([...selectedRowsIds])
  }
}

const isRowSelected = (id, selectedRowsIds) => {
  return selectedRowsIds.indexOf(id) > -1
}

const handleCheckboxClick = (event) => {
  event.stopPropagation()
}

export { getRowId, getRowSelectionOptions, toggleRowSelection, isRowSelected, handleCheckboxClick }
