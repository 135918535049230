import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { TreeItem, TreeView } from '@material-ui/lab'
import { ChevronRightIcon, ExpandMoreIcon } from '@components/MaterialUI/icons'
import { v4 } from 'uuid'
import { TreeViewFieldModel } from '@models/form/fields/FieldModels'
import { connect, useDispatch } from 'react-redux'
import { Loader } from '@components/index'
import { InputLabel } from '@components/MaterialUI/core'
import { makeStyles } from '@components/MaterialUI/styles'
import { Theme } from '@components/MaterialUI/theme'
import LabeledStyles from '../Labeled/LabeledStyles'
import { useTranslation } from '@utils/translation'
import clsx from 'clsx'
import { Control, RegisterOptions, useController } from 'react-hook-form'
import { getInputName } from '@utils/form/fields'
import { mergeRules } from '@utils/form/rules/mergeRules'
import { refuseElementsByCount } from '@utils/array'
import { ROOT_ID } from '@components/Form/FormFields/config/TreeView/constants'
import { CategoryModel } from '@models/entities/categories/CategoryModel'
import { CommonNodeFieldModel, NodeFieldModel } from '@components/Form/FormFields/model/TreeView'
import {
  createNodeFields,
  findNodeById,
  getNodeFieldModels,
  getParentNodeFieldsFromNode,
} from '@utils/form/fields/TreeView'

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    ...LabeledStyles.row,
    margin: '3px 0',
  },
  line: {
    ...LabeledStyles.line,
  },
  item: {
    flexGrow: 1,
    padding: '6px 0',
  },
  highlight: {
    '& > div': {
      '&:first-child': {
        '& .MuiTreeItem-label': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.royal.nearlyWhite,
        },
      },
    },
  },
}))

interface TreeNodeValueToFetch {
  node: CommonNodeFieldModel
  forChildren?: boolean
}

interface FormTreeViewProps {
  formField: TreeViewFieldModel
  label: string
  disabled: boolean
  objects: Record<string, any>[]
  control: Control
  isLoading: boolean
  sectionLabel?: string
}

const FormTreeView: FC<FormTreeViewProps> = ({
  formField,
  label,
  disabled,
  objects,
  control,
  isLoading,
  sectionLabel,
}) => {
  const [expanded, setExpanded] = useState<string[]>([ROOT_ID])
  const [loadingChildren, setLoadingChildren] = useState<boolean>(false)
  const [dataTree, setDataTree] = useState<CommonNodeFieldModel[]>([])
  const [valueToFetch, setValueToFetch] = useState<undefined | TreeNodeValueToFetch>()
  const [initialLoad, setInitialLoad] = useState<boolean>(false)
  const [dispatchWasDone, setDispatchWasDone] = useState<boolean>(false)
  const name = getInputName(formField, sectionLabel)
  const rules = mergeRules(formField.rules) as RegisterOptions
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const fields: NodeFieldModel<CategoryModel>[] = getNodeFieldModels<CategoryModel>(formField)

  const { field } = useController({
    name,
    rules,
    control,
  })

  const handleSelectionChange = useCallback(
    (record: CommonNodeFieldModel) => {
      setExpanded([ROOT_ID])
      field.onChange(record)
    },
    [field],
  )

  const loadNodes = useCallback(
    async (param: Record<string, any>) => {
      if (formField.fillAction) {
        await dispatch(formField.fillAction(param))
        setDispatchWasDone(true)
      }
    },
    [formField, dispatch],
  )

  const toggleRoot = () => {
    let nodes = [ROOT_ID, ...expanded]

    if (expanded.length && expanded.includes(ROOT_ID)) {
      nodes = expanded.filter((node) => node !== ROOT_ID)
    }

    setExpanded(nodes)
  }

  const toggleExpansion = useCallback(
    (value: CommonNodeFieldModel, level: number, forChildren?: boolean) => {
      const valueToFind = dataTree.length ? `${value.id}` : ROOT_ID
      let newValueToFetch: TreeNodeValueToFetch | undefined = { node: value, forChildren: false }

      if (valueToFind !== ROOT_ID) {
        const foundIndex = expanded.findIndex((item) => item === valueToFind)
        let newNodes

        if (foundIndex === -1) {
          newNodes = [...expanded, valueToFind]

          if (level < expanded.length) {
            newNodes = [...refuseElementsByCount(expanded, level), valueToFind]
          }

          newValueToFetch = { node: value, forChildren: forChildren }
        } else {
          newNodes = expanded.filter((node) => node !== valueToFind)
          newValueToFetch = undefined
        }

        setExpanded(newNodes)

        if (!value.children.length && dataTree.length && !initialLoad) {
          handleSelectionChange(value)
          return
        }
      }

      setValueToFetch(newValueToFetch)
    },
    [dataTree, handleSelectionChange, expanded, initialLoad],
  )

  const fetchData = useCallback(
    (value: CommonNodeFieldModel, forChildren?: boolean) => {
      if (
        (formField.fillAction && expanded.includes(`${value.id}`)) ||
        (expanded.length === 1 && expanded[0] === ROOT_ID)
      ) {
        const param = formField.getParamsCallback(value.id, forChildren)
        loadNodes({ extra: { ...param } })
        setValueToFetch(undefined)
      }
    },
    [expanded, formField, loadNodes],
  )

  const shouldBeHighlighted = (item: CommonNodeFieldModel): boolean => {
    return expanded.filter((node) => node === `${item.id}`).length > 0
  }

  const getTreeNodes = (list: CommonNodeFieldModel[], level: number): ReactNode[] => {
    return list.map((item) => (
      <TreeItem
        className={clsx({ [classes.highlight]: shouldBeHighlighted(item) })}
        key={v4()}
        nodeId={`${item.id}`}
        label={item.name}
        onClick={() => toggleExpansion(item, level, true)}
      >
        {item.children ? getTreeNodes(item.children, level + 1) : <></>}
      </TreeItem>
    ))
  }

  useEffect(() => {
    if (objects && objects.length && !disabled && dispatchWasDone) {
      let newTree = dataTree.length ? dataTree : createNodeFields(formField.mapToNodeModel, objects)
      setDispatchWasDone(false)

      if (objects[0].parent) {
        newTree = getParentNodeFieldsFromNode(formField.mapToNodeModel, newTree, objects)
      }

      setDataTree(newTree)
    }

    setLoadingChildren(false)
  }, [objects, dataTree, formField, dispatchWasDone, disabled])

  useEffect(() => {
    if (valueToFetch) {
      setLoadingChildren(true)
      fetchData(valueToFetch.node, valueToFetch.forChildren)
    }
  }, [valueToFetch, fetchData])

  const fieldsHaveBeenExpanded = useCallback((): boolean => {
    return expanded.includes(ROOT_ID) && fields.length <= expanded.length - 1
  }, [expanded, fields])

  const treeIsLoading = useCallback((): boolean => {
    return disabled || isLoading || loadingChildren
  }, [disabled, isLoading, loadingChildren])

  useEffect(() => {
    //to expand tree on load
    if (!fieldsHaveBeenExpanded() && !valueToFetch && !treeIsLoading() && initialLoad) {
      let forChildren = false
      let nodeToExpand: CommonNodeFieldModel = fields[0]
      let level = 1

      if (dataTree.length) {
        const node = findNodeById(dataTree, 1, fields[expanded.length - 1].id)

        if (node) {
          nodeToExpand = node.node
          level = node.level
          forChildren = true
        }
      }

      toggleExpansion(nodeToExpand, level, forChildren)
    }

    if (fields.length < expanded.length) {
      setInitialLoad(false)
    }
  }, [dataTree, fields, fieldsHaveBeenExpanded, treeIsLoading, expanded, toggleExpansion, valueToFetch, initialLoad])

  useEffect(() => {
    if (!disabled && !dataTree.length) {
      setInitialLoad(true)
    }
  }, [disabled, dataTree])

  if (isLoading || loadingChildren || initialLoad) {
    return <Loader />
  }

  return (
    <div className={classes.row}>
      <InputLabel className={classes.line}>{disabled ? t(label) : t(formField.label)}</InputLabel>
      {disabled ? (
        <div className={classes.item}>
          {fields.map((field) => (
            <div key={v4()}>{field.name}</div>
          ))}
        </div>
      ) : (
        <TreeView
          className={classes.item}
          key={v4()}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          {...field}
        >
          <TreeItem key={v4()} nodeId={ROOT_ID} label={t(label)} onClick={() => toggleRoot()}>
            {getTreeNodes(dataTree, 1)}
          </TreeItem>
        </TreeView>
      )}
    </div>
  )
}

interface OwnPropsType {
  formField: {
    selectors: {
      selectAll: (state: any) => Record<string, any>[]
      isLoading: (state: any) => boolean
      getTotal: (state: any) => number
      getPerPage: (state: any) => number
    }
  }
}

const mapStateToProps = (state: any, ownProps: OwnPropsType) => ({
  objects: ownProps.formField.selectors.selectAll(state),
  isLoading: ownProps.formField.selectors.isLoading(state),
})

export default connect(mapStateToProps)(FormTreeView)
