import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { Padding } from '@theme/styles/padding'

const cellStyle: CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  paddingLeft: Padding.Small,
  paddingRight: Padding.Small,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
}

const headerLinkStyle: CSSProperties = {
  alignItems: 'center',
  color: 'inherit',
  display: 'flex',
  textDecoration: 'none',
}

const slimCellStyle: CSSProperties = {
  flex: '0 0 2rem',
}

export { cellStyle, headerLinkStyle, slimCellStyle }
