import { createSlice } from '@reduxjs/toolkit'
import { readClient } from '../action'
import { entityAdapter } from '../adapter'
import { initialState, SliceStateModel } from './models/SliceStateModel'
import { SliceReducersModel } from './models/SliceReducerModel'

const readSlice = createSlice<SliceStateModel, SliceReducersModel>({
  name: 'client_read',
  initialState,
  reducers: {},
  extraReducers: {
    [readClient.pending.type]: (state) => {
      state.isLoading = true
    },
    [readClient.fulfilled.type]: (state, action) => {
      entityAdapter.upsertOne(state, action.payload.data)
      state.isLoading = false
    },
    [readClient.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default readSlice
