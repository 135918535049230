import { EntityState } from '@reduxjs/toolkit'
import { entityAdapter } from '../../adapter'
import { ClientModel } from '@models/entities/clientOrders'

interface SliceStateModel extends EntityState<ClientModel> {
  isLoading: boolean
  isUpdating: boolean
  updatedSuccessfully: boolean
  error?: string
}

const initialState: SliceStateModel = {
  ...entityAdapter.getInitialState(),
  isLoading: true,
  isUpdating: false,
  updatedSuccessfully: false,
  error: undefined,
}

export { initialState }
export type { SliceStateModel }
