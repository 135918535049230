import React, { FC } from 'react'
import clsx from 'clsx'
import { Theme } from '@components/MaterialUI/theme'
import { makeStyles } from '@components/MaterialUI/styles'
import { ChevronLeftIcon, ChevronRightIcon } from '@components/MaterialUI/icons'
import { AppBar, IconButton, Toolbar } from '@components/MaterialUI/core'
import { LocaleSwitcher } from '@components/index'
import UserActions from './UserActions'
import { useTranslation } from '@utils/translation'

const useStyles = () =>
  makeStyles((theme: Theme) => ({
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      [theme.breakpoints.up('lg')]: {
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    spacer: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(3),
    },
  }))

interface Props {
  opened: boolean
  setOpened: (isOpened: boolean) => void
}

const Header: FC<Props> = ({ opened, setOpened }) => {
  const classes = useStyles()()
  const { t } = useTranslation()

  const getAriaLabel = (): string => {
    let translationKey = 'header:menu.showFull'

    if (opened) {
      translationKey = 'header:menu.showSmall'
    }

    return t(translationKey)
  }

  const navButton = (
    <IconButton
      edge='start'
      color='inherit'
      aria-label={getAriaLabel()}
      onClick={() => {
        setOpened(!opened)
      }}
      className={clsx(classes.menuButton)}
    >
      {opened ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </IconButton>
  )

  return (
    <AppBar
      position='sticky'
      color='inherit'
      elevation={0}
      className={clsx(classes.appBar, opened && classes.appBarShift)}
    >
      <Toolbar>
        {navButton}
        <div className={classes.spacer} />
        <LocaleSwitcher />
        <UserActions />
      </Toolbar>
    </AppBar>
  )
}

export default Header
