import React, { FC, Suspense, useState } from 'react'
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config'
import { makeStyles } from '@components/MaterialUI/styles'
import Header from './components/Header'
import Navigation from './components/Navigation'
import { IdleDialog, Loader } from '@components/index'
import RightPane from './components/RightPane'
import { Theme } from '@components/MaterialUI/theme'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'none',
  },
  inner: {
    display: 'flex',
    height: 'inherit',
  },
  padding: {
    flexGrow: 1,
    overflow: 'auto',
    height: 'calc(100% - 65px)',
    width: '30vw',
    padding: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2),
    },
  },
}))

const MainLayout: FC<RouteConfigComponentProps> = ({ route }) => {
  const classes = useStyles()
  const [opened, setOpened] = useState(false)
  const [drawerWidth] = useState(240)

  return (
    <div className={classes.root}>
      <Navigation open={opened} drawerWidth={drawerWidth} />
      <main className={classes.content}>
        <Header opened={opened} setOpened={setOpened} />
        <div className={classes.inner}>
          <Suspense fallback={<Loader />}>
            {route && <div className={classes.padding}>{renderRoutes(route.routes)}</div>}
            <RightPane />
            <IdleDialog />
          </Suspense>
        </div>
      </main>
    </div>
  )
}

export default MainLayout
