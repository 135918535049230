const addValuesToKey = (object: Record<string, any>, key: string, recordToAdd: Record<string, any>) => {
  return (object[key] = {
    ...object[key],
    ...recordToAdd,
  })
}

const addNewPropToNestedObject = (
  object: Record<string, any>,
  newPropKey: string,
  recordToAdd: Record<string, any>,
): Record<string, any> => {
  return {
    ...object,
    ...{ [newPropKey]: recordToAdd },
  }
}

const addNewPropToObject = (object: Record<string, any>, recordToAdd: Record<string, any>): Record<string, any> => {
  return {
    ...object,
    ...recordToAdd,
  }
}

export const recompileObject = (
  object: Record<string, any>,
  properties: string[],
  value: any,
  newProperty?: string,
): Record<string, any> => {
  const isNestedValue = properties.length > 1
  const valueToAdd = isNestedValue ? { [properties[1]]: value } : { [properties[0]]: value }

  if (!isNestedValue) {
    return addNewPropToObject(object, valueToAdd)
  }

  if (!newProperty) {
    return addNewPropToNestedObject(object, properties[0], valueToAdd)
  }

  object[newProperty] = addValuesToKey(object, newProperty, valueToAdd)

  return object
}
