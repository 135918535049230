import { createSlice } from '@reduxjs/toolkit'
import {
  readClientOrder,
  readClientOrderByIri,
  updateClientOrderOptions,
  updateClientOrderPaymentDiscount,
} from '../action'
import { ReadSliceState } from '../model/slice/ReadSliceState'
import ReadSliceReducer from '../model/slice/ReadSliceReducer'
import { readInitialState } from '../initialState/readState'
import { ClientOrderModelTotalsIncluded } from '@models/entities/clientOrders'
import { AnyAction } from 'redux'
import { actionVerifier } from '@utils/redux/actionVerifier'
import { FulfilledAction, RejectedAction } from '@utils/redux/action'

const verifier = actionVerifier([
  readClientOrder,
  readClientOrderByIri,
  updateClientOrderPaymentDiscount,
  updateClientOrderOptions,
])

const isFulfilledAction = (action: AnyAction): action is FulfilledAction<ClientOrderModelTotalsIncluded> => {
  return verifier.isFulfilledAction<ClientOrderModelTotalsIncluded>(action)
}

const readSlice = createSlice<ReadSliceState, ReadSliceReducer>({
  name: 'clientOrder_read',
  initialState: readInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(verifier.isPendingAction, (state: ReadSliceState) => {
        state.clientOrder = readInitialState.clientOrder
        state.isLoading = true
        state.error = readInitialState.error
      })
      .addMatcher(
        isFulfilledAction,
        (state: ReadSliceState, action: FulfilledAction<ClientOrderModelTotalsIncluded>) => {
          state.clientOrder = action.payload
          state.isLoading = false
          state.error = readInitialState.error
        },
      )
      .addMatcher(verifier.isRejectedAction, (state: ReadSliceState, action: RejectedAction) => {
        state.clientOrder = readInitialState.clientOrder
        state.isLoading = false
        state.error = action.payload.message
      })
  },
})

export default readSlice
