import { UploadType } from '@components/MediaUpload/config/UploadType'
import { ImageUploadTypes } from '@config/upload'

const getAcceptTypes = (uploadType: UploadType): string | undefined => {
  if (uploadType === UploadType.Image) {
    return ImageUploadTypes
  }
}

const getFirstUploadedFile = (files: FileList): File | undefined => {
  if (files.length === 0 || !files[0]) {
    return
  }

  return files[0]
}

export { getAcceptTypes, getFirstUploadedFile }
