import { AbstractFieldModel } from '@models/form/fields/FieldModels'
import { AsyncThunk } from '@reduxjs/toolkit'
import { FieldType } from '@app/enums/form/FieldType'
import { CreateNodeFieldCallback, NodeFieldModel } from '@components/Form/FormFields/model/TreeView'
import { TreeNodeModel } from '@models/form/tree/TreeNodeModel'
import { HasIdEntity } from '@models/entities/HasIdEntity'

export class TreeViewFieldModel extends AbstractFieldModel<FieldType.TreeView, TreeNodeModel<HasIdEntity> | undefined> {
  private _fillAction?: AsyncThunk<any, any, any>
  private _selectors?: any
  private _getParamsCallback: (node: number) => Record<string, any>
  private _mapToNodeModel: CreateNodeFieldCallback

  constructor() {
    super(FieldType.TreeView)

    this._fillAction = undefined
    this._selectors = undefined

    this._mapToNodeModel = () => {
      return new NodeFieldModel<Record<string, any>>('', 0, '0', undefined, [])
    }

    this._getParamsCallback = (value: any) => {
      return { value }
    }
  }

  get fillAction(): AsyncThunk<any, any, any> | undefined {
    return this._fillAction
  }

  set fillAction(value: AsyncThunk<any, any, any> | undefined) {
    this._fillAction = value
  }

  get selectors(): any {
    return this._selectors
  }

  set selectors(value: any) {
    this._selectors = value
  }

  get getParamsCallback(): (node: number, forChildren?: boolean) => Record<string, any> {
    return this._getParamsCallback
  }

  set getParamsCallback(value: (node: number, forChildren?: boolean) => Record<string, any>) {
    this._getParamsCallback = value
  }

  get mapToNodeModel(): CreateNodeFieldCallback {
    return this._mapToNodeModel
  }

  set mapToNodeModel(value: CreateNodeFieldCallback) {
    this._mapToNodeModel = value
  }
}
