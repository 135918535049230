import apiResponses from './apiResponses.json'
import articles from './articles.json'
import auth from './auth.json'
import brand from './brand.json'
import client from './client.json'
import clientOrder from './clientOrder.json'
import confirmationDialog from './confirmationDialog.json'
import dataTable from './dataTable.json'
import date from './date.json'
import errors from './errors.json'
import form from './form.json'
import global from './global.json'
import groupOrder from './groupOrder.json'
import idle from './idle.json'
import messages from './messages.json'
import sidebarView from './sidebarView.json'
import supplier from './supplier.json'
import validation from './validation.json'
import brandConditions from './brandConditions.json'
import settings from './settings.json'
import languages from './languages.json'
import header from './header.json'
import upload from './upload.json'

export default {
  apiResponses,
  articles,
  auth,
  brand,
  brandConditions,
  client,
  clientOrder,
  confirmationDialog,
  dataTable,
  date,
  errors,
  form,
  global,
  groupOrder,
  header,
  idle,
  messages,
  sidebarView,
  settings,
  supplier,
  validation,
  languages,
  upload,
}
