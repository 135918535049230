import React, { FC } from 'react'
import { v4 } from 'uuid'
import Row from '../Row'
import Loader from '../../Loader/Loader'
import { Column } from '../Model/Column'
import RowClickHandler from '../Props/RowClickHandler'

type Props = {
  isLoading: boolean
  objects: Record<string, unknown>[]
  columns: Column[]
  onRowClick?: RowClickHandler
  hover?: boolean
  highlightIfValue?: string
}

const Body: FC<Props> = ({ isLoading, objects, columns, onRowClick, highlightIfValue, hover = true }) => {
  if (isLoading) {
    return <Loader />
  }

  return (
    <div>
      {objects.map((object, index) => (
        <Row
          key={v4()}
          onClick={onRowClick}
          columns={columns}
          object={object}
          highlightIfValue={highlightIfValue}
          hover={hover}
          rowIndex={index}
        />
      ))}
    </div>
  )
}

export default Body
