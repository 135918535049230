import React, { FC } from 'react'
import { List } from '../../../../components/MaterialUI/core'
import NavListItem from './NavListItem'
import { useTranslation } from '../../../../utils/translation'
import { NavigationItemModel } from '../../../../app/models/navigation/NavigationItemModel'
import { makeStyles } from '../../../../components/MaterialUI/styles'

const useStyles = makeStyles(() => ({
  list: {
    height: '100%',
  },
}))

type Props = {
  level?: number
  childItems: NavigationItemModel[]
  open: boolean
}

const NavListOneLevel: FC<Props> = ({ level = 1, childItems = [], open }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <List disablePadding className={classes.list} component='div'>
      {childItems.map((item) => (
        <NavListItem
          isOpen={open}
          key={item.route}
          level={level}
          route={item.route}
          title={t(item.title)}
          icon={item.icon}
          childItems={item.childItems || []}
        />
      ))}
    </List>
  )
}

export default NavListOneLevel
