import { AsyncThunk } from '@reduxjs/toolkit'
import createApiAction from '@features/@common/actions/api-actions'
import * as api from '../api'
import { FetchParameters } from '../api'
import { parsePaginatedCollection } from '@utils/api/data'

const fetchGroupOrders: AsyncThunk<any, any, any> = createApiAction(
  'groupOrders.fetch',
  async (parameters: FetchParameters) => {
    const response = await api.fetchGroupOrders(parameters)

    return parsePaginatedCollection(response)
  },
)

const readGroupOrder: AsyncThunk<any, any, any> = createApiAction('groupOrders.read', api.readGroupOrder)

const createGroupOrder: AsyncThunk<any, any, any> = createApiAction('groupOrders.create', api.createGroupOrder)

const updateGroupOrderItems: AsyncThunk<any, any, any> = createApiAction(
  'groupOrders.update',
  api.updateGroupOrderItems,
)

const updateGroupOrderDelivery: AsyncThunk<any, any, any> = createApiAction(
  'grouporders.update.delivery',
  api.updateGroupOrderDelivery,
)

const updateGroupOrderHasClientDelivery: AsyncThunk<any, any, any> = createApiAction(
  'grouporders.update.hasClientDelivery',
  api.updateGroupOrderHasClientDelivery,
)

export {
  fetchGroupOrders,
  readGroupOrder,
  updateGroupOrderItems,
  createGroupOrder,
  updateGroupOrderDelivery,
  updateGroupOrderHasClientDelivery,
}
