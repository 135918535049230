import { Separator } from '@config/number'

const extractFirstDigit = (string: string): number | undefined => {
  const matched = string.match(/\d+/)

  // Oops, no numbers found
  if (matched === null || !Array.isArray(matched) || matched.length === 0) {
    return
  }

  return Number(matched[0])
}

const extractFirstNumber = (value: string, allowNegativeValues?: boolean): number | undefined => {
  if (value === '') {
    return
  }

  const pattern = (allowNegativeValues ? '-?' : '') + `\\b\\d[\\d${Separator.Decimal}${Separator.Thousand}]*\\b`
  const regex = new RegExp(pattern) // e.g. 123.456.789,010
  const matched = value.match(regex)

  if (matched === null || !Array.isArray(matched)) {
    return
  }

  const extracted = matched[0]

  // Remove separators of thousands
  // Example: 123456789,010
  const clean = extracted.replace(new RegExp(`\\${Separator.Thousand}`, 'g'), '')

  // Replace separator of decimals with a dot, the real/valid separator
  // Example: 123456789.010
  const result = clean.replace(new RegExp(`\\${Separator.Decimal}`), '.')

  // Example: 123456789.01
  return Number(result)
}

const extractFirstNumberAsString = (value: string): string => {
  const result = extractFirstNumber(value)

  if (result === undefined) {
    return ''
  }

  return result.toString()
}

export { extractFirstDigit, extractFirstNumber, extractFirstNumberAsString }
