import { createEntityAdapter } from '@reduxjs/toolkit'
import { UniqueSellingPointModel } from '@models/entities/uniqueSellingPoint/UniqueSellingPointModel'

const selectId = (entity: UniqueSellingPointModel) => entity.id

const entityAdapter = createEntityAdapter({
  selectId,
})

export { selectId, entityAdapter }
