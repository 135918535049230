import TabA11yProps from '../Type/TabA11yProps'

const a11yProps = (idPrefix: string, index: number): TabA11yProps => {
  return {
    id: `${idPrefix}-tab-${index}`,
    'aria-controls': `${idPrefix}-tabpanel-${index}`,
  }
}

export default a11yProps
