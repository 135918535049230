import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SliceState } from '@features/clientOrderAdditionalFees/model/slice/SliceState'
import { SliceReducers } from '@features/clientOrderAdditionalFees/model/slice/SliceReducers'
import { AdditionalFee } from '@models/entities/clientOrders'

const additionalFeesSlice = createSlice<SliceState, SliceReducers>({
  name: 'clientOrderAdditionalFees',
  initialState: {},
  reducers: {
    setAdditionalFees: (state: SliceState, action: PayloadAction<AdditionalFee[]>) => {
      state.additionalFees = action.payload
    },
  },
})

const additionalFeesActions = additionalFeesSlice.actions

export { additionalFeesSlice, additionalFeesActions }
