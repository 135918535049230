import { createSlice } from '@reduxjs/toolkit'
import { CollectionSliceState } from '@features/@common/slices/collection/CollectionSliceState'
import { CollectionSliceReducers } from '@features/@common/reducers/CollectionSliceReducers'
import { createCollectionInitialState } from '@features/@common/slices/collection/createCollectionInitialState'
import { fetchSuggestedUSPsForArticle } from '@features/uniqueSellingPoints/redux/action'
import { UniqueSellingPointModel } from '@models/entities/uniqueSellingPoint/UniqueSellingPointModel'
import { entityAdapter } from '@features/uniqueSellingPoints/redux/adapter'

const initialState = createCollectionInitialState<UniqueSellingPointModel>(entityAdapter)

export const mainsSliceForArticle = createSlice<
  CollectionSliceState<UniqueSellingPointModel>,
  CollectionSliceReducers<UniqueSellingPointModel>
>({
  name: 'uniqueSellingPoint.fetch',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSuggestedUSPsForArticle.pending.type]: (state) => {
      state.isLoading = true
      state.error = undefined
    },
    [fetchSuggestedUSPsForArticle.fulfilled.type]: (state, action) => {
      state.isLoading = false
      entityAdapter.upsertMany(state, action.payload.data)
    },
    [fetchSuggestedUSPsForArticle.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    },
  },
})
