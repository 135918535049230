import { FONT_SIZE, FONT_WEIGHT_BOLD, HTML_FONT_SIZE, SMALL_FONT_SIZE } from './constants'

const typography = {
  htmlFontSize: HTML_FONT_SIZE,
  fontSize: FONT_SIZE,
  smallFont: SMALL_FONT_SIZE,
  fontWeightBold: FONT_WEIGHT_BOLD,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
}

export default typography
