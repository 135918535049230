import React, { FC } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from '@utils/translation'
import { selectCurrentUserType } from '@features/auth/selector'
import Tile from './Tile'
import { makeStyles } from '@components/MaterialUI/styles'
import EmployeeType from '@features/auth/model/EmployeeType'
import supplierItems from '../Navigation/NavItems/supplierItems'
import items from '../Navigation/NavItems/userItems'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '10%',
  },
  tiles: {
    height: 'auto',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: 0,
    justifyContent: 'center',
    listStyle: 'none',
    maxHeight: '90%',
  },
}))

interface Props {
  userType?: EmployeeType
}

const TilesContainer: FC<Props> = ({ userType }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const userItems = userType === EmployeeType.SupplierEmployee ? supplierItems : items

  return (
    <div className={classes.root}>
      <div className={classes.title}>{t('sidebarView:dashboard.title')}</div>

      {userItems.length > 0 && (
        <ul className={classes.tiles}>
          {userItems.map(({ route, title, icon }) => (
            <Tile key={title} title={t(title)} route={route} icon={icon} />
          ))}
        </ul>
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  userType: selectCurrentUserType(state),
})

export default connect(mapStateToProps)(TilesContainer)
