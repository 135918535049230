import { createSlice } from '@reduxjs/toolkit'
import { updateArticle } from '../action'
import UpdateSliceState from '../model/slice/UpdateSliceState'
import UpdateSliceReducer from '../model/slice/UpdateSliceReducer'
import updateInitialState from '../initialState/updateState'

const updateSlice = createSlice<UpdateSliceState, UpdateSliceReducer>({
  name: 'article.update',
  initialState: updateInitialState,
  reducers: {},
  extraReducers: {
    [updateArticle.pending.type]: (state) => {
      state.updatedSuccessfully = updateInitialState.updatedSuccessfully
      state.isUpdating = updateInitialState.isUpdating
      state.error = updateInitialState.error
    },
    [updateArticle.fulfilled.type]: (state) => {
      state.updatedSuccessfully = true
      state.isUpdating = false
      state.error = updateInitialState.error
    },
    [updateArticle.rejected.type]: (state, action) => {
      state.updatedSuccessfully = updateInitialState.updatedSuccessfully
      state.isUpdating = false
      state.error = action.payload.errors
    },
  },
})

export default updateSlice
