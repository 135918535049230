import moment, { Moment } from 'moment'
import { isThisWeek, isToday } from 'date-fns'
import { DateFormat } from '../../../config/dateFormat'
import { formatDate, isBeforeToday, parseDate } from '../../../utils/date'
import { DateErrors } from './enums'
import { Day } from '../../../config/date'
import { MAX_YEAR, MIN_WEEK, MIN_YEAR, NOW } from './constants'
import localeConfiguration from '../../../config/locale'

type OptionType = {
  maxDate?: number
  dateExample?: string
}

export type DateErrorType = {
  value: string
  option?: OptionType
}

export const getDateError = (value: number): DateErrorType | undefined => {
  if (value === DateErrors.DATE_BEFORE_MINIMAL_DATE) {
    return { value: 'date:chooseDateAfterToday' }
  }

  if (value === DateErrors.DATE_AFTER_MAXIMAL_DATE) {
    return { value: 'date:chooseDateBeforeMaximalDate', option: { maxDate: MAX_YEAR } }
  }

  if (value === DateErrors.INVALID_DATE_FORMAT) {
    const dateExample = formatDate(NOW, DateFormat.WithoutTime) as string

    return { value: 'date:chooseValidDateFormat', option: { dateExample } }
  }

  if (value === DateErrors.WEEK_DOES_NOT_EXIST) {
    return { value: 'date:chooseValidWeek' }
  }

  if (value === DateErrors.NO_DELIVERIES_DURING_WEEKEND) {
    return { value: 'date:noDeliveriesDuringWeekend' }
  }

  if (value === DateErrors.NO_DELIVERIES_ON_SAME_DAY_AS_TODAY) {
    return { value: 'date:noDeliveriesForToday' }
  }

  if (value === DateErrors.INVALID_WEEK_PICKER_FORMAT) {
    const dateExample = `${MIN_YEAR}-${MIN_WEEK}`

    return { value: 'date:chooseValidWeekPickerFormat', option: { dateExample } }
  }
}

export const setFirstDayOfWeek = () => {
  const localeSpecification = {
    week: {
      dow: localeConfiguration.firstDayOfWeek,
    },
  }

  const currentLocale = moment.locale()
  moment.updateLocale(currentLocale, localeSpecification)
}

export const validate = (dateValue: string): DateErrorType | undefined => {
  const date = parseDate(dateValue, DateFormat.WithoutTime)
  const year = date.year()

  if (!date.isValid()) {
    return getDateError(DateErrors.INVALID_DATE_FORMAT)
  }

  if (year > MAX_YEAR) {
    return getDateError(DateErrors.DATE_AFTER_MAXIMAL_DATE)
  }

  if (year < NOW.year() || isBeforeToday(date)) {
    return getDateError(DateErrors.DATE_BEFORE_MINIMAL_DATE)
  }

  if (isThisWeek(date.toDate()) && NOW.day() >= Day.Friday) {
    return getDateError(DateErrors.NO_DELIVERIES_DURING_WEEKEND)
  }

  if (isToday(date.toDate())) {
    return getDateError(DateErrors.NO_DELIVERIES_ON_SAME_DAY_AS_TODAY)
  }
}

export const isBeforeTodayButInCurrentWeek = (value: Moment): boolean => {
  return isBeforeToday(value) && isThisWeek(value.toDate())
}
