import React, { FC } from 'react'
import { v4 as uuidv4 } from 'uuid'
import clsx from 'clsx'
import { Column } from '../Model/Column'
import Cell from '../Cell/Cell'
import { makeStyles } from '../../MaterialUI/styles'
import RowClickHandler from '../Props/RowClickHandler'
import { Theme } from '@components/MaterialUI/theme'
import { ActionsClasses } from '../Configuration/constants'
import { hasCssClass } from '@utils/html-element'
import { contains } from '@utils/string'
import useRoyalListService from '../Hook/useRoyalListService'
import { ClickEvent } from '@models/events/ClickEvent'

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
  },
  highlight: {
    backgroundColor: theme.palette.royal.highlight,
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.royal.lightGray,
    },
    '&:focus': {
      outline: 0,
      backgroundColor: theme.palette.royal.lightGray,
    },
  },
}))

type Props = {
  columns: Column[]
  object: Record<string, unknown>
  rowIndex: number
  onClick?: RowClickHandler
  highlightIfValue?: string
  hover?: boolean
}

const Row: FC<Props> = ({ columns, object, rowIndex, onClick, highlightIfValue, hover = true }) => {
  const classes = useStyles()
  const service = useRoyalListService()

  const handleClick = (event: ClickEvent) => {
    const clickedElement = event.target as HTMLElement

    // Do nothing if the "show actions" icon or particular action item was clicked
    if (isShowActionsIcon(clickedElement) || isHidden(clickedElement) || isActionItem(clickedElement)) {
      return
    }

    return onClick ? onClick(event, object) : null
  }

  const isShowActionsIcon = (clickedElement: HTMLElement): boolean => {
    if (clickedElement instanceof SVGElement) {
      return contains((clickedElement.className as SVGAnimatedString).baseVal, ActionsClasses.ShowIcon)
    }

    return false
  }

  const isHidden = (clickedElement: HTMLElement): boolean => {
    if (clickedElement && clickedElement.getAttribute) {
      return !!clickedElement.getAttribute('aria-hidden')
    }

    return false
  }

  const isActionItem = (clickedElement: HTMLElement): boolean => {
    if (clickedElement instanceof HTMLLIElement) {
      return hasCssClass(clickedElement, ActionsClasses.Item)
    }

    return false
  }

  const shouldHighlight = (): boolean => {
    return !!highlightIfValue && service.isHighlighted(object, highlightIfValue)
  }

  const rowClass = clsx(classes.row, {
    [classes.highlight]: shouldHighlight(),
    [classes.hover]: hover,
  })

  return (
    <div role={'button'} className={rowClass} tabIndex={0} onClick={handleClick} onKeyDown={handleClick}>
      {columns.map((column, index) => (
        <Cell key={uuidv4()} object={object} column={column} rowIndex={rowIndex} cellIndex={index} />
      ))}
    </div>
  )
}

export default Row
