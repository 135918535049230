import { createEntityAdapter } from '@reduxjs/toolkit'
import { ClientOrderItemModel } from '@models/entities/clientOrders'

const selectId = (entity: ClientOrderItemModel) => entity.id

const entityAdapter = createEntityAdapter<ClientOrderItemModel>({
  selectId,
})

export { selectId, entityAdapter }
