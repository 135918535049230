import createApiAction from '@features/@common/actions/api-actions'
import { parsePaginatedCollection } from '@utils/api/data'
import * as api from '../api'
import { CreateParameters, DeleteParameters, FetchParameters } from '../api'
import { UpdateParameters } from '@features/clientOrderItems/api/model'

const fetchClientOrderItems = createApiAction<any, FetchParameters, any>(
  'clientOrderItems.fetch',
  async (parameters: FetchParameters) => {
    const response = await api.fetchClientOrderItems(parameters)

    return parsePaginatedCollection(response)
  },
)

const createClientOrderItem = createApiAction<any, CreateParameters, any>(
  'clientOrderItems.create',
  api.createClientOrderItem,
)

const updateClientOrderItem = createApiAction<any, UpdateParameters, any>(
  'clientOrderItems.update',
  api.updateClientOrderItem,
)

const deleteClientOrderItem = createApiAction<any, DeleteParameters, any>(
  'clientOrderItems.delete',
  api.deleteClientOrderItem,
)

export { fetchClientOrderItems, createClientOrderItem, updateClientOrderItem, deleteClientOrderItem }
