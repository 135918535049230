import DeleteSliceState from '../model/slice/DeleteSliceState'
import { entityAdapter } from '@features/clientHasBrandConditions/redux/adapter'

const deleteInitialState: DeleteSliceState = {
  ...entityAdapter.getInitialState(),
  isLoading: true,
  deletedId: undefined,
  deleteSuccess: false,
  error: undefined,
}

export default deleteInitialState
