import { CSSProperties } from '@material-ui/core/styles/withStyles'
import typography from '@theme/typography'
import palette from '@theme/palette'

const row: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
}

const line: CSSProperties = {
  lineHeight: '30px',
  marginRight: '3px',
  width: 'calc(50% - 15px)',
}

const indent: CSSProperties = {
  marginLeft: '30px',
  width: 'calc(50% - 45px)',
}

const textArea: CSSProperties = {
  width: '100%',
  resize: 'none',
  minHeight: '70px',
  overflow: 'auto',
  fontSize: typography.fontSize,
  fontFamily: typography.fontFamily,
  backgroundColor: palette.royal.nearlyWhite,
  '&:focus': {
    outline: 'none',
  },
}

const bottomMargin: CSSProperties = {
  marginBottom: '20px',
}

interface FormComponentStylesType {
  row: CSSProperties
  line: CSSProperties
  indent: CSSProperties
  textArea: CSSProperties
  bottomMargin: CSSProperties
}

const LabeledStyles: FormComponentStylesType = {
  row,
  line,
  indent,
  textArea,
  bottomMargin,
}

export default LabeledStyles
