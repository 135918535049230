import { entityAdapter } from '../adapter'

const selectState = (state: any) => state.mediaObjects.create
const selectors = entityAdapter.getSelectors(selectState)
const selectNewMediaObject = (state: any) => selectState(state).success

export default {
  ...selectors,
  selectNewMediaObject,
}
