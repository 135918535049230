import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from '@utils/translation'

const localeMap = {
  fr: 'fr-FR',
  nl: 'nl-NL',
  en: 'en-GB',
  'en-US': 'en-US',
}

const Price = ({ value, currency }) => {
  const { i18n } = useTranslation()
  const formatter = useMemo(() => {
    const locale = localeMap[i18n.language]
    if (!locale) {
      throw new Error('Could not resolve locale code for lang: ', i18n.language)
    }

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    })
  }, [currency, i18n.language])

  return formatter.format(value)
}

Price.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  currency: PropTypes.string,
}

Price.defaultProps = {
  currency: 'EUR',
}

export default Price
