import http from '@utils/http'
import { generateUrl } from '@utils/route'
import { GetItemsPaginatedParameters } from '@models/api/request'

const BRANDS_API_URL = '/api/brands'
export const BRAND_API_URL = '/api/brands/:id(\\d+)'
const UPDATE_BRAND_API_URL = '/api/brands/:id(\\d+)'
const UPDATE_BRAND_CONDITION_API_URL = '/api/brand_condition_items/:id(\\d+)'

interface UpdateParameters {
  id: number
  changed: {
    position?: number
    percentage?: number
  }
}

interface UpdateBrandParameters {
  id: number
  changed: {
    hasOrderAttachment?: boolean
    isOnBackOrderList?: boolean
  }
}

export interface FetchBrandParams extends GetItemsPaginatedParameters {
  name: string
}

const fetchBrands = async (parameters: FetchBrandParams) => {
  const url = generateUrl(BRANDS_API_URL)

  return http.get(url, {
    headers: parameters.headers,
    params: {
      notAssignedToClient: parameters.id,
      ...parameters.pagination,
      ...parameters.sorting,
      extra: parameters.extra,
      name: parameters.name,
      include: ['supplier', 'clientChannels'],
    },
  })
}

const updateBrand = async (parameters: UpdateBrandParameters) => {
  const url = generateUrl(UPDATE_BRAND_API_URL, { id: parameters.id })
  return http.patch(url, parameters.changed)
}

const updateBrandConditionItem = async (parameters: UpdateParameters) => {
  const url = generateUrl(UPDATE_BRAND_CONDITION_API_URL, { id: parameters.id })

  return http.patch(url, parameters.changed)
}

const readBrand = async (id: number) => {
  const url = generateUrl(BRAND_API_URL, { id })

  return http.get(url, {
    params: {
      include: [
        'representatives',
        'supplier',
        'translations',
        'brandSuppliers',
        'clientChannels',
        'coreRangeCondition',
      ],
    },
  })
}

export type { UpdateParameters, UpdateBrandParameters }
export { fetchBrands, readBrand, updateBrandConditionItem, updateBrand }
