import React, { FC } from 'react'
import { Dialog } from './Dialog'
import { useTranslation } from '@utils/translation'
import DialogProps from '@components/Dialog/model/DialogProps'

type Props = Omit<DialogProps, 'title' | 'text' | 'buttonText'>

export const ConfirmationDialog: FC<Props> = ({ isOpened, onCancel, onConfirm }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      title={t('confirmationDialog:title')}
      text={t('confirmationDialog:text')}
      buttonText={{
        confirmation: t('global:yes'),
        cancel: t('global:no'),
      }}
      isOpened={isOpened}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  )
}
