import { EntityState } from '@reduxjs/toolkit'
import { DeliveryAddressModel } from '@models/entities/addresses/DeliveryAddressModel'
import { entityAdapter } from '../../adapter'

interface SliceStateModel extends EntityState<DeliveryAddressModel> {
  isLoading: boolean
  isUpdating: boolean
  updatedSuccessfully: boolean
  error?: string
}

const initialState: SliceStateModel = {
  ...entityAdapter.getInitialState(),
  isLoading: true,
  isUpdating: false,
  updatedSuccessfully: false,
  error: undefined,
}

export { initialState }
export type { SliceStateModel }
