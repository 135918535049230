import React, { FC } from 'react'
import { connect } from 'react-redux'
import { selectCurrentUserType } from '@features/auth/selector'
import NavListOneLevel from './NavListOneLevel'
import supplierItems from './NavItems/supplierItems'
import clientItems from './NavItems/clientItems'
import EmployeeType from '@features/auth/model/EmployeeType'

type Props = {
  open: boolean
  userType?: EmployeeType
}
const NavList: FC<Props> = ({ open, userType }) => {
  const userItems = userType === EmployeeType.SupplierEmployee ? supplierItems : clientItems

  return <NavListOneLevel open={open} childItems={userItems} />
}

const mapStateToProps = (state: any) => ({
  userType: selectCurrentUserType(state),
})

export default connect(mapStateToProps)(NavList)
