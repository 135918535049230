import React, { FC, ReactElement } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { RoyalTooltipDelay, TooltipDirection } from './config/index'
import { makeStyles } from '../MaterialUI/styles'
import TooltipStyle from './TooltipStyle'
import { Fade } from '../MaterialUI/core'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'inline-block',
    position: 'relative',
    width: 'fit-value',
    height: 'fit-value',
  },
  tip: {
    ...TooltipStyle.tip,
  },
  left: {
    ...TooltipStyle.left,
  },
  right: {
    ...TooltipStyle.right,
  },
  top: {
    ...TooltipStyle.top,
  },
  bottom: {
    ...TooltipStyle.bottom,
  },
}))

interface Props {
  value?: string | ReactElement
  direction?: TooltipDirection
  delay?: RoyalTooltipDelay
  className?: string
}

const RoyalTooltip: FC<Props> = ({
  children,
  value,
  delay = RoyalTooltipDelay.MEDIUM,
  direction = TooltipDirection.BOTTOM,
  className,
}) => {
  const childrenElement = children as ReactElement
  const classes = useStyles()

  if (!children) {
    return <></>
  }

  if (!value) {
    return childrenElement
  }

  const cssClasses = clsx(
    classes.tip,
    {
      [classes.left]: direction === TooltipDirection.LEFT,
      [classes.right]: direction === TooltipDirection.RIGHT,
      [classes.top]: direction === TooltipDirection.TOP,
      [classes.bottom]: direction === TooltipDirection.BOTTOM,
    },
    className,
  )

  return (
    <Tooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: delay }}
      placement={direction}
      title={
        <Typography className={cssClasses} color='inherit' component='span'>
          {value}
        </Typography>
      }
    >
      <Typography component='span'>{childrenElement}</Typography>
    </Tooltip>
  )
}

export default RoyalTooltip
