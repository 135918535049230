import React, { FC } from 'react'
import { useController } from 'react-hook-form'
import { LabeledTextField } from '@components/Form/Labeled'
import { FormFieldProps } from '@components/Form/FormFields'
import { mergeRules } from '@utils/form/rules/mergeRules'
import { getInputName } from '@utils/form/fields/getInputName'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'

interface Props extends FormFieldProps {
  minRows?: number
}

const FormTextField: FC<Props> = ({ formField, control, disabled, sectionLabel, minRows }) => {
  const name = getInputName(formField, sectionLabel)
  const rules = mergeRules(formField.rules) as RegisterOptions

  const { field, fieldState } = useController({
    name,
    rules,
    control,
    defaultValue: formField.value,
  })

  return (
    <LabeledTextField
      {...field}
      labelText={formField.label}
      disabled={disabled}
      showIfEmptyValue={!disabled}
      error={fieldState.error}
      minRows={minRows}
    />
  )
}

export default FormTextField
