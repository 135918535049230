import { entityAdapter } from '../adapter'
import UpSellerSliceState from '../model/slice/UpSellerSliceState'

const upSellersInitialState: UpSellerSliceState = {
  ...entityAdapter.getInitialState(),
  isLoading: true,
  error: undefined,
}

export default upSellersInitialState
