import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit'
import SliceState from './model/SliceState'

type SliceReducers = SliceCaseReducers<SliceState>

const initialState: SliceState = {
  type: null,
  object: null,
}

const slice = createSlice<SliceState, SliceReducers>({
  name: 'selectedObject',
  initialState,
  reducers: {
    showDetails: (state, action) => {
      const { type, object } = action.payload

      state.type = type
      state.object = object
    },
    hideDetails: (state) => {
      state.type = null
      state.object = null
    },
  },
})

const { actions } = slice

export { slice, actions }
