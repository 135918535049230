import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { fetchCurrentUser, login, logout } from '../action'
import authStorage from '../storage'
import { User } from '../model/User'

interface AuthReducerState {
  user: User | null
  isLoading: boolean
  isBooting: boolean
}

type AuthReducerPayloadAction = PayloadAction<User>

const initialState: AuthReducerState = {
  user: null,
  isLoading: true,
  isBooting: !!authStorage.getData(),
}

const authReducer = createReducer<AuthReducerState>(initialState, {
  [login.pending.type]: (state: AuthReducerState) => {
    state.isLoading = true
  },
  [login.fulfilled.type]: (state: AuthReducerState) => {
    state.isLoading = false
  },
  [login.rejected.type]: (state: AuthReducerState) => {
    state.isLoading = false
    state.user = null
  },
  [logout.fulfilled.type]: (state: AuthReducerState) => {
    state.isBooting = false
    state.user = null
  },
  [fetchCurrentUser.pending.type]: (state: AuthReducerState) => {
    state.isLoading = true
  },
  [fetchCurrentUser.fulfilled.type]: (state: AuthReducerState, action: AuthReducerPayloadAction) => {
    const { id, email, roles, person } = action.payload

    state.isLoading = false
    state.isBooting = false

    state.user = {
      '@id': action.payload['@id'],
      '@type': action.payload['@type'],
      id,
      email,
      roles,
      person,
    }
  },
  [fetchCurrentUser.rejected.type]: (state: AuthReducerState) => {
    state.isLoading = false
    state.isBooting = false

    state.user = null
  },
})

export default authReducer
