import { noArrowsInputStyle } from '../custom'

export default {
  root: {
    margin: '4px 0',
  },
  input: {
    color: 'primary',
    '&[type=number]': {
      '&.without-arrows': {
        ...noArrowsInputStyle,
      },
    },
  },
}
