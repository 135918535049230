import { generateUrl } from '@utils/route'
import http from '@utils/http'
import { GetItemsPaginatedParameters } from '@models/api/request'

type CreateParameters = {
  hasBuilderPrice?: boolean
  client: string
  brandCondition?: string
  brand: string
}

const FETCH_BRAND_CONDITIONS_FOR_CLIENT_API_URL = '/api/client_has_brand_conditions'
const CREATE_BRAND_CONDITIONS_API_URL = '/api/client_has_brand_conditions'
const REMOVE_BRAND_CONDITIONS_API_URL = '/api/client_has_brand_conditions/:id(\\d+)'

const fetchClientHasBrandConditionsForBrand = async (parameters: GetItemsPaginatedParameters) => {
  const url = generateUrl(FETCH_BRAND_CONDITIONS_FOR_CLIENT_API_URL)
  const additionalId = Number(parameters.extra?.additionalId)

  return http.get(url, {
    params: {
      ...parameters.pagination,
      ...parameters.sorting,
      'brandCondition.brand.id': parameters.id,
      'brandCondition.id': additionalId,
      include: ['client'],
    },
  })
}

export interface FetchBrandConditionForClientParams extends GetItemsPaginatedParameters {
  'brand.name': string
  'currentPrice.retailPrice': string
}

const fetchClientHasBrandConditionsForClient = async (parameters: FetchBrandConditionForClientParams) => {
  const url = generateUrl(FETCH_BRAND_CONDITIONS_FOR_CLIENT_API_URL)

  return http.get(url, {
    params: {
      ...parameters.pagination,
      ...parameters.sorting,
      'brand.name': parameters['brand.name'],
      'currentPrice.retailPrice': parameters['currentPrice.retailPrice'],
      'client.id': parameters.id,
      include: ['client', 'brandCondition', 'brandCondition.items', 'brand'],
    },
  })
}

const createClientHasBrandCondition = async (parameters: CreateParameters) => {
  const url = generateUrl(CREATE_BRAND_CONDITIONS_API_URL)
  return http.post(url, parameters)
}

const deleteClientHasBrandCondition = async (id: number) => {
  const url = generateUrl(REMOVE_BRAND_CONDITIONS_API_URL, { id })
  return http.delete(url)
}

export type { CreateParameters }

export {
  fetchClientHasBrandConditionsForBrand,
  fetchClientHasBrandConditionsForClient,
  createClientHasBrandCondition,
  deleteClientHasBrandCondition,
}
