import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { selectCurrentUser } from '../selector'
import { AuthUserType } from '../prop-types'
import { DASHBOARD_PATH } from '@config/routes/paths'

const GuestGuard = (props) => {
  const { children, user } = props
  const history = useHistory()

  useEffect(() => {
    if (user) {
      history.push(DASHBOARD_PATH)
    }
  }, [history, user])

  if (user) {
    return null
  }

  return children
}

GuestGuard.propTypes = {
  user: AuthUserType,
}

const mapStateToProps = (state) => ({
  user: selectCurrentUser(state),
})

export default connect(mapStateToProps)(GuestGuard)
