export enum ArticleFormFieldName {
  Empty = '',
  Category = 'category',
  Attributes = 'attributes',
  UniqueSellingPoints = 'uniqueSellingPoints',
  Color = 'color',
  Language = 'language',
  Description = 'description',
  DescriptionShort = 'descriptionShort',
  Title = 'title',
  Depth = 'depth',
  Height = 'height',
  Weight = 'weight',
  Width = 'width',
  Appliance = 'appliance',
  Package = 'package',
  NicheMax = 'nicheMax',
  NicheMin = 'nicheMin',
}
