import { useState } from 'react'

export const useVisibility = (initialState = false) => {
  const [isVisible, setVisibility] = useState<boolean>(initialState)

  const show = () => {
    setVisibility(true)
  }

  const hide = () => {
    setVisibility(false)
  }

  return {
    isVisible,
    show,
    hide,
  }
}
