import {
  CATALOGUE_PATH,
  CLIENT_ORDERS_PATH,
  CUSTOMERS_PATH,
  PUBLICITY_PATH,
  REPORTS_PATH,
  SUPPLIER_CREATE_PATH,
} from '@config/routes/paths'
import {
  AssessmentIcon,
  CatalogueIcon,
  DashboardIcon,
  FolderIcon,
  LocalShippingIcon,
  ShoppingCartIcon,
} from '@components/MaterialUI/icons'
import { generateUrl } from '@utils/route'
import { NavigationItemModel } from '@models/navigation/NavigationItemModel'

const items: NavigationItemModel[] = [
  {
    route: generateUrl(CUSTOMERS_PATH),
    title: 'sidebar.dashboard',
    icon: DashboardIcon,
  },
  {
    route: generateUrl(CLIENT_ORDERS_PATH),
    title: 'sidebar.order',
    icon: ShoppingCartIcon,
  },
  {
    route: generateUrl(SUPPLIER_CREATE_PATH),
    title: 'sidebar.supplier',
    icon: LocalShippingIcon,
  },
  {
    route: generateUrl(CATALOGUE_PATH),
    title: 'sidebar.catalogue',
    icon: FolderIcon,
  },
  {
    route: generateUrl(REPORTS_PATH),
    title: 'sidebar.report',
    icon: AssessmentIcon,
  },
  {
    route: generateUrl(PUBLICITY_PATH),
    title: 'sidebar.publicity',
    icon: CatalogueIcon,
  },
]

export default items
