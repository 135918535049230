import { generateUrl } from '@utils/route'
import http from '@utils/http'

const ARTICLE_PRICES_API_URL = '/api/articles/:id(\\d+)/prices'

const readPrices = async (id: number) => {
  const url = generateUrl(ARTICLE_PRICES_API_URL, { id })
  return http.get(url)
}

export { readPrices }
