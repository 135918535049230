import React, { Fragment, useEffect, useState } from 'react'
import { useSortBy, useTable } from 'react-table'
import PropTypes from 'prop-types'
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '../MaterialUI/core'
import { makeStyles } from '../MaterialUI/styles'
import Loader from '../Loader/Loader'
import { DEFAULT_PAGE_NUMBER, DEFAULT_PER_PAGE } from '@config/pagination'
import { useTranslation } from '@utils/translation'
import {
  getRowId,
  getRowSelectionOptions,
  handleCheckboxClick,
  isRowSelected,
  toggleRowSelection,
} from './RowSelection'
import getRowClickHandler from './RowClick'
import { RoyalPagination } from '..'
import { SortingDirection } from '@config/sorting'

const useStyles = (settings) =>
  makeStyles((theme) => ({
    root: {
      position: 'relative',
      '& a ': {
        color: 'inherit',
        textDecoration: 'none',
      },
    },
    loadingOverlay: {
      ...theme.mixins.overlay,
      ...theme.mixins.centered,
    },
    rowSelectionHeader: {
      paddingRight: 0,
    },
    rowSelectionBody: {
      textAlign: 'center',
      width: '30px',
    },
    rowSelectionFooter: {
      paddingRight: 0,
      whiteSpace: 'nowrap',
    },
    row: {
      cursor: settings.rowClick.enabled ? 'pointer' : 'default',
    },
  }))

const DataTable = ({
  isLoading,
  columns,
  data,
  showHeaders,
  initialSortBy,
  afterRow,
  paginationOptions,
  rowSelection,
  rowClick,
  onSortByChange,
  ...rest
}) => {
  const classes = useStyles({ rowClick })()
  const { t } = useTranslation()
  const [selectedRowsIds, setSelectedRowsIds] = useState([])
  const rowSelectionOptions = getRowSelectionOptions(rowSelection)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: initialSortBy,
      },
      ...rest,
    },
    useSortBy,
  )

  useEffect(() => {
    if (onSortByChange) {
      onSortByChange(sortBy)
    }
  }, [onSortByChange, sortBy])

  const getSortingDirection = (column) => {
    return column.isSortedDesc ? SortingDirection.Descending : SortingDirection.Ascending
  }

  return (
    <TableContainer className={classes.root} component={Paper}>
      <Table {...getTableProps()}>
        {showHeaders && (
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {rowSelectionOptions.enabled && (
                  <TableCell variant='head' className={classes.rowSelectionHeader}>
                    {t('dataTable:columns.selected')}
                  </TableCell>
                )}

                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.canSort ? (
                      <TableSortLabel active={column.isSorted} direction={getSortingDirection(column)}>
                        {column.render('Header')}
                      </TableSortLabel>
                    ) : (
                      column.render('Header')
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
        )}

        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const rowsProps = row.getRowProps()
            const rowId = rowSelectionOptions.getRowId(row)
            const rowIsSelected = rowSelectionOptions.enabled && isRowSelected(rowId, selectedRowsIds)

            return (
              <Fragment key={rowsProps.key}>
                <TableRow
                  hover
                  {...rowsProps}
                  selected={rowIsSelected}
                  onClick={getRowClickHandler(row, rowClick.enabled, rowClick.onRowClick)}
                  className={classes.row}
                >
                  {rowSelectionOptions.enabled && rowId && (
                    <TableCell padding='none' variant='body' className={classes.rowSelectionBody}>
                      <Checkbox
                        checked={rowIsSelected}
                        onClick={handleCheckboxClick}
                        onChange={(event, isChecked) => {
                          return toggleRowSelection(isChecked, rowId, selectedRowsIds, setSelectedRowsIds)
                        }}
                      />
                    </TableCell>
                  )}

                  {row.cells.map((cell) => (
                    <TableCell {...cell.getCellProps()} {...(cell.column.cellProps || {})}>
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>

                {afterRow && afterRow(row)}
              </Fragment>
            )
          })}
        </TableBody>

        {paginationOptions && (
          <TableFooter>
            <TableRow>
              <TableCell variant='footer' className={classes.rowSelectionFooter}>
                <Typography variant='body2' color='textPrimary'>
                  {t('dataTable:info.selected_rows_count')} {selectedRowsIds.length}
                </Typography>
              </TableCell>
              <RoyalPagination isLoading={isLoading} options={paginationOptions} columnsCount={columns.length} />
            </TableRow>
          </TableFooter>
        )}
      </Table>

      {isLoading && (
        <div className={classes.loadingOverlay}>
          <Loader />
        </div>
      )}
    </TableContainer>
  )
}

DataTable.propTypes = {
  isLoading: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      Cell: PropTypes.func,
    }),
  ).isRequired,
  data: PropTypes.array.isRequired,
  showHeaders: PropTypes.bool,
  initialSortBy: PropTypes.array,
  afterRow: PropTypes.func,
  paginationOptions: PropTypes.shape({
    page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    perPage: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
    onChangePage: PropTypes.func,
    onChangeRowsPerPage: PropTypes.func,
  }).isRequired,
  rowSelection: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    getRowId: PropTypes.func,
  }),
  rowClick: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    onRowClick: PropTypes.func,
  }),
  onSortByChange: PropTypes.func,
}

DataTable.defaultProps = {
  isLoading: false,
  showHeaders: true,
  initialSortBy: [],
  paginationOptions: {
    page: DEFAULT_PAGE_NUMBER,
    perPage: DEFAULT_PER_PAGE,
    totalItems: 0,
  },
  rowSelection: {
    enabled: false,
    getRowId: (row) => getRowId(row),
  },
  rowClick: {
    enabled: false,
  },
}

export default DataTable
