export default {
  paperWidthSm: {
    maxWidth: '50%',
    minWidth: '700px',
    width: 'fit-content',
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
}
