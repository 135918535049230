import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { fetchEmailTypes, readEmailType } from './action'

export const selectEmailType = (item) => {
  return item.type
}

export const emailTypesAdapter = createEntityAdapter({
  selectId: selectEmailType,
})

const initialState = {
  ...emailTypesAdapter.getInitialState(),
  isLoading: true,
  items: [],
  error: null,
}

export const emailAddressTypesSlice = createSlice({
  name: 'emailAddressTypes',
  initialState,
  extraReducers: {
    [fetchEmailTypes.pending.type]: (state) => {
      state.isLoading = true
      state.error = null
    },
    [fetchEmailTypes.fulfilled.type]: (state, action) => {
      state.isLoading = false
      state.items = action.payload.data.map(selectEmailType)
      emailTypesAdapter.addMany(state, action.payload.data)
    },
    [fetchEmailTypes.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [readEmailType.fulfilled.type]: (state, action) => {
      emailTypesAdapter.upsertOne(state, action.payload)
    },
  },
})
