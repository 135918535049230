import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useTranslation } from '@utils/translation'
import localeConfiguration from '../../config/locale'

const LocaleUpdater = () => {
  const { i18n } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const lang = location.pathname.split('/')
    const currentLang = i18n.language.split('-')[0]

    if (typeof lang[1] !== 'undefined') {
      const code = lang[1]
      if (localeConfiguration.locales.includes(code) && code !== currentLang) {
        lang[1] = currentLang
        history.push(lang.join('/'))
      }
    }
  }, [i18n.language, history, location])

  return null
}

export default LocaleUpdater
