import React, { FC, forwardRef, KeyboardEvent, useEffect } from 'react'
import { SlideProps } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'
import clsx from 'clsx'
import { Theme } from '@components/MaterialUI/theme'
import { createStyles, makeStyles } from '@components/MaterialUI/styles'
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@components/MaterialUI/core'
import DialogProps from '@components/Dialog/model/DialogProps'
import { useVisibility } from '@hooks/toggle/useVisibility'

const ENTER_KEY = 'enter'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      borderRadius: theme.shape.borderRadius,
      border: '1px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    title: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    buttonRow: {
      borderTop: `1px solid ${theme.palette.divider}`,
      paddingTop: '15px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    children: {
      margin: '10px',
      display: 'flex',
      justifyContent: 'center',
    },
    actionButton: {
      width: '100px',
      height: '30px',
    },
  }),
)

const Transition = forwardRef<unknown, SlideProps>((props, ref) => <Slide direction='down' ref={ref} {...props} />)

export const Dialog: FC<DialogProps> = ({
  isOpened,
  onCancel,
  onConfirm,
  text,
  title,
  children,
  buttonText,
  className,
  displayButtons = true,
  confirmOnEnterPress = true,
}) => {
  const classes = useStyles()
  const { isVisible, show, hide } = useVisibility(isOpened)
  const dialogContentId = uuidv4()
  const dialogTitleId = uuidv4()
  const modalStyle = clsx(classes.modal, className)

  const handleConfirm = () => {
    if (!onConfirm) {
      return
    }

    onConfirm()
  }

  const handleCancel = () => {
    hide()
    onCancel()
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    // Handle "Enter" key only, if it's enabled, and treat it like click on the "confirm" button
    if (!confirmOnEnterPress || event.key.toLowerCase() !== ENTER_KEY) {
      return
    }

    handleConfirm()
  }

  useEffect(() => {
    if (isOpened) {
      show()
      return
    }

    hide()
  }, [isOpened, show, hide])

  return (
    <div className={modalStyle}>
      <MuiDialog
        className={classes.paper}
        open={isVisible}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCancel}
        aria-labelledby={dialogTitleId}
        aria-describedby={dialogContentId}
        onKeyPress={handleKeyPress}
      >
        {title && (
          <DialogTitle className={classes.title} id={dialogTitleId}>
            {title}
          </DialogTitle>
        )}

        {text && (
          <DialogContent>
            <DialogContentText id={dialogContentId}>{text}</DialogContentText>
          </DialogContent>
        )}

        {children && <div className={classes.children}>{children}</div>}

        {displayButtons && buttonText && (
          <DialogActions className={classes.buttonRow}>
            <Button
              className={classes.actionButton}
              title={title}
              onClick={handleConfirm}
              color='primary'
              variant='outlined'
            >
              {buttonText.confirmation}
            </Button>
            <Button className={classes.actionButton} onClick={handleCancel} variant='outlined'>
              {buttonText.cancel}
            </Button>
          </DialogActions>
        )}
      </MuiDialog>
    </div>
  )
}
