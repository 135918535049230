import { createSlice } from '@reduxjs/toolkit'
import { readArticleUpSellers } from '../action'
import { entityAdapter } from '../adapter'
import UpSellerSliceState from '../model/slice/UpSellerSliceState'
import UpSellerReducer from '../model/slice/UpSellerReducer'
import upSellersInitialState from '../initialState/upSellerState'

const upSellerSlice = createSlice<UpSellerSliceState, UpSellerReducer>({
  name: 'articles.readMany.upSellers',
  initialState: upSellersInitialState,
  reducers: {},
  extraReducers: {
    [readArticleUpSellers.pending.type]: (state) => {
      state.isLoading = upSellersInitialState.isLoading
      entityAdapter.removeAll(state)
      state.error = upSellersInitialState.error
    },
    [readArticleUpSellers.fulfilled.type]: (state, action) => {
      entityAdapter.upsertMany(state, action.payload.data)
      state.isLoading = false
      state.error = upSellersInitialState.error
    },
    [readArticleUpSellers.rejected.type]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default upSellerSlice
