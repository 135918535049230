import { getKeyAsStringFromObject } from '@utils/form/submitData/getKeyAsStringFromObject'
import { recompileObject } from '@utils/form/submitData/recompileObject'
import { isEmpty } from 'lodash'

export const getFormDataObject = (data: Record<string, any>): Record<string, any> => {
  if (isEmpty(data)) {
    return {}
  }

  let result: Record<string, any> = {}

  Object.entries(data).forEach(([property, value]) => {
    const keys = property.split('-')
    const object = getKeyAsStringFromObject(result, keys[0])

    result = recompileObject(result, keys, value, object)
  })

  return result
}
