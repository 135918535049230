import React, { FC, MutableRefObject, useEffect, useState } from 'react'
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase'
import { TextField } from '@components/MaterialUI/core'
import { MinMax } from '@config/number'
import { formatWithDecimals } from '@utils/numbers'
import { makeStyles } from '@components/MaterialUI/styles'
import clsx from 'clsx'
import { FieldError } from 'react-hook-form'
import { useErrorMessage } from '@utils/form/errors/useErrorMessage'

const useStyles = makeStyles(() => ({
  textField: {
    '& input': {
      textAlign: 'right',
    },
  },
}))

interface Props {
  id: string
  inputRef: MutableRefObject<HTMLInputElement | undefined>
  value: number
  inputComponent: FC
  amountOfDecimals: number
  error?: FieldError
  disabled?: boolean
  inputProps?: InputBaseComponentProps
  className?: string
}

const NumberInputDisplay: FC<Props> = ({
  id,
  inputRef,
  disabled = false,
  value,
  inputComponent,
  className = '',
  inputProps,
  amountOfDecimals,
  error,
}) => {
  const classes = useStyles()
  const message = useErrorMessage(error)
  const [errorMessage, setErrorMessage] = useState<undefined | string>(message)

  useEffect(() => {
    setErrorMessage(message)
  }, [message])

  return (
    <TextField
      error={!!errorMessage}
      id={id}
      inputRef={inputRef}
      helperText={errorMessage}
      className={clsx(className, classes.textField)}
      defaultValue={formatWithDecimals(value, amountOfDecimals)}
      disabled={disabled}
      InputProps={{
        inputComponent: inputComponent,
        disableUnderline: true,
        'aria-valuemin': MinMax.DefaultMinimum,
      }}
      inputProps={{
        ...inputProps,
      }}
    />
  )
}

export default NumberInputDisplay
