import useClientIri from './use-client-iri'
import { extractFirstDigit } from '../../../utils/regex'

const useClientId = (): number | undefined => {
  const { clientIri } = useClientIri()

  return extractFirstDigit(clientIri as string)
}

export default useClientId
