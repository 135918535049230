import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export default function useUrlParam(paramName) {
  const location = useLocation()

  const getParamRaw = useCallback(
    (values) => {
      const params = new URLSearchParams()
      params.append(paramName, btoa(JSON.stringify(values)))

      return params.toString()
    },
    [paramName],
  )

  const values = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const filtersRaw = params.get(paramName)

    try {
      return JSON.parse(atob(filtersRaw))
    } catch (e) {
      return {}
    }
  }, [location.search, paramName])

  return [values, getParamRaw]
}
