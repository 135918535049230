import React, { FC } from 'react'
import clsx from 'clsx'
import { GridCell, GridCellType } from '../cell'
import { makeStyles } from '../../MaterialUI/styles'
import GridStyles from './contentComponents/gridStyles/GridStyles'
import { useTranslation } from '../../../utils/translation'
import { Button } from '../../MaterialUI/core'

export type GridItemProps = {
  object: Record<string, unknown>
  cell: GridCellType
  toggleButtonShow: (cell: GridCellType) => void
  isVisible: boolean
}

const useStyles = makeStyles(() => ({
  section: {
    ...GridStyles.section,
  },
  listItem: {
    ...GridStyles.listItem,
  },
  visible: {
    ...GridStyles.visible,
  },
  button: {
    ...GridStyles.button,
  },
  inVisible: {
    ...GridStyles.inVisible,
  },
}))

const GridItem: FC<GridItemProps> = ({ object, cell, toggleButtonShow }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleToggleOpen = () => {
    toggleButtonShow(cell)
  }

  const cssClasses = clsx({
    [classes.button]: !cell.isOpen,
    [classes.listItem]: cell.isOpen,
  })

  return (
    <>
      {cell.isOpen ? (
        <div className={cssClasses}>
          <div className={classes.section}>
            <GridCell object={object} gridCell={cell} toggleOpen={handleToggleOpen} />
          </div>
        </div>
      ) : (
        <Button className={cssClasses} onClick={handleToggleOpen}>
          {t(cell.title)}
        </Button>
      )}
    </>
  )
}

export default GridItem
