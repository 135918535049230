import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { useEffect } from 'react'
import { selectCurrentUser } from '../selector'
import { AuthUserType } from '../prop-types'
import { useClientIri } from '@features/@common/hooks'

const AuthGuard = (props) => {
  const { children, user } = props
  const history = useHistory()
  const clientIri = useClientIri()

  useEffect(() => {
    if (!user) {
      history.push('/auth/login')
    }
  }, [history, user])

  if (!user) {
    return null
  }

  clientIri.setClientIri(user.person.client)

  return children
}

AuthGuard.propTypes = {
  user: AuthUserType,
}

const mapStateToProps = (state) => ({
  user: selectCurrentUser(state),
})

export default connect(mapStateToProps)(AuthGuard)
