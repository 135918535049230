import React, { createRef, FC, useEffect, useRef, useState } from 'react'
import { CSSProperties, useTheme } from '../MaterialUI/styles'
import { Theme } from '../MaterialUI/theme'
import { Transition, TransitionStatus } from 'react-transition-group'
import { Timeout } from '../../config/animation'

interface TransitionStyles {
  entering: Record<string, unknown>
  entered: Record<string, unknown>
  exiting: Record<string, unknown>
  exited: Record<string, unknown>
  unmounted: Record<string, unknown>
}

interface Props {
  timeout?: number
  in?: boolean
}

export const SlideToggle: FC<Props> = ({ timeout = Timeout.Slide, children, in: inProp = true, ...rest }) => {
  const [height, setHeight] = useState(0)
  const theme = useTheme<Theme>()
  const container = createRef<HTMLDivElement>()
  const nodeRef = useRef(null)

  useEffect(() => {
    if (!container.current || !inProp) {
      return
    }

    const content = container.current.firstChild as HTMLElement
    const nextHeight = content.offsetHeight + theme.spacing(3)

    setHeight(nextHeight)
  }, [theme, container, inProp])

  const defaultStyle: CSSProperties = {
    height: 0,
    overflow: 'hidden',
    transition: `height ${timeout}ms ease-in-out`,
  }

  const transitionStyles: TransitionStyles = {
    entering: { height },
    entered: { height },
    exiting: { height: 0 },
    exited: { height: 0 },
    unmounted: { height: 0 },
  }

  return (
    <Transition nodeRef={nodeRef} in={inProp} timeout={timeout} {...rest}>
      {(status: TransitionStatus) => (
        <div
          ref={container}
          style={{
            ...defaultStyle,
            ...transitionStyles[status],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  )
}
