import React, { FC } from 'react'
import clsx from 'clsx'
import { Box, Divider } from '../MaterialUI/core'
import { makeStyles } from '../MaterialUI/styles'
import Body from './Body'
import { PaginationOptions } from '@models/pagination/PaginationOptions'
import Header from './Header'
import { RoyalPagination } from '..'
import { Column } from './Model/Column'
import RowClickHandler from './Props/RowClickHandler'
import { Theme } from '@components/MaterialUI/theme'
import { MARGIN } from '@theme/constants'

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    marginTop: MARGIN,
  },
  body: {
    borderBottom: `1px solid ${theme.palette.royal.gray}`,
  },
}))

interface Props {
  columns: Column[]
  onRowClick?: RowClickHandler
  objects: Record<string, any>[]
  isLoading: boolean
  highlightIfValue?: string
  hover?: boolean
  paginationOptions?: PaginationOptions
}

const RoyalList: FC<Props> = ({
  columns,
  onRowClick,
  objects,
  isLoading,
  paginationOptions,
  highlightIfValue,
  hover = true,
}) => {
  const classes = useStyles()

  const cssClasses = clsx(classes.main, {
    [classes.body]: !paginationOptions,
  })

  return (
    <Box className={cssClasses}>
      <Header columns={columns} />
      <Body
        isLoading={isLoading}
        objects={objects}
        columns={columns}
        onRowClick={onRowClick}
        highlightIfValue={highlightIfValue}
        hover={hover}
      />
      <Divider />

      {paginationOptions && <RoyalPagination isLoading={isLoading} options={paginationOptions} asTableRow={false} />}
    </Box>
  )
}

export default RoyalList
