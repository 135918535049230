import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { ResponseSuccessful } from '@models/api/response'
import { ClientModel } from '@models/entities/clientOrders'
import { SupplierModel } from '@models/entities/suppliers/SupplierModel'

interface AuthContextState {
  client: ClientModel | null
  supplier: SupplierModel | null
}

type AuthContextReducers = SliceCaseReducers<AuthContextState>

interface AuthContextResponse extends Omit<ResponseSuccessful, 'data'> {
  client: ClientModel
  supplier: SupplierModel
}

type AuthContextPayloadAction = PayloadAction<AuthContextResponse>

const authContextSlice = createSlice<AuthContextState, AuthContextReducers>({
  name: 'authContext',
  initialState: {
    client: null,
    supplier: null,
  },
  reducers: {
    setContext: (state: AuthContextState, action: AuthContextPayloadAction) => {
      state.client = null
      state.supplier = null

      if (action.payload.client) {
        state.client = action.payload.client
      }

      if (action.payload.supplier) {
        state.supplier = action.payload.supplier
      }
    },
  },
})

export const authContextActions = authContextSlice.actions
export default authContextSlice
