import { createPaginationSlice } from '@features/@common'
import { fetchClientHasBrandConditionsForBrand } from '../action'
import { ClientHasBrandConditionModel } from '@models/entities/clientOrders'

const mainSlice = createPaginationSlice<ClientHasBrandConditionModel>(
  'clientHasBrandConditions.forBrand.fetch',
  fetchClientHasBrandConditionsForBrand,
)

export { mainSlice }
