const ACCOUNT_CONFIRMATION_PATH = '/auth/confirm/:token'
const ARTICLES_PATH = '/articles/:page(\\d+)?'
const ARTICLE_DETAILS_PATH = '/article/:id(\\d+)'
const BRANDS_PATH = '/brands/:page(\\d+)?'
const BRAND_DETAILS_PATH = '/brand/:id(\\d+)'
const CATALOGUE_PATH = '/catalogue'
const CLIENT_ORDERS_PATH = '/client-orders/:page(\\d+)?'
const CLIENT_ORDER_PATH = '/client-order/:id(\\d+)'
const CONDITIONS_PATH = '/conditions'
const CUSTOMERS_PATH = '/customers/:page(\\d+)?'
const DASHBOARD_PATH = '/'
const GROUP_ORDERS_PATH = '/group-orders/:page(\\d+)?'
const GROUP_ORDER_PATH = '/group-order/:id(\\d+)'
const LOGIN_PATH = '/auth/login'
const PRODUCTS_PATH = '/products/:page(\\d+)?'
const PUBLICITY_PATH = '/publicity'
const REPORTS_PATH = '/reports'
const SETTINGS_PATH = '/settings/:entityOne(\\w+)?'
const SETTINGS_PATH_STEP2 = '/settings/:entityOne(\\w+)/:entityOneId(\\d+)?/:entityTwo(\\w+)?'
const SUPPLIERS_PATH = '/suppliers/:page(\\d+)?'
const SUPPLIER_CREATE_PATH = '/suppliers/add/'
const SUPPLIER_DETAILS_PATH = '/supplier/:id(\\d+)'

export {
  ACCOUNT_CONFIRMATION_PATH,
  ARTICLES_PATH,
  ARTICLE_DETAILS_PATH,
  BRAND_DETAILS_PATH,
  BRANDS_PATH,
  CATALOGUE_PATH,
  CLIENT_ORDERS_PATH,
  CLIENT_ORDER_PATH,
  CONDITIONS_PATH,
  CUSTOMERS_PATH,
  DASHBOARD_PATH,
  GROUP_ORDERS_PATH,
  GROUP_ORDER_PATH,
  LOGIN_PATH,
  PRODUCTS_PATH,
  PUBLICITY_PATH,
  REPORTS_PATH,
  SETTINGS_PATH,
  SETTINGS_PATH_STEP2,
  SUPPLIERS_PATH,
  SUPPLIER_CREATE_PATH,
  SUPPLIER_DETAILS_PATH,
}
