import { ApiParameter, SortingDirection } from '@config/sorting'
import { Filter } from '@components/RoyalList/Model/Filter'

export interface SortingParameter {
  field: string
  desc: boolean
}

export interface SortingParameters {
  [key: string]: string
}

export const createSortingParameters = (parameters: SortingParameter[] = []): SortingParameters => {
  if (!Array.isArray(parameters) || parameters.length === 0) {
    return {}
  }

  const result: SortingParameters = {}

  try {
    parameters.forEach((parameter?: SortingParameter) => {
      if (!parameter) {
        return
      }

      const key = `${ApiParameter.Sorting}[${parameter.field}]` // e.g. "order[firstName]"
      result[key] = parameter.desc ? SortingDirection.Descending : SortingDirection.Ascending
    })
  } catch (error) {
    // Nothing to do
  }

  return result
}

export const mergeSortingWithFilter = (filter: Filter, sortingParameter?: SortingParameter): SortingParameters => {
  const result = createSortingParameters([sortingParameter as SortingParameter])

  if (filter.affectedProperties.length > 0 && !!filter.value) {
    filter.affectedProperties.forEach((property) => {
      if (!property) {
        return
      }

      result[property] = filter.value as string
    })
  }

  return result
}
