import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { makeStyles } from '@components/MaterialUI/styles'
import { FormControl, InputLabel, MenuItem, Select } from '@components/MaterialUI/core'
import { TextFieldVariant } from '@models/form/TextFieldVariant'

const useStyles = makeStyles(() => ({
  formControl: {
    margin: '5px',
    display: 'flex',
  },
}))

interface Props {
  items: any[]
  label: string
  selectedValue: string
  variant?: TextFieldVariant
  changeValue?: (value: string) => void
  readOnly?: boolean
}

export const DropdownSimple: FC<Props> = ({
  items,
  label,
  selectedValue,
  variant = TextFieldVariant.Outlined,
  readOnly = false,
  changeValue,
}) => {
  const [value, setValue] = useState(selectedValue)
  const classes = useStyles()

  useEffect(() => {
    setValue(selectedValue)
  }, [selectedValue])

  if (isEmpty(items)) {
    return null
  }

  const handleChange = (event: ChangeEvent<{ name?: string; value: any }>) => {
    if (readOnly) {
      return
    }

    const { value } = event.target

    if (changeValue) {
      changeValue(value)
    }

    setValue(value)
  }

  const menuItems = items.map((item) => (
    <MenuItem key={`dropdown-item-${item}`} value={item}>
      {item}
    </MenuItem>
  ))

  return (
    <FormControl variant={variant} className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select labelId={label} value={value} onChange={handleChange} label={label}>
        {menuItems}
      </Select>
    </FormControl>
  )
}
