import createApiAction from '@features/@common/actions/api-actions'
import { parsePaginatedCollection } from '@utils/api/data'
import * as api from '../api'
import {
  FetchParameters,
  PostParameters,
  UpdateAddressParameters,
  UpdateDateParameters,
  UpdateInternalNoteParameters,
  UpdateNoteParameters,
  UpdateOptionsParameters,
  UpdatePaymentDiscountParameters,
  UpdatePaymentPeriodParameters,
  UpdateUsedByParameters,
} from '../api'
import { readResource } from '@features/@common/api/api'
import readClientOrderIncludes from '@features/clientOrders/api/config/readClientOrderIncludes'

const fetchClientOrders = createApiAction<any, FetchParameters, any>(
  'clientOrders.fetch',
  async (parameters: FetchParameters) => {
    const response = await api.fetchClientOrders(parameters)

    return parsePaginatedCollection(response)
  },
)

const readClientOrder = createApiAction<any, number, any>('clientOrders.readOne', api.readClientOrder)

const readClientOrderByIri = createApiAction<any, string, any>('clientOrders.readOne', (clientOrderIri: string) => {
  return readResource(clientOrderIri, readClientOrderIncludes)
})

const createClientOrder = createApiAction<any, PostParameters, any>('clientOrders.create', api.createClientOrder)

const updateClientOrderAddress = createApiAction<any, UpdateAddressParameters, any>(
  'clientOrders.updateAddress',
  api.updateClientOrderAddress,
)

const updateClientOrderOptions = createApiAction<any, UpdateOptionsParameters, any>(
  'clientOrders.updateOptions',
  api.updateClientOrderOptions,
)

const updateClientOrderPaymentDiscount = createApiAction<any, UpdatePaymentDiscountParameters, any>(
  'clientOrders.updatePaymentDiscount',
  api.updateClientOrderPaymentDiscount,
)

const updateClientOrderPaymentPeriod = createApiAction<any, UpdatePaymentPeriodParameters, any>(
  'clientOrders.updatePaymentPeriod',
  api.updateClientOrderPaymentPeriod,
)

const updateClientOrderInternalNote = createApiAction<any, UpdateInternalNoteParameters, any>(
  'clientOrders.updateInternalNote',
  api.updateClientOrderInternalNote,
)

const updateClientOrderDeliveryDate = createApiAction<any, UpdateDateParameters, any>(
  'clientOrders.updateDeliveryDate',
  api.updateClientOrderDeliveryDate,
)

const updateClientOrderNote = createApiAction<any, UpdateNoteParameters, any>(
  'clientOrders.updateNote',
  api.updateClientOrderNote,
)

const updateClientOrderUsedBy = createApiAction<any, UpdateUsedByParameters, any>(
  'clientOrders.updateUsedBy',
  api.updateClientOrderUsedBy,
)

export {
  fetchClientOrders,
  readClientOrder,
  readClientOrderByIri,
  createClientOrder,
  updateClientOrderAddress,
  updateClientOrderOptions,
  updateClientOrderDeliveryDate,
  updateClientOrderNote,
  updateClientOrderInternalNote,
  updateClientOrderUsedBy,
  updateClientOrderPaymentPeriod,
  updateClientOrderPaymentDiscount,
}
