import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SliceState } from '@features/clientOrderTotals/model/slice/SliceState'
import { SliceReducers } from '@features/clientOrderTotals/model/slice/SliceReducers'
import { ClientOrderTotalsModel } from '@models/entities/clientOrders/ClientOrderTotalsModel'

const totalsSlice = createSlice<SliceState, SliceReducers>({
  name: 'clientOrderTotals',
  initialState: {},
  reducers: {
    setTotals: (state: SliceState, action: PayloadAction<ClientOrderTotalsModel>) => {
      state.clientOrderTotals = action.payload
    },
  },
})

const totalsActions = totalsSlice.actions

export { totalsSlice, totalsActions }
