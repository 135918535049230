import { createSlice } from '@reduxjs/toolkit'
import { createImageMediaObject } from '../action'
import CreateSliceState from '../model/slice/CreateSliceState'
import CreateSliceReducer from '../model/slice/CreateSliceReducer'
import createInitialState from '../initialState/createState'
import { ImageModel } from '@models/entities/images/ImageModel'

const createMediaObjectSlice = createSlice<CreateSliceState, CreateSliceReducer>({
  name: 'mediaObject.create',
  initialState: createInitialState,
  reducers: {},
  extraReducers: {
    [createImageMediaObject.pending.type]: (state) => {
      state.isLoading = true
      state.success = createInitialState.success
      state.error = createInitialState.error
    },
    [createImageMediaObject.fulfilled.type]: (state, action) => {
      state.isLoading = false
      state.success = action.payload as ImageModel
      state.error = createInitialState.error
    },
    [createImageMediaObject.rejected.type]: (state, action) => {
      state.isLoading = false
      state.success = createInitialState.success
      state.error = action.payload.errors
    },
  },
})

export default createMediaObjectSlice
