import moment from 'moment'
import clsx from 'clsx'
import React, { FC } from 'react'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { isThisWeek, isWeekend } from 'date-fns'
import { NOW } from '../utilities/constants'
import { DateOptions, getTomorrow, isBeforeToday } from '@utils/date'
import { isBeforeTodayButInCurrentWeek } from '../utilities/functions'
import { Day as MuiDay } from '@components/MaterialUI/pickers'
import { makeStyles } from '@components/MaterialUI/styles'
import { Theme } from '@components/MaterialUI/theme'
import { SMALL_FONT_SIZE } from '@theme/constants'
import { Day as DayName, UnitOfTime } from '../../../config/date'

const useStyles = makeStyles((theme: Theme) => {
  const size = '36px'

  return {
    week: {
      position: 'absolute',
      left: '5px',
      lineHeight: size,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '50%',
      fontSize: SMALL_FONT_SIZE,
      height: size,
      display: 'flex',
      justifyContent: 'center',
      width: size,
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    weekDisabled: {
      cursor: 'default',
      color: theme.palette.royal.gray,
      border: `1px solid ${theme.palette.royal.gray}`,
    },
  }
})

type Props = {
  date: MaterialUiPickersDate
  selectedDate: MaterialUiPickersDate
  dayInCurrentMonth: boolean
}

const Day: FC<Props> = ({ date, selectedDate, dayInCurrentMonth }) => {
  const classes = useStyles()
  const dateDisplay = moment(date)
  const current = moment(selectedDate)
  const tomorrowDay = getTomorrow().day()

  const beforeToday = isBeforeToday(dateDisplay)
  const weekend = isWeekend(dateDisplay.toDate())
  const thisWeek = isThisWeek(dateDisplay.toDate(), DateOptions)

  const weekClass = clsx(classes.week, {
    [classes.weekDisabled]: beforeToday,
  })

  return (
    <div>
      {!isBeforeTodayButInCurrentWeek(dateDisplay) && dateDisplay.day() === DayName.Monday && (
        <div className={weekClass}>{dateDisplay.isoWeek()}</div>
      )}

      {thisWeek && dateDisplay.day() === tomorrowDay && <div className={weekClass}>{dateDisplay.isoWeek()}</div>}

      <MuiDay
        current={dateDisplay.isSame(NOW, UnitOfTime.Day)}
        hidden={!dayInCurrentMonth}
        disabled={beforeToday || weekend}
        selected={dateDisplay.isSame(current, UnitOfTime.Day)}
      >
        {dateDisplay.date()}
      </MuiDay>
    </div>
  )
}

export default Day
