import {
  ARTICLES_PATH,
  BRANDS_PATH,
  CLIENT_ORDERS_PATH,
  DASHBOARD_PATH,
  GROUP_ORDERS_PATH,
  PRODUCTS_PATH,
  SETTINGS_PATH,
  SUPPLIERS_PATH,
} from '@config/routes/paths'
import {
  AndroidIcon,
  DashboardIcon,
  KitchenIcon,
  LibraryBooksIcon,
  LocalShippingIcon,
  ReorderIcon,
  SettingsIcon,
  ShoppingCartIcon,
} from '@components/MaterialUI/icons'
import { generateUrl } from '@utils/route'
import { NavigationItemModel } from '@models/navigation/NavigationItemModel'

const clientItems: NavigationItemModel[] = [
  {
    route: generateUrl(DASHBOARD_PATH),
    title: 'navigation.dashboard',
    icon: DashboardIcon,
  },
  {
    route: generateUrl(SUPPLIERS_PATH),
    title: 'navigation.suppliers',
    icon: LocalShippingIcon,
  },
  {
    route: generateUrl(CLIENT_ORDERS_PATH),
    title: 'navigation.clientOrders',
    icon: ShoppingCartIcon,
  },
  {
    route: generateUrl(ARTICLES_PATH),
    title: 'navigation.articles',
    icon: KitchenIcon,
  },
  {
    route: generateUrl(GROUP_ORDERS_PATH),
    title: 'navigation.groupOrders',
    icon: LibraryBooksIcon,
  },
  {
    route: generateUrl(BRANDS_PATH),
    title: 'navigation.brands',
    icon: AndroidIcon,
  },
  {
    route: generateUrl(SETTINGS_PATH),
    title: 'navigation.settings',
    icon: SettingsIcon,
  },
  {
    route: generateUrl(PRODUCTS_PATH),
    title: 'Products',
    icon: ReorderIcon,
    childItems: [],
  },
]

export default clientItems
