import { createSlice } from '@reduxjs/toolkit'
import { readArticleAccessories } from '../action'
import { entityAdapter } from '../adapter'
import AccessoriesSliceState from '../model/slice/AccessoriesSliceState'
import AccessoriesReducer from '../model/slice/AccessoriesReducer'
import accessoriesInitialState from '../initialState/accessoriesState'

const accessoriesSlice = createSlice<AccessoriesSliceState, AccessoriesReducer>({
  name: 'articles.readMany.accessories',
  initialState: accessoriesInitialState,
  reducers: {},
  extraReducers: {
    [readArticleAccessories.pending.type]: (state) => {
      state.isLoading = accessoriesInitialState.isLoading
      entityAdapter.removeAll(state)
      state.error = accessoriesInitialState.error
    },
    [readArticleAccessories.fulfilled.type]: (state, action) => {
      entityAdapter.upsertMany(state, action.payload.data)
      state.isLoading = false
      state.error = accessoriesInitialState.error
    },
    [readArticleAccessories.rejected.type]: (state, action) => {
      state.isLoading = accessoriesInitialState.isLoading
      state.error = action.payload
    },
  },
})

export default accessoriesSlice
