import { useCallback } from 'react'
import { SortingParameter } from '@utils/api/sorting'
import { SortingDirection, UrlParameter } from '@config/sorting'
import useUrlParam from './use-url-param'

const DEFAULT_SORTING_DIRECTION = SortingDirection.Ascending

interface Result {
  sortingParameter?: SortingParameter
  createSortingUrlParameter: () => string | undefined
}

const useUrlSortingParameter = (field?: string): Result => {
  const [urlParameters, createEncodedParameter] = useUrlParam(UrlParameter.Filtering)

  const extractSortingParameter = useCallback((): SortingParameter | undefined => {
    if (!!urlParameters && urlParameters[UrlParameter.Sorting]) {
      return urlParameters[UrlParameter.Sorting]
    }
  }, [urlParameters])

  const createSortingParameter = (): SortingParameter | null => {
    if (field) {
      let descending = DEFAULT_SORTING_DIRECTION.toString() === SortingDirection.Descending.toString()

      if (!!sortingParameter && sortingParameter.field === field) {
        descending = !sortingParameter.desc
      }

      return {
        field,
        desc: descending,
      }
    }

    return null
  }

  const createSortingUrlParameter = (): string | undefined => {
    const sortingParameter = createSortingParameter()

    if (sortingParameter) {
      const parameter = {
        [UrlParameter.Sorting]: sortingParameter,
      }

      return createEncodedParameter(parameter)
    }
  }

  const sortingParameter = extractSortingParameter()

  return {
    sortingParameter,
    createSortingUrlParameter,
  }
}

export default useUrlSortingParameter
