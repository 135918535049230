import { createSlice } from '@reduxjs/toolkit'
import { createBrandCondition } from '../action'
import CreateSliceState from '../model/slice/CreateSliceState'
import createInitialState from '../initialState/createState'
import CreateSliceReducer from '../model/slice/CreateSliceReducer'
import { BrandConditionModel } from '@models/entities/brands/BrandConditionModel'

const createBrandConditionSlice = createSlice<CreateSliceState, CreateSliceReducer>({
  name: 'brandCondition.create',
  initialState: createInitialState,
  reducers: {},
  extraReducers: {
    [createBrandCondition.pending.type]: (state) => {
      state.isLoading = true
      state.success = createInitialState.success
      state.newId = createInitialState.newId
      state.error = createInitialState.error
    },
    [createBrandCondition.fulfilled.type]: (state, action) => {
      state.isLoading = false
      state.success = action.payload
      state.newId = (action.payload as BrandConditionModel).id
      state.error = createInitialState.error
    },
    [createBrandCondition.rejected.type]: (state, action) => {
      state.isLoading = false
      state.success = createInitialState.success
      state.newId = createInitialState.newId
      state.error = action.payload.errors
    },
  },
})

export default createBrandConditionSlice
